import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { DialogAction } from '../../../../contracts/ActivityAction';
import { Stack } from 'office-ui-fabric-react';
import { isNullOrUndefined } from 'util';

export class ActivityDeleteDialog extends React.Component<
  {
    hideDialog: boolean,
    dialogText:string,
    isDelete:boolean,
    OnDialogActionButtonClick:(action:DialogAction) => void,
    warningText: string
  },
  {
    hideDialog: boolean;
    dialogText: string;
    isDelete:boolean;
    warningText: string;
  }
> {
  constructor(props: {
    hideDialog: true,
    dialogText:'',
    isDelete:false
    OnDialogActionButtonClick:(action:DialogAction) => void,
    warningText: ''
  }) {
    super(props);

    this.state = {
        hideDialog:true,
        dialogText:'',
        isDelete:false,
        warningText: ''
    };
  }
  public componentWillReceiveProps(nextProps:any) {
    this.setState ( {
        hideDialog:nextProps.hideDialog,
        dialogText:nextProps.dialogText,
        isDelete:nextProps.isDelete,
        warningText: nextProps.warningText
    });
  }
  public render() {
    const {hideDialog,dialogText,isDelete, warningText}=this.state;
    return (
      <div>
        <Dialog
          hidden={hideDialog}
          onDismiss={this._onCancelClick}
          dialogContentProps={{
            type: DialogType.normal,
            title: isDelete?'Confirm Delete':'Un-Map PO association',
            subText: dialogText,
          }}
          modalProps={{
            isBlocking: true,
            containerClassName: 'ms-deleteDialogMainOverride',
            isDarkOverlay:true,
            topOffsetFixed: true
          }}
        >
          {(!isNullOrUndefined(warningText) && warningText !== '' && isDelete) ?
            <Stack horizontal>
              <Stack.Item>
                <Icon iconName="WarningSolid" style={{ zoom: "250%" }} />
              </Stack.Item>
              <Stack.Item>
                <span className="font-Size-16">{ warningText }</span>
              </Stack.Item>
            </Stack> : <div />
          }
       {isDelete?
          <DialogFooter>
            <PrimaryButton onClick={this._onDeleteClick} className="btnDelete" text="Delete" />
            <DefaultButton onClick={this._onCancelClick}  className="btnCancelDelete" text="Cancel" />
            </DialogFooter>:
            <DialogFooter>
               <PrimaryButton onClick={this._onCancelClick}  className="btnCancelDelete" text="Close" />
          </DialogFooter>
       }
        </Dialog>
      </div>
    );
  }

  private _onDeleteClick = (): void => {
    this.props.OnDialogActionButtonClick(DialogAction.Yes);
    this.setState({ hideDialog: true });
  };
  private _onCancelClick = (): void => {
    this.props.OnDialogActionButtonClick(DialogAction.No);
    this.setState({ hideDialog: true });
  };
}