import * as React from 'react';
import { adalApiFetch } from '../../adalConfig';
import { AppSettings,Config } from '../../Configuration';
import { MessageBarButton } from 'office-ui-fabric-react/lib/Button';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { AuthorizationService } from'@commercialsalesandmarketing/pet-common';
import { Permissions } from '../../contracts/Permissions';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';


export class POUnMappingDetails extends React.Component<{ SelectedHieraryNode:any,
  OnPOClick:(OnUnMappedPODetails:boolean,
    unMappedPOs:any[], mapBHide:boolean) => void,
    roles:any[],
    forceUpdate:boolean,
    mapBHide:boolean,
    AdvancedSearchEnabled:boolean,
    OnSaveComplete:(unMappedPOs:any[])=>void
},{
        unMappedCount:any,
        unMappedPOs:any[],
        LandingCountry:string,
        isLoading:boolean,
        isError:boolean,
        erroDetails:string;
        isTeachingBubbleVisible:boolean,
        mapBHide: boolean,
        AdvancedSearchEnabled:boolean,
        workspaceFY:string
      }>{
        private _menuButtonElement: HTMLElement;

    constructor(props:{
      SelectedHieraryNode:any,  
      OnPOClick:(OnUnMappedPODetails:boolean) => void,
      roles:any[],
      forceUpdate:boolean,
      mapBHide:boolean,
      AdvancedSearchEnabled:boolean
      OnSaveComplete:(unMappedPOs:any[])=>void
        }){
           super(props);
           this.state={
            unMappedCount:"",
            unMappedPOs:[],
            LandingCountry:'',
            isLoading:true,
            isError:false,
            erroDetails:"",
            isTeachingBubbleVisible:false,
            mapBHide: false,
            AdvancedSearchEnabled:false,
            workspaceFY:''
           }
           this._onDismiss= this._onDismiss.bind(this);
        }
       
    public render() : JSX.Element{
        const currentWorkspaceName = 'Loading Un-Map PO Details for '  + this.props.SelectedHieraryNode.text;
        return(
          !this.state.AdvancedSearchEnabled?
            <div className="unmapped-panel">
            {
              this.state.isLoading?
              <div>
                {
                  this.props.SelectedHieraryNode.key?
                    <ProgressIndicator label={currentWorkspaceName}/>
                    :<div/>
                }
              </div>
              :
              <div>
              {
                this.state.isError?
                <MessageBar messageBarType={MessageBarType.error} isMultiline={false} >
                {'Fail to load Un-Mapped PO Details, Please reload the page.'}
              </MessageBar>
              :<div>
              {
               this.state.unMappedCount ==="" ||  this.state.unMappedCount ===0 ?
               <div>
                <MessageBar messageBarType={MessageBarType.success}>
                  There are no Unmapped Spend Item(s) in {this.props.SelectedHieraryNode.text}.
                </MessageBar>
                 </div>
              :
                <div>
                 <MessageBar
                 messageBarType={MessageBarType.warning}
                 isMultiline={false}
                 dismissButtonAriaLabel="Close"
                 actions={ !this.props.mapBHide &&
                   <div>
                     <span ref={menuButton => (this._menuButtonElement = menuButton!)}>
                      <MessageBarButton onClick={this._UnMappedPOPage} id="mapId" >Map</MessageBarButton>
                     </span>
                   </div>
                 }
               >
               {
                !this.props.mapBHide ?
                <div>There are {this.state.unMappedCount} Unmapped Spend Item(s) in {this.props.SelectedHieraryNode.text} Click here to map them.</div>
                :<div>There are {this.state.unMappedCount} Unmapped Spend Item(s) in {this.props.SelectedHieraryNode.text}.</div>
                }
               </MessageBar>
               {this.state.isTeachingBubbleVisible ? 
                <div>
                  <TeachingBubble
                  targetElement={this._menuButtonElement}
                  hasCondensedHeadline={true}
                  onDismiss={this._onDismiss}
                  hasCloseIcon={true}
                  headline="Permission Required"
                  >
                  You do not have enough permissions to perform this action, please contact your admin to gain access.
                  </TeachingBubble>
                </div>
                : null}
               </div>
              }
              </div>
              }
              </div>
             }
            </div>
      :<div></div>
      );
    }
    public componentDidMount(){
      if(this.props.SelectedHieraryNode.key !== undefined &&
        this.props.SelectedHieraryNode.data !==undefined &&
        this.props.SelectedHieraryNode.data.relation!==undefined &&
        this.props.SelectedHieraryNode.data.relation.Country !==undefined)      {
          this.setState({workspaceFY:this.props.SelectedHieraryNode.data.relation.FY})
          this.getLandingCountry(this.props.SelectedHieraryNode.data.relation.Country,Config.Mapping.LandingCountryEntityDefinition);
      }
    }

    public componentWillReceiveProps(nextProps:any) {
      if(nextProps.SelectedHieraryNode.key !== undefined &&
        nextProps.SelectedHieraryNode.data !==undefined &&
        nextProps.SelectedHieraryNode.data.relation!==undefined &&
        nextProps.SelectedHieraryNode.data.relation.Country !==undefined &&
         (this.props.SelectedHieraryNode.key === undefined ||
          this.props.SelectedHieraryNode.data ===undefined ||
          this.props.SelectedHieraryNode.data.relation===undefined ||
          this.props.SelectedHieraryNode.data.relation.Country ===undefined ||
          this.props.SelectedHieraryNode.data.relation.Country !==nextProps.SelectedHieraryNode.data.relation.Country  ||
          this.props.SelectedHieraryNode.data.relation.FY !==nextProps.SelectedHieraryNode.data.relation.FY 
          || nextProps.forceUpdate )){
          this.setState({workspaceFY:nextProps.SelectedHieraryNode.data.relation.FY})
          this.getLandingCountry(nextProps.SelectedHieraryNode.data.relation.Country,Config.Mapping.LandingCountryEntityDefinition);
      }
      if(nextProps.SelectedHieraryNode.key !== undefined &&
        nextProps.SelectedHieraryNode.relations!==undefined &&
        nextProps.SelectedHieraryNode.relations.Country !==undefined &&
        (this.props.SelectedHieraryNode.key === undefined ||
        this.props.SelectedHieraryNode.relations===undefined ||
        this.props.SelectedHieraryNode.relations.Country ===undefined ||
        this.props.SelectedHieraryNode.relations.Country !==nextProps.SelectedHieraryNode.relations.Country
        || this.props.SelectedHieraryNode.relations.FY !==nextProps.SelectedHieraryNode.relations.FY
        || nextProps.forceUpdate)){
        this.setState({workspaceFY:nextProps.SelectedHieraryNode.data.relation.FY})
        this.getLandingCountry(nextProps.SelectedHieraryNode.relations.Country,Config.Mapping.LandingCountryEntityDefinition);
      }
      this.setState({
        AdvancedSearchEnabled: nextProps.AdvancedSearchEnabled
      })
    }
      private UpdateUnMappedPODetails(SelectedHierarchyNodeId:string):void{
        SelectedHierarchyNodeId =this.encodeUrl(SelectedHierarchyNodeId);
        adalApiFetch(fetch,`${AppSettings.UnMappedPOServiceUrl()}/${SelectedHierarchyNodeId}/${this.state.workspaceFY}`,{})
        .then(response =>  response.json())
        .then(data =>  {
          let unMappedCnt:any;
          unMappedCnt= data.length;
          const unMappedposCnt=this.state.unMappedCount;
          this.setState({
            unMappedCount: unMappedCnt,
            unMappedPOs: data,
            isLoading:false
          },()=>{
          if(this.props.forceUpdate && unMappedposCnt!==unMappedCnt)
          {
            this.props.OnSaveComplete(data);
          }
          });
        })
            .catch((error) =>{
              this.setState({
                isLoading:false,
                isError:true,
                erroDetails : error
              });
            });
      }
      
      private getLandingCountry(landingCountryTaxonomyId:string,taxonomyDefId:string):void{
        this.setState({isLoading:true});
        const locale = Config.locale;
        const nodesData={
          taxonomy_ids:[landingCountryTaxonomyId],
          definition_id:taxonomyDefId

        };
        const opts = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(nodesData)
        }
        adalApiFetch(fetch, `${AppSettings.TaxonomyServiceUrl()}/${Config.Taxonomy.GetTaxonomiesUrl}`, opts)
          .then(response => response.json())
          .then(data => {
            this.setState({
              LandingCountry:data[taxonomyDefId][0].display_labels[locale],
            });
            this.UpdateUnMappedPODetails(this.state.LandingCountry);
          })
          .catch((error) => {
            this.setState({
              isLoading:false,
              isError:true,
              erroDetails : error.message
            });
          });
      }
      private _UnMappedPOPage = (): void => {
        const authorizationService = new AuthorizationService(AppSettings);
        const isRoleAvailable = authorizationService.IsRolePresent(this.props.roles,Permissions.POAssociation);
        if(isRoleAvailable){
          this.props.OnPOClick(false,this.state.unMappedPOs, true);
        }
        else{
          this.setState({isTeachingBubbleVisible:true});
        }
      };
      private encodeUrl(url:string):string{
        var enUrl= url.replace(/\+/g,'0-0-0');
        enUrl = enUrl.replace(/&/g,'1-1-1');
        return enUrl;
      }
      private _onDismiss(ev: any): void {
        this.setState({
          isTeachingBubbleVisible: !this.state.isTeachingBubbleVisible
        });
      }
}