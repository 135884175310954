import * as React from 'react';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { ActivityAction } from '../../../../contracts/ActivityAction';
import { AuthorizationService } from'@commercialsalesandmarketing/pet-common';
import { Permissions } from '../../../../contracts/Permissions'
import { TooltipHost, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { AppSettings } from '../../../../Configuration';

export class CreateActivityButton extends React.Component<
  {
    OnActivityActionButtonClick: (action: ActivityAction) => void,
    roles: any[],
    disabled: boolean
  },
  {
    checked: boolean,
    action: ActivityAction,
    isRoleAvailable: boolean
  }> {
  constructor(props: {
    OnActivityActionButtonClick: (action: ActivityAction) => void,
    roles: any[],
    disabled: false
  }) {
    super(props);
    this._onCreateClicked = this._onCreateClicked.bind(this);
    const authorizationService = new AuthorizationService(AppSettings);
    const isRoleAvailable = authorizationService.IsRolePresent(this.props.roles, Permissions.CreateUpdateActivity);
    this.state = {
      checked: false,
      action: ActivityAction.AddActivity.valueOf(),
      isRoleAvailable
    }
  }

  public render(): JSX.Element {
    return (
      <div className="activityActionButtonContainer">
        <TooltipHost
          className="actionButtontoolTip"
          calloutProps={{ gapSpace: -10 }}
          closeDelay={100}
          hostClassName="selectedTaxonomyValueTooltip"
          tooltipProps={{
            onRenderContent: () => {
              return (
                <div>
                  <ul style={{ margin: 0, padding: 0 }}>
                    <li><span>Create Activity</span></li>
                  </ul>
                </div>
              );
            }
          }}
          delay={TooltipDelay.zero}
          directionalHint={DirectionalHint.topCenter}
        >
          <div style={{ display: 'flex' }}>
            <ActionButton
              data-automation-id="test"
              iconProps={{ iconName: 'Table' }}
              allowDisabledFocus={true}
              disabled={!this.state.isRoleAvailable || this.props.disabled}
              checked={this.state.checked}
              onClick={this._onCreateClicked}
              className="activityActionButton" >Create Activity</ActionButton>
          </div>
        </TooltipHost>
      </div>
    );
  }

  public componentWillReceiveProps(nextProp: any) {
    const authorizationService = new AuthorizationService(AppSettings);
    const isRoleAvailable = authorizationService.IsRolePresent(nextProp.roles, Permissions.CreateUpdateActivity);
    this.setState({
      checked: false,
      action: ActivityAction.AddActivity.valueOf(),
      isRoleAvailable
    })
  }
  private _onCreateClicked(event): void {
    this.props.OnActivityActionButtonClick(this.state.action);
  }
}