import * as React from 'react';
import { CreateFolderButton } from './ActivityActionButtonPanelControls/CreateFolderButton';
import { CreateActivityButton } from './ActivityActionButtonPanelControls/CreateActivityButton';
import { CutButton } from './ActivityActionButtonPanelControls/CutButton';
import { CopyButton } from './ActivityActionButtonPanelControls/CopyButton';
import { PasteButton } from './ActivityActionButtonPanelControls/PasteButton';
import { DeleteButton } from './ActivityActionButtonPanelControls/DeleteButton';
import { ImportButton } from './ActivityActionButtonPanelControls/ImportButton';
import { ExportButton } from './ActivityActionButtonPanelControls/ExportButton';
import { ExpandAllButton } from './ActivityActionButtonPanelControls/ExpandAllButton';
import { CollapseAllButton } from './ActivityActionButtonPanelControls/CollapseAllButton';
import { CustomizeColumnsButton } from './ActivityActionButtonPanelControls/CustomizeColumnsButton';
import { AuditTrailButton } from './ActivityActionButtonPanelControls/AuditTrailButton';
import { ActivityAction } from '../../../contracts/ActivityAction';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { IActivityDeifinitionMember } from '../../../contracts/IActivityDefinitionMember';


export class ActivityActionButtonPanel extends React.Component<{
    OnActivityActionButtonClick: (action: ActivityAction) => void,
    OnSelectedColumns: (items: IActivityDeifinitionMember[]) => void,
    roles: any[],
    SelectedWorkspaceId: string,
    SelectedWorkspaceName: string,
    UserHierarchyDetails: any,
    Workspaces: any[],
    SelectedActivity: any,
    Allcolumns: any[],
    ActivityDef: any,
    AllTaxonomies: any,
    DisableEdits: boolean
}>
{
    // eslint-disable-next-line
    constructor(props: {
        OnActivityActionButtonClick: (action: ActivityAction) => void,
        OnSelectedColumns: (items: IActivityDeifinitionMember[]) => void,
        roles: any[],
        SelectedWorkspaceId: string,
        SelectedWorkspaceName: string,
        UserHierarchyDetails: any,
        Workspaces: any[],
        SelectedActivity: any,
        Allcolumns: any[],
        ActivityDef: any,
        AllTaxonomies: any,
        DisableEdits: false
    }) {
        super(props);
    }
    public render() {
        return (
            <Stack horizontal horizontalAlign="space-between">
                <Stack.Item>
                    <Stack horizontal gap={5} wrap>
                        <Stack.Item>
                            <CreateFolderButton OnActivityActionButtonClick={this.props.OnActivityActionButtonClick}
                                roles={this.props.roles} disabled={this.props.DisableEdits} />
                        </Stack.Item>
                        <Stack.Item>
                            <CreateActivityButton OnActivityActionButtonClick={this.props.OnActivityActionButtonClick}
                                roles={this.props.roles} disabled={this.props.DisableEdits} />
                        </Stack.Item>
                        <Stack.Item>
                            <CutButton OnActivityActionButtonClick={this.props.OnActivityActionButtonClick} roles={this.props.roles}
                                SelectedActivity={this.props.SelectedActivity} disabled={this.props.DisableEdits}/>
                        </Stack.Item>
                        <Stack.Item>
                            <CopyButton OnActivityActionButtonClick={this.props.OnActivityActionButtonClick} roles={this.props.roles}
                                SelectedActivity={this.props.SelectedActivity} disabled={this.props.DisableEdits} />
                        </Stack.Item>
                        <Stack.Item>
                            <PasteButton OnActivityActionButtonClick={this.props.OnActivityActionButtonClick} disabled={this.props.DisableEdits} />
                        </Stack.Item>
                        <Stack.Item>
                            <DeleteButton OnActivityActionButtonClick={this.props.OnActivityActionButtonClick}
                                roles={this.props.roles} disabled={this.props.DisableEdits} />
                        </Stack.Item>
                        <Stack.Item>
                            <ImportButton selectedWorkspaceId={this.props.SelectedWorkspaceId} UserHierarchyDetails={this.props.UserHierarchyDetails}
                                Workspaces={this.props.Workspaces} roles={this.props.roles} disabled={this.props.DisableEdits} />
                        </Stack.Item>
                        <Stack.Item>
                            <ExportButton selectedWorkspaceId={this.props.SelectedWorkspaceId} selectedWorkspaceName={this.props.SelectedWorkspaceName} />
                        </Stack.Item>
                        <Stack.Item>
                            <ExpandAllButton OnActivityActionButtonClick={this.props.OnActivityActionButtonClick}
                                roles={this.props.roles} disabled={this.props.DisableEdits} />
                        </Stack.Item>
                        <Stack.Item>
                            <CollapseAllButton OnActivityActionButtonClick={this.props.OnActivityActionButtonClick}
                                roles={this.props.roles} disabled={this.props.DisableEdits} />
                        </Stack.Item>
                        <Stack.Item>
                            <AuditTrailButton SelectedActivity={this.props.SelectedActivity}  roles={this.props.roles} ActivityDef = {this.props.ActivityDef} AllTaxonomies = {this.props.AllTaxonomies} WorkSpacesFullList = {this.props.Workspaces}/>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <CustomizeColumnsButton OnActivityActionButtonClick={this.props.OnActivityActionButtonClick}
                        OnSelectedColumns={this.props.OnSelectedColumns}
                        AllColumns={this.props.Allcolumns} />
                </Stack.Item>
            </Stack>
        )
    }

}
