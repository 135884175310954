import * as React from 'react';
import { ActivityAction } from '../../../../contracts/ActivityAction';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';

export class ExpandAllButton extends React.Component<{
  OnActivityActionButtonClick: (action: ActivityAction) => void,
  roles: any[],
  disabled: boolean
},
{
  checked: boolean,
  action: ActivityAction
}> {
constructor(props: {
  OnActivityActionButtonClick: (action: ActivityAction) => void,
  roles: any[],
  disabled: false
  IsDisabled: boolean
}) {
  super(props);
  this._onCreateClicked = this._onCreateClicked.bind(this);
}

public render(): JSX.Element {

  return (
    <div className="activityActionButtonContainer">
      <TooltipHost
          className="actionButtontoolTip"
          calloutProps={{ gapSpace: -10 }}
          closeDelay={100}
          hostClassName="selectedTaxonomyValueTooltip"
          tooltipProps={{
            onRenderContent: () => {
              return (
                <div>
                  <ul style={{ margin: 0, padding: 0 }}>
                    <li><span>Expand All</span></li>
                  </ul>
                </div>
              );
            }
          }}
          delay={TooltipDelay.zero}
          directionalHint={DirectionalHint.topCenter}
        >
          <div style={{ display: 'flex' }}>
            <ActionButton
              data-automation-id="test"
              iconProps={{ iconName: 'FullScreen' }}
              allowDisabledFocus={true}
              onClick={this._onCreateClicked}
              disabled={this.props.disabled}
              className="activityActionButton" >Expand All</ActionButton>
          </div>
        </TooltipHost>
      </div>
    );
  }
  public componentWillReceiveProps(nextProp: any) {
    this.setState({
      checked: false,
      action: ActivityAction.ExpandAll.valueOf(),
    })
  }
  private _onCreateClicked(event): void {
    this.props.OnActivityActionButtonClick(this.state.action);
  }
}