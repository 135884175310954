import * as React from 'react';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { ActivityAction } from '../../../../contracts/ActivityAction';
import { AuthorizationService } from'@commercialsalesandmarketing/pet-common';
import { Permissions } from '../../../../contracts/Permissions';
import { AppSettings } from '../../../../Configuration';

export class CutButton extends React.Component<
{ 
  OnActivityActionButtonClick: (action: ActivityAction) => void,
  roles: any[],
  SelectedActivity:any,
  disabled: boolean
},
{
  checked: boolean;
  isCreateEditActivityRoleAvailable:boolean;
  isCreateEditFolderRoleAvailable:boolean;
  isRoleAvailable:boolean
}
> {
  public constructor(props: {
     OnActivityActionButtonClick: (action: ActivityAction) => void,
     roles: any[],
     SelectedActivity:any,
     disabled: false
    }) {
    super(props);
    this._onCutClicked = this._onCutClicked.bind(this);
    this.state = {
      checked: false,
      isCreateEditFolderRoleAvailable:false,
      isCreateEditActivityRoleAvailable:false,
      isRoleAvailable:false
    }
  }

  public render(): JSX.Element {
    const {  checked,isRoleAvailable } = this.state;

    return (
      <div className="activityActionButtonContainer">
        <TooltipHost
          className="actionButtontoolTip"
          calloutProps={{ gapSpace: -10 }}
          closeDelay={100}
          hostClassName="selectedTaxonomyValueTooltip"
          tooltipProps={{
            onRenderContent: () => {
              return (
                <div>
                  <ul style={{ margin: 0, padding: 0 }}>
                    <li><span>Cut</span></li>
                  </ul>
                </div>
              );
            }
          }}
          delay={TooltipDelay.zero}
          directionalHint={DirectionalHint.topCenter}
        >
          <div style={{ display: 'flex' }}>
            <ActionButton
              data-automation-id="test"
              iconProps={{ iconName: 'Cut' }}
              allowDisabledFocus={true}
              checked={checked}
              onClick={this._onCutClicked}
              disabled={!isRoleAvailable || this.props.disabled}
              className="activityActionButton"
              ariaLabel="Cut">Cut</ActionButton>
          </div>
        </TooltipHost>
      </div >
    );
  }
 
  public componentWillReceiveProps(nextProps: any) {
      const authorizationService = new AuthorizationService(AppSettings);
      const isCreateEditActivityRoleAvailable = authorizationService.IsRolePresent(nextProps.roles, Permissions.CreateUpdateActivity) ;
      const isCreateEditFolderRoleAvailable= authorizationService.IsRolePresent(nextProps.roles, Permissions.SubFolders);
      this.setState ( {
        isCreateEditFolderRoleAvailable,
        isCreateEditActivityRoleAvailable,
        isRoleAvailable:isCreateEditFolderRoleAvailable||isCreateEditActivityRoleAvailable
      })
  if(this.state.isCreateEditActivityRoleAvailable || this.state.isCreateEditFolderRoleAvailable)
  {
    if( nextProps !== null && nextProps.SelectedActivity && nextProps.SelectedActivity.id !== undefined)
    {
      this.setState({
        checked: false,
        isRoleAvailable:( nextProps.SelectedActivity.is_folder && this.state.isCreateEditFolderRoleAvailable ) || (!nextProps.SelectedActivity.is_folder && this.state.isCreateEditActivityRoleAvailable)
      })
   }
  }
  }
  private _onCutClicked(event): void {
    this.props.OnActivityActionButtonClick(ActivityAction.Cut);
  }
}