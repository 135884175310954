import * as React from 'react';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { AuditPanel } from'@commercialsalesandmarketing/pet-common';
import { AuthorizationService } from'@commercialsalesandmarketing/pet-common';
import { Permissions } from '../../../../contracts/Permissions'
import { isNullOrUndefined } from 'util';
import { AppSettings } from '../../../../Configuration';

export class AuditTrailButton extends React.Component<{
    SelectedActivity: any,
    roles: any[],
    ActivityDef: any,
    AllTaxonomies: any,
    WorkSpacesFullList: any[]
},
    {
        showModal: boolean;
        isRoleAvailable: boolean;
    }> {
    public constructor(props: {
        SelectedActivity: any,
        roles: any[],
        ActivityDef: any,
        AllTaxonomies: any,
        WorkSpacesFullList: any[]
    }) {
        super(props);
        this.onAuditTrailClicked = this.onAuditTrailClicked.bind(this);
        this.onCloseAuditPanelClicked = this.onCloseAuditPanelClicked.bind(this);
        const authorizationService = new AuthorizationService(AppSettings);
        const isRoleAvailable = authorizationService.IsRolePresent(this.props.roles, Permissions.CreateUpdateActivity);

        this.state = {
            showModal: false,
            isRoleAvailable
        }
    }

    public render(): JSX.Element {
        const _isDisabled: boolean = !this.state.isRoleAvailable
            || isNullOrUndefined(this.props.SelectedActivity)
            || isNullOrUndefined(this.props.SelectedActivity.id)
            ? true : false;
        return (
            <div className="activityActionButtonContainer">
                <TooltipHost
                    className="actionButtontoolTip"
                    calloutProps={{ gapSpace: -10 }}
                    closeDelay={100}
                    hostClassName="selectedTaxonomyValueTooltip"
                    tooltipProps={{
                        onRenderContent: () => {
                            return (
                                <div>
                                    <ul style={{ margin: 0, padding: 0 }}>
                                        <li><span>Audit Trail</span></li>
                                    </ul>
                                </div>
                            );
                        }
                    }}
                    delay={TooltipDelay.zero}
                    directionalHint={DirectionalHint.topCenter}
                >
                    <div style={{ display: 'flex' }}>
                        <ActionButton
                            data-automation-id="test"
                            iconProps={{ iconName: 'History' }}
                            allowDisabledFocus={true}
                            disabled={_isDisabled}
                            onClick={this.onAuditTrailClicked}
                            className="activityActionButton" >Audit Trail</ActionButton>
                        <div>
                            {
                                <AuditPanel SelectedActivity={this.props.SelectedActivity} showModal={this.state.showModal} onCloseAuditPanel={this.onCloseAuditPanelClicked}
                                    ActivityDef={this.props.ActivityDef} AllTaxonomies={this.props.AllTaxonomies} WorkSpacesFullList = {this.props.WorkSpacesFullList}
                                    AppSettings={AppSettings} />
                            }
                        </div>
                    </div>
                </TooltipHost>
            </div>
        );
    }

    public componentWillReceiveProps(nextProp: any) {
        const authorizationService = new AuthorizationService(AppSettings);
        const isRoleAvailable = authorizationService.IsRolePresent(nextProp.roles, Permissions.CreateUpdateActivity);
        this.setState({
            showModal: false,
            isRoleAvailable
        })
    }

    private onAuditTrailClicked = (): void => {
        this.setState({ showModal: true });
    };

    private onCloseAuditPanelClicked = (): void => {
        this.setState({ showModal: false });
    };
}