import * as React from 'react';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Selection,  DetailsList, IDetailsList, DetailsListLayoutMode, ConstrainMode, CheckboxVisibility } from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { IColumnReorderOptions } from 'office-ui-fabric-react/lib/DetailsList';
import { ActivityListService } from '../../../../services/ActivityListService';
import { ActivityDefinitionService } from '../../../../services/ActivityDefinitionService';
import { AppSettings, Config } from '../../../../Configuration';
import { adalApiFetch } from '../../../../adalConfig';
import { IActivityDefinition } from '../../../../contracts/IActivityDefinition';
import { Shimmer, ShimmerElementType as ElemType } from 'office-ui-fabric-react/lib/Shimmer';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { IActivityActionAttribute } from '../../../../contracts/IActivityAction';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { AuthorizationService } from'@commercialsalesandmarketing/pet-common';
import { Permissions } from '../../../../contracts/Permissions';
import { DialogAction, ActivityAction } from '../../../../contracts/ActivityAction';
import { IDetailsHeaderProps } from "office-ui-fabric-react/lib/components/DetailsList/DetailsHeader";
import { Sticky, StickyPositionType } from 'office-ui-fabric-react/lib/Sticky';
import { IRenderFunction, } from 'office-ui-fabric-react/lib/Utilities';
import { ScrollablePane, IScrollablePane, ScrollbarVisibility } from 'office-ui-fabric-react/lib/ScrollablePane';
import { IActivitySearch } from '../../../../contracts/IActivitySearch';
import { ActivityType } from '../../../../contracts/ActivityTypeEnum';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { isNullOrUndefined } from 'util';
import { IActivityDeifinitionMember } from '../../../../contracts/IActivityDefinitionMember';
import { HierarchyService } from '@commercialsalesandmarketing/pet-org-hierarchy';
let _columns: IColumn[];


export class ActivityList extends React.Component<
  {
    SelectedHierarchyNodeId: string,
    ActivityDef: IActivityDefinition,
    UpdateActivityOnAction: IActivityActionAttribute,
    HandleActivitySelection: (selectedActivity: any) => void
    OnPOClick: (OnActivityDetails: boolean, SelectionDetails: any) => void,
    ShowActivityDetails: () => void,
    roles: any[],
    ActivitySearch: IActivitySearch,
    ActivitySearchList: (activitiesList: any[]) => void,
    AllTaxonomies: any,
    IsSearchInProgress: boolean,
    SelectedActivityColumns: IActivityDeifinitionMember[],
    UserPrefColumn: string,
    LoadFullGrid:boolean,
    selectedActivity:any
  },
  {
    items: Array<{}>;
    fullList: Array<any>;
    activitiesSearchList: Array<{}>
    selectionDetails?: string;
    columns: IColumn[];
    isColumnReorderEnabled: boolean;
    frozenColumnCountFromStart: string;
    frozenColumnCountFromEnd: string;
    isLoading: boolean,
    isError: boolean,
    erroDetails: string,
    editIconId: string,
    isPOMapRoleAvailable: boolean
    cutActivities: any[]
    copiedActivities: any[],
    isActivityMoved:boolean
   // isSearched:boolean
  }
  > {
  private _selection: Selection;
  private _scrollablePane = React.createRef<IScrollablePane>();
  private _detailsList = React.createRef<IDetailsList>();
  constructor(props: {
    SelectedHierarchyNodeId: string,
    ActivityDef: IActivityDefinition,
    UpdateActivityOnAction: IActivityActionAttribute,
    HandleActivitySelection: (selectedActivity: any) => void
    OnPOClick: (OnActivityDetails: boolean, SelectionDetails: any) => void,
    ShowActivityDetails: () => void,
    ActivitySearchList: (activitiesList: any[]) => void,
    roles: [],
    ActivitySearch: IActivitySearch,
    AllTaxonomies: any,
    IsSearchInProgress: boolean,
    SelectedActivityColumns: [],
    UserPrefColumn: "",
    LoadFullGrid:boolean,
    selectedActivity:any
  }) {
    super(props);
    this.onRenderDetailsHeader = this.onRenderDetailsHeader.bind(this);
    this._onRenderItemColumn = this._onRenderItemColumn.bind(this);
    this._handleColumnReorder = this._handleColumnReorder.bind(this);
    this._getColumnReorderOptions = this._getColumnReorderOptions.bind(this);
    this._onChangeColumnReorderEnabled = this._onChangeColumnReorderEnabled.bind(this);
    this._onChangeStartCountText = this._onChangeStartCountText.bind(this);
    this._onChangeEndCountText = this._onChangeEndCountText.bind(this);
    this._onItemInvoked = this._onItemInvoked.bind(this);
    this._onOutlookClicked = this._onOutlookClicked.bind(this);
    this._onActivityNameChanged = this._onActivityNameChanged.bind(this);
    this.UpdateGrid = this.UpdateGrid.bind(this);
    this._onSelectionChanged = this._onSelectionChanged.bind(this);
    this._handleActivityActionButtonClick = this._handleActivityActionButtonClick.bind(this);
    this._handleDialogActionClick = this._handleDialogActionClick.bind(this);
    this._onCreateActivityorFolder = this._onCreateActivityorFolder.bind(this);
    this._onExpandAllorCollapseAll = this._onExpandAllorCollapseAll.bind(this);
    this._onEditActivityClicked = this._onEditActivityClicked.bind(this);
    this._resetActivityList = this._resetActivityList.bind(this);
    this._forceGridUpdate = this._forceGridUpdate.bind(this);
    this._setSelection = this._setSelection.bind(this);
    this._findInsertIndex = this._findInsertIndex.bind(this);
    this._updateActivityList = this._updateActivityList.bind(this);
    this._updateActivitySearchList = this._updateActivitySearchList.bind(this);
    this._handleActivitySearch = this._handleActivitySearch.bind(this);
    this._filterActivitiesList = this._filterActivitiesList.bind(this);
    this._filterActivity = this._filterActivity.bind(this);
    this._filterCategory = this._filterCategory.bind(this);
    this._onRemoveActivityorFolder = this._onRemoveActivityorFolder.bind(this);
    this._removeItemFromArray = this._removeItemFromArray.bind(this);
    this._onCutActivityorFolder = this._onCutActivityorFolder.bind(this);
    this._onPasteActivityorFolder = this._onPasteActivityorFolder.bind(this);
    this._reAddCutActivityOrFolder = this._reAddCutActivityOrFolder.bind(this);
    this._cutFolderFromList = this._cutFolderFromList.bind(this);
    this.resolveTaxonomy = this.resolveTaxonomy.bind(this);
    this._onCopyActivityorFolder = this._onCopyActivityorFolder.bind(this);
    this._onCopyPasteActivityorFolder = this._onCopyPasteActivityorFolder.bind(this);
    this._onCustomColumnChange = this._onCustomColumnChange.bind(this);
    this._onShowDetailsClick = this._onShowDetailsClick.bind(this);
    this._selection = new Selection({
      onSelectionChanged: () => {
        this._onSelectionChanged();
      }
    });

    this.state = {
      items: [],
      columns: _columns,
      isColumnReorderEnabled: false,
      frozenColumnCountFromStart: '1',
      frozenColumnCountFromEnd: '0',
      isLoading: true,
      isError: false,
      erroDetails: '',
      fullList: [],
      activitiesSearchList: [],
      editIconId: '',
      isPOMapRoleAvailable: true,
      cutActivities: [],
      copiedActivities: [],
      isActivityMoved:false
     // isSearched:false
    };
  }

  public UpdateGrid(ActivityDef: any, SelectedHierarchyNodeId: string, roles: any[], SelectedActivityColumns: IActivityDeifinitionMember[], UserPrefColumn: string, activitySearch: IActivitySearch,selectedActivity:any={}): void {
    const authorizationService = new AuthorizationService(AppSettings);
    const isRoleAvailable = authorizationService.IsRolePresent(roles, Permissions.POAssociation);
    this.setState({ isPOMapRoleAvailable: isRoleAvailable });
    //Load the ActivityGrid Columns
    this.setState({ columns: this.getColumns(ActivityDef, SelectedActivityColumns, UserPrefColumn, !isNullOrUndefined(activitySearch) && !activitySearch.ClearSearch) });

    this.setState({ isLoading: true });

    adalApiFetch(fetch, `${AppSettings.ActivityBaseUrl()}/${Config.Actvity.SearchByOrgPath}/${SelectedHierarchyNodeId}`, {})
      .then(response => response.json())
      .then(data => {
        const activityListService = new ActivityListService();
        const activityDefinitionsForSearchedWorkspaces = !isNullOrUndefined(this.props.ActivitySearch) ? this.props.ActivitySearch.ActivityDefinitionsForSearchedWorkspaces : [];
        const _activitiesSummary = activityListService.MapActivityList(data, this.props.ActivityDef, activityDefinitionsForSearchedWorkspaces);
        this.setState({
          items: _activitiesSummary ? _activitiesSummary : [],
          fullList: _activitiesSummary ? _activitiesSummary : [],
          activitiesSearchList: _activitiesSummary ? _activitiesSummary : [],
          isLoading: false
        }, () => {
          this.props.ActivitySearchList(this.state.activitiesSearchList);
        });       
          const index = this._selection.getSelectedIndices()[0];
          if (index > 0) {
            this._selection.toggleIndexSelected(index);
          }
          else {
            this._selection.toggleIndexSelected(0);
          }
          if (_activitiesSummary !== null && _activitiesSummary !== undefined && _activitiesSummary.length > 0) {
            this._selection.setIndexSelected(0, true, false);
          }
        
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isError: true,
          erroDetails: error,
          cutActivities: []
        });
      });
  }


  public UpdateFullGrid(ActivityDef: any, SelectedHierarchyNodeId: string, roles: any[], SelectedActivityColumns: IActivityDeifinitionMember[], UserPrefColumn: string, activitySearch: IActivitySearch,selectedActivity:any={}): void {
    const authorizationService = new AuthorizationService(AppSettings);
    const isRoleAvailable = authorizationService.IsRolePresent(roles, Permissions.POAssociation);
    this.setState({ isPOMapRoleAvailable: isRoleAvailable });
    //Load the ActivityGrid Columns
    this.setState({ columns: this.getColumns(ActivityDef, SelectedActivityColumns, UserPrefColumn, !isNullOrUndefined(activitySearch) && !activitySearch.ClearSearch) });

    this.setState({ isLoading: true });

    adalApiFetch(fetch, `${AppSettings.ActivityBaseUrl()}/${Config.Actvity.SearchByOrgPath}/${SelectedHierarchyNodeId}`, {})
      .then(response => response.json())
      .then(data => {
        const activityListService = new ActivityListService();
        const activityDefinitionsForSearchedWorkspaces = !isNullOrUndefined(this.props.ActivitySearch) ? this.props.ActivitySearch.ActivityDefinitionsForSearchedWorkspaces : [];
        const _activitiesSummary = activityListService.MapActivityList(data, this.props.ActivityDef, activityDefinitionsForSearchedWorkspaces);
        this.setState({
          items: _activitiesSummary ? _activitiesSummary : [],
          fullList: _activitiesSummary ? _activitiesSummary : [],
          activitiesSearchList: _activitiesSummary ? _activitiesSummary : [],
          isLoading: false,
          isActivityMoved:false
        }, () => {
          if(selectedActivity && selectedActivity.id){
            var movedActivity = _activitiesSummary.filter(x => x.id === selectedActivity.id)[0];
            this._setSelection(_activitiesSummary,movedActivity);  
            this._updateActivitySearchList(movedActivity);           
          }           
        });
      
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isError: true,
          erroDetails: error,
          cutActivities: []
        });
      });
  }

  private getColumns(ActivityDef: any, SelectedActivityColumns: IActivityDeifinitionMember[], UserPrefColumn: string, isInSearchMode: boolean): IColumn[] {

    const activityDefinitionService = new ActivityDefinitionService();
    const hierarchyService = new HierarchyService(AppSettings);
    if (SelectedActivityColumns && SelectedActivityColumns.length > 0) {
      const _summaryAttributes = activityDefinitionService.MapActivityDefitionMemberToColumnsToDisplay(SelectedActivityColumns, isInSearchMode);
      _columns = _summaryAttributes;
      // update the DB on column selection change
      const columnsToSave = activityDefinitionService.MapActivityDefitionMemberToJsonString(SelectedActivityColumns);
      hierarchyService.UpdateUserPreferenceForWorkspace(columnsToSave, Config.PreferenceKeys.ActivityColumnView);
    }
    else {

      if (UserPrefColumn) {
        const _summaryAttributes = activityDefinitionService.MapActivityDefitionColumnsAsDBView(ActivityDef, UserPrefColumn);
        const _DisplayAttributes = activityDefinitionService.MapActivityDefitionMemberToColumnsToDisplay(_summaryAttributes, isInSearchMode);
        _columns = _DisplayAttributes;
      }
      else {
        //First time user logging in. No preference found in DB. Show the default summaray view as prefred view
        const _summaryAttributes = activityDefinitionService.MapActivityDefitionColumnsAsSummaryView(ActivityDef);
        const _DisplayAttributes = activityDefinitionService.MapActivityDefitionMemberToColumnsToDisplay(_summaryAttributes, isInSearchMode);
        _columns = _DisplayAttributes;
        // update the default summaray view as prefred view in DB
        const columnsToSave = activityDefinitionService.MapActivityDefitionMemberToJsonString(_summaryAttributes);
        hierarchyService.UpdateUserPreferenceForWorkspace(columnsToSave, Config.PreferenceKeys.ActivityColumnView);

      }

    }
    return _columns;
  }

  public componentDidMount() {
    if (this.props.SelectedHierarchyNodeId && this.props.ActivityDef
      && this.props.ActivityDef.members && this.props.ActivityDef.members.length > 0) {
      if (this.props.IsSearchInProgress) this.setState({ isLoading: true }, () => { this.UpdateGrid(this.props.ActivityDef, this.props.SelectedHierarchyNodeId, this.props.roles, this.props.SelectedActivityColumns, this.props.UserPrefColumn, this.props.ActivitySearch); });
      else if (!this.props.IsSearchInProgress && !this.props.ActivitySearch.ClearSearch) this.setState({ isLoading: false }, () => { this.UpdateGrid(this.props.ActivityDef, this.props.SelectedHierarchyNodeId, this.props.roles, this.props.SelectedActivityColumns, this.props.UserPrefColumn, this.props.ActivitySearch); });
      else { this.UpdateGrid(this.props.ActivityDef, this.props.SelectedHierarchyNodeId, this.props.roles, this.props.SelectedActivityColumns, this.props.UserPrefColumn, this.props.ActivitySearch); }
    }
  }

  public componentWillReceiveProps(nextProps: any) {   
    if (nextProps.IsSearchInProgress) {
       this.setState({ isLoading: true }, () => { 
         this.enrich(nextProps); });
    }
    else if (!nextProps.IsSearchInProgress && !nextProps.ActivitySearch.ClearSearch) 
    {
          this.setState({ isLoading: false })
          this.enrich(nextProps); 
    }
    else { this.enrich(nextProps); }
  }

  public enrich = (nextProps: any) => {
    if (nextProps.UpdateActivityOnAction !== null && nextProps.UpdateActivityOnAction.ActivityAction !== ActivityAction.none) {
      this._handleActivityActionButtonClick(nextProps);
    }
    else if (this.props.SelectedHierarchyNodeId !== nextProps.SelectedHierarchyNodeId ||
      (!this.props.ActivitySearch.ClearSearch && nextProps.ActivitySearch.ClearSearch)) {
      this.UpdateGrid(nextProps.ActivityDef, nextProps.SelectedHierarchyNodeId, nextProps.roles, nextProps.SelectedActivityColumns, nextProps.UserPrefColumn, nextProps.ActivitySearch);
    }
    else if (nextProps.UpdateActivityOnAction.DialogAction !== DialogAction.none) {
      this._handleDialogActionClick(nextProps.UpdateActivityOnAction);
    }    
    else if (nextProps.UpdateActivityOnAction.SaveComplete) {
      if(nextProps.LoadFullGrid  && this.state.isActivityMoved){
        this.UpdateFullGrid(nextProps.ActivityDef, nextProps.SelectedHierarchyNodeId, nextProps.roles, 
                        nextProps.SelectedActivityColumns, nextProps.UserPrefColumn, nextProps.ActivitySearch,nextProps.selectedActivity);
      }
      else{
      this._updateActivityList(nextProps.UpdateActivityOnAction.Activity);
      }
    }
    
    if (this.props.ActivitySearch === null || nextProps.ActivitySearch !== null) {
      this._handleActivitySearch(nextProps.ActivitySearch);
    }
  }

  public render(): JSX.Element {
    const { items, columns } = this.state;

    return (
      <Stack horizontal className={"gridMaxHeight"} aria-describedby="Manage Activities" aria-label="Manage Activities" aria-disabled={false}>

        <Stack.Item grow className="activityList" >          
            {
              this.state.isLoading ?
                <div>
                  <br />
                  <Shimmer
                    shimmerElements={[{ type: ElemType.circle }, { type: ElemType.gap, width: '2%' }, { type: ElemType.line }]}
                  />
                  <br />
                  <Shimmer
                    shimmerElements={[{ type: ElemType.circle }, { type: ElemType.gap, width: '2%' }, { type: ElemType.line }]}
                  />
                  <br />
                  <Shimmer
                    shimmerElements={[{ type: ElemType.circle }, { type: ElemType.gap, width: '2%' }, { type: ElemType.line }]}
                  />
                  <br />
                  <Shimmer
                    shimmerElements={[{ type: ElemType.circle }, { type: ElemType.gap, width: '2%' }, { type: ElemType.line }]}
                  />
                  <br />
                </div>
                :
                <ScrollablePane componentRef={this._scrollablePane} scrollbarVisibility={ScrollbarVisibility.auto}
                  styles={{ root:{position:'relative', height:'100%', width: '100%'},
                  contentContainer:{position:'relative', height:'100%', width: '100%'} }}>
                  <MarqueeSelection selection={this._selection}>
                    <DetailsList
                      setKey={'items'}
                      items={items}
                      columns={columns}
                      selection={this._selection}
                      selectionPreservedOnEmptyClick={false}
                      onItemInvoked={this._onItemInvoked}
                      onRenderItemColumn={this._onRenderItemColumn}
                      checkboxVisibility={CheckboxVisibility.hidden}
                      /*dragDropEvents={this._getDragDropEvents()}*/
                      columnReorderOptions={this.state.isColumnReorderEnabled ? this._getColumnReorderOptions() : undefined}
                      ariaLabelForSelectionColumn="Toggle selection"
                      ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                      layoutMode={DetailsListLayoutMode.justified}
                      componentRef={this._detailsList}
                      constrainMode={ConstrainMode.unconstrained}
                      onRenderDetailsHeader={this.onRenderDetailsHeader}
                      isHeaderVisible={true}
                      onShouldVirtualize={ () => false }
                      styles={{
                        headerWrapper: {
                          backgroundColor: "rgb(104, 103, 103)"
                        }
                      }}
                      ariaLabelForGrid="Activities List"
                      ariaLabel="Activities List"
                    />
                  </MarqueeSelection>
                </ScrollablePane>
            }          
        </Stack.Item>
        <Stack.Item align="center">
          <div className="show-hide-button"><IconButton
            iconProps={{ iconName: 'DoubleChevronLeft8' }}
            title="Show Details" ariaLabel="Show Activity Details" id="ActivityDetailsview"
            style={{ color: 'white' }}
            onClick={this._onShowDetailsClick}
          /></div>
        </Stack.Item>
      </Stack>
    );
  }

  private onRenderDetailsHeader(props: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
    props.styles={
      root:{
        paddingTop:'0px !important',
        paddingBottom:'0px !important'
      }
    }
    return (
      <Sticky stickyClassName="stickyHeaderRow" stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
        {defaultRender!({
          ...props
        })}
      </Sticky>
    );
  }
  private _onShowDetailsClick() {
    const item = this.state.items[this._selection.getSelectedIndices()[0]] as any;
    if (!isNullOrUndefined(item)) this._handleActivitySelection(item);
    this.props.ShowActivityDetails();
  }
  private _handleColumnReorder = (draggedIndex: number, targetIndex: number) => {
    const draggedItems = this.state.columns[draggedIndex];
    const newColumns: IColumn[] = [...this.state.columns];

    // insert before the dropped item
    newColumns.splice(draggedIndex, 1);
    newColumns.splice(targetIndex, 0, draggedItems);
    this.setState({ columns: newColumns });
  };


  private _getColumnReorderOptions(): IColumnReorderOptions {
    return {
      frozenColumnCountFromStart: parseInt(this.state.frozenColumnCountFromStart, 10),
      frozenColumnCountFromEnd: parseInt(this.state.frozenColumnCountFromEnd, 10),
      handleColumnReorder: this._handleColumnReorder
    };
  }

  private _onChangeStartCountText = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
    this.setState({ frozenColumnCountFromStart: text });
  };

  private _onChangeEndCountText = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
    this.setState({ frozenColumnCountFromEnd: text });
  };

  private _onChangeColumnReorderEnabled = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
    this.setState({ isColumnReorderEnabled: checked });
  };

  /*private _getDragDropEvents(): IDragDropEvents {
    return {
      canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
        return true;
      },
      canDrag: (item?: any) => {
        return true;
      },
      onDragEnter: (item?: any, event?: DragEvent) => {
        return 'dragEnter';
      }, // return string is the css classes that will be added to the entering element.
      onDragLeave: (item?: any, event?: DragEvent) => {
        return;
      },
      onDrop: (item?: any, event?: DragEvent) => {
        if (_draggedItem) {
          this._insertBeforeItem(item);
        }
      },
      onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
        _draggedItem = item;
        _draggedIndex = itemIndex!;
      },
      onDragEnd: (item?: any, event?: DragEvent) => {
        _draggedItem = null;
        _draggedIndex = -1;
      }
    };
  }*/

  private _onItemInvoked(item: any, index?: number, ev?: Event): void {
    this.props.HandleActivitySelection(item);
  }

  private _onOutlookClicked(item: any): void {
    const activityListService = new ActivityListService();
    this.props.OnPOClick(false, activityListService.GetActivityList(item, this.state.fullList));
  }

  private iterateAndDetermineHide(parentId: any, list: any[]): boolean {
    for (let idx = 0; idx < this.state.fullList.length; idx++) {
      const castedElement = this.state.fullList[idx];
      if (castedElement.id === parentId) {
        parentId = castedElement.parent_node_id;
        if (castedElement.iscollapsed) {
          return true;
        }
      }
    }
    return false;
  }

  private toggleCollapse(collabseParentId: string): void {
    let newItems: Array<{}>;
    newItems = [];
    this.state.fullList.forEach(newItem => {
      const castedItem = newItem as any;
      if (collabseParentId === castedItem.id) {
        castedItem.iscollapsed = !castedItem.iscollapsed;
      }
      newItems.push(castedItem);
    });
    this.setState({ items: newItems });
    this.setState({ fullList: newItems });

    newItems = [];
    this.state.fullList.forEach(newItem => {
      const castedItem = newItem as any;
      let hideItem: boolean;
      hideItem = false;
      let parentId = castedItem.parent_node_id;
      while (parentId) {
        hideItem = this.iterateAndDetermineHide(parentId, this.state.fullList);
        if (hideItem) {
          break;
        }
      }
      if (!hideItem) {
        newItems.push(castedItem);
      }
    });
    this.setState({ items: newItems });
  };

  private _onRenderItemColumn(item: any, index: number, column: IColumn): JSX.Element {
    const collabseParentId = item.id;
    const toggleCollapse = () => {
      this.toggleCollapse(collabseParentId);
    }
    const onOutlookClick = () => {
      this._onOutlookClicked(item);
    }

    let tabs: any[];
    tabs = [];
    let level: number;
    level = item.level;
    for (let i = 0; i < level; i++) {
      tabs.push(<span key={i} role={!column.isRowHeader ? 'rowheader' : 'gridcell'} className="float-left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>);
    }
    if (!item.is_edit) {
      if (column.key === 'name' && item.is_folder) {
        return <div key={item.id} role={"presentation"} className="divActivityTitle" id={"MActivityId" + index}>
          {tabs}
          <IconButton key={item.id} iconProps={{ iconName: item.iscollapsed ? 'ChevronDownMed' : 'ChevronUpMed' }} ariaLabel={item.iscollapsed ? "Expand" : "Collapase"}
            onClick={toggleCollapse} />
          <Icon iconName={item.type === ActivityType.Category ? "FolderOpen" : "FormLibrary"} ariaLabel={item.type === ActivityType.Category ? "Category" : "Sub-category"} title={item.type === ActivityType.Category ? "Category" : "Sub-category"} />
          <span>&nbsp;</span>
          <span title={item[column.key]}> {item.name} </span>
        </div>;
      }
      if (column.key === 'name') {
        return <div role={"presentation"} className="divActivityTitle" id={"SActivityId" + index}>{tabs}
          <Icon iconName="Table" ariaLabel="Activity" title="Activity" />
          <span>&nbsp;</span>
          <span className="wrap-span" title={item[column.key]} onClick={this._onShowDetailsClick} style={{cursor:'pointer'}} >{item[column.key]}</span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>;
      }
      if (column.key === 'oamt') {

        return <div>
          <Link role={'button'} className="wrap-span" aria-label="OutlookAmtCD" data-name='OutlookAmtCD' onClick={onOutlookClick} onKeyDown={onOutlookClick} disabled={!this.state.isPOMapRoleAvailable} id={"OutlookAmtCDid" + index}
          style={{textDecoration: 'underline'}} >
            {item[column.key]}
          </Link>
        </div>;
      }
      if (column.key === 'parent_org_id') {
        return this.resolveWorkspaceName(item[column.key]);
      }
      if (column.data.type === 'relation' && column.data.selectiontype === 'single') {
        return this.resolveSingleSelectRelation(item[column.key], column.data.defintionId);
      }
      if ((column.data.type === 'relation' || column.data.type === 'io') && column.data.selectiontype === 'multi') {
        const items = this.resolveMultiSelectRelation(item[column.key], column.data.defintionId);
        if (items.length > 0) {
          return (items.map(i => {
            return (
              <div key={item.id + i}>
                {i}
                <br />
              </div>
            );

          }));
        }
        return this.resolveMultiSelectRelation(item[column.key], column.data.defintionId);
      }
      if (column.key.includes("date") && !isNullOrUndefined(item[column.key])) {
        const dt = new Date(item[column.key].toString());
        return <span>{dt.toLocaleString()}</span>;
      }

      return item[column.key];
    }
    else {
      if (column.key === 'name') {
        let txtClassName: string = '';
        if (item.haschildnodes) {
          txtClassName = item.level === 0 ? "activity-TextField-Div-89" : item.level === 1 ? "activity-TextField-Div-81" : item.level === 2 ? "activity-TextField-Div-72" : "activity-TextField-Div-72";
        }
        else {
          txtClassName = item.level === 0 ? "activity-TextField-Div-97" : item.level === 1 ? "activity-TextField-Div-89" : item.level === 2 ? "activity-TextField-Div-81" : "activity-TextField-Div-72";
        }
        return <div key={item.id} className="activity-TextField-Div" >
          {tabs}
          <div className={txtClassName} id="divEditActivity">
            <TextField id="txtActivity" placeholder={item.is_folder ? "New Folder" : "New Activity"}
              borderless={true} defaultValue={item.name}
              onChange={this._onActivityNameChanged.bind(this, item)} />
          </div>
          {item.haschildnodes &&
            <IconButton key={item.id} iconProps={{ iconName: item.iscollapsed ? 'ChevronDownMed' : 'ChevronUpMed' }} ariaLabel={item.iscollapsed ? "Expand" : "Collapase"}
              onClick={toggleCollapse} />}
          <Icon iconName={item.type === ActivityType.Category ? "FolderOpen" : item.type === ActivityType.SubCategory ? "FormLibrary" : "Table"} ariaLabel={item.type === ActivityType.Category ? "Category" : item.type === ActivityType.SubCategory ? "Sub-category" : "Activity"} title={item.type === ActivityType.Category ? "Category" : item.type === ActivityType.SubCategory ? "Sub-category" : "Activity"} />

        </div>;
      }
      else {
        if (column.key.includes("date") && !isNullOrUndefined(item[column.key])) {
          const dt = new Date(item[column.key].toString());
          return <span>{dt.toLocaleString()}</span>;
        }
        return item[column.key];
      }
    }
  }

  private resolveWorkspaceName(workspaceId: any): any {
    if (!isNullOrUndefined(workspaceId) && !isNullOrUndefined(this.props.ActivitySearch)
      && !isNullOrUndefined(this.props.ActivitySearch.SelectedWorkspaces) && this.props.ActivitySearch.SelectedWorkspaces.length > 0) {
      const workspaceMatch = this.props.ActivitySearch.SelectedWorkspaces.find(x => x.id === workspaceId);
      if (!isNullOrUndefined(workspaceMatch)) return workspaceMatch.name;
    }
    return '';
  }

  private resolveSingleSelectRelation(item: any, definitionId: string): any {
    if (item && item !== undefined && item.length > 0) {
      return this.resolveTaxonomy(definitionId, item[0].taxonomy_id)
    }
    return '';
  }
  private resolveMultiSelectRelation(item: any, definitionId: string): any {
    let displayList = [];
    if (item && item !== undefined && item.length > 0) {
      for (const value of item) {
        if (value) {
          displayList.push(this.resolveTaxonomy(definitionId, value.taxonomy_id) + '(' + value.percentage + '%)');
        }
      }
    }
    return displayList;
  }
  private resolveTaxonomy(definitionId: string, taxonomyId: string) {
    if (this.props && this.props.AllTaxonomies && this.props.AllTaxonomies[definitionId]) {
      for (let taxonomy of this.props.AllTaxonomies[definitionId]) {
        if (taxonomy.id === taxonomyId) {
          return taxonomy.display_labels[Config.locale];
        }
      }
    }
    return taxonomyId;// todo  
  }
  private _onExpandAllorCollapseAll(iscollapsed: boolean) {
    let newItems: Array<{}>;
    newItems = [];
    this.state.fullList.forEach(newItem => {
      const castedItem = newItem as any;
      castedItem.iscollapsed = iscollapsed;
      newItems.push(castedItem);
    });
    this.setState({ items: newItems });
    this.setState({ fullList: newItems });

    newItems = [];
    this.state.fullList.forEach(newItem => {
      const castedItem = newItem as any;
      let hideItem: boolean;
      hideItem = false;
      let parentId = castedItem.parent_node_id;
      while (parentId) {
        hideItem = this.iterateAndDetermineHide(parentId, this.state.fullList);
        if (hideItem) {
          break;
        }
      }
      if (!hideItem) {
        newItems.push(castedItem);
      }
    });
    this.setState({ items: newItems });

  }

  private _onCreateActivityorFolder(activity: any) {
    const items: any[] = this.state.items;
    if (activity && activity.id !== undefined && !items.filter(x => x.id === activity.id)[0]) {
      const activityListService = new ActivityListService();
      const activityList: any[] = [];
      const activityItem: any = { item: activity, level: 0 }
      activityList.push(activityItem)
      const newactivity = activityListService.MapActivityList(activityList, this.props.ActivityDef)[0];
      // newactivity.is_edit=true;

      this._insertActivityOrCategory(newactivity);
    }
  }

  private _insertActivityOrCategory(newactivity: any) {
    let newItems: any[];
    newItems = [];
    let fullItems: any[];
    fullItems = [];
    // Expand the parent node if collapsed
    this.state.fullList.forEach(newItem => {
      const castedItem = newItem as any;
      if (newactivity.parent_node_id === castedItem.id && castedItem.iscollapsed) {
        castedItem.iscollapsed = !castedItem.iscollapsed;
      }
      newItems.push(castedItem);
    });
    if (newactivity.parent_node_id !== '') {
      const insertIndex: number = this._findInsertIndex(newItems, newactivity.parent_node_id);
      const parentItem = newItems.filter(x => x.id === newactivity.parent_node_id)[0];
      newactivity.level = parentItem ? parentItem.level + 1 : 0;
      newItems.splice(insertIndex + 1, 0, newactivity);
    }
    else {
      newactivity.level = 0;
      newItems.push(newactivity);
    }
    newItems.forEach(newItem => {
      const castedItem = newItem as any;
      let hideItem: boolean;
      hideItem = false;
      let parentId = castedItem.parent_node_id;
      while (parentId) {
        hideItem = this.iterateAndDetermineHide(parentId, this.state.fullList);
        if (hideItem) {
          break;
        }
      }
      if (!hideItem) {
        if (castedItem.is_edit && castedItem.id !== newactivity.id) {
          castedItem.is_edit = false;
        }
        fullItems.push(castedItem);
      }
    });
    if (this.state.isLoading) {
      this.setState({ isLoading: false });
    }
    this.setState({
      fullList: newItems,
      items: fullItems
    }, () => {
      this._setSelection(fullItems, newactivity);
      const activitySearch = this.props.ActivitySearch;
      if (!isNullOrUndefined(activitySearch.Activities) && activitySearch.Activities.length >= 0 && !activitySearch.ClearSearch) {
        this._updateActivitySearchList(newactivity, true);
      }
    })
  }

  private _setSelection(items: any[], editactivity: any, forceUpdate: boolean = false) {
    const item = this._selection.getSelectedIndices()[0];
    const index: number = items.indexOf(editactivity)
    if (index !== item) {
      if (item !== undefined && item > -1) {
        this._selection.toggleIndexSelected(item);
      }

      if (index > -1) {
        if (this._detailsList.current) {
          this._detailsList.current.focusIndex(index, true);
        }
        this._selection.setIndexSelected(index, true, false);
        this.props.HandleActivitySelection(editactivity);
      }
    }
    else if (forceUpdate) {
      this.props.HandleActivitySelection(editactivity);
    }
  }

  private _findInsertIndex(newItems: any[], parentId: string): any {
    const childItems: any[] = newItems.filter(x => x.parent_node_id === parentId);
    if (childItems && childItems.length > 0) {
      const lastChild: any = childItems[childItems.length - 1];
      if (lastChild) {
        if (lastChild.is_folder && !lastChild.iscollapsed) {
          // If last child is folder and is not collapsed then find lastchild 
          return this._findInsertIndex(newItems, lastChild.id)
        }
        else {
          return newItems.indexOf(lastChild);
        }
      }
    }
    else {
      const parentItem = newItems.filter(x => x.id === parentId)[0];
      return newItems.indexOf(parentItem);
    }
  }

  private _onEditActivityClicked(item: any) {
    let newItems: Array<{}>;
    newItems = [];
    this.state.fullList.forEach(newItem => {
      const castedItem = newItem as any;
      if (castedItem.is_edit) {
        castedItem.name = castedItem.temp_name;
        castedItem.is_edit = false;
      }
      if (item.id === castedItem.id) {
        castedItem.is_edit = true;
      }
      newItems.push(castedItem);
    });
    this.setState({ items: newItems, fullList: newItems });

    const fullItems: any[] = [];
    newItems.forEach(newItem => {
      const castedItem = newItem as any;
      let hideItem: boolean;
      hideItem = false;
      let parentId = castedItem.parent_node_id;
      while (parentId) {
        hideItem = this.iterateAndDetermineHide(parentId, this.state.fullList);
        if (hideItem) {
          break;
        }
      }
      if (!hideItem) {
        fullItems.push(castedItem);
      }
    });
    this.setState({ items: fullItems }, () => {
      const index: number = fullItems.indexOf(item)
      this._detailsList.current.focusIndex(index, true)
    });
  }

  private _resetActivityList(activityId:string) {
    const selectedItem = this.state.items[this._selection.getSelectedIndices()[0]] as any;
    var newactivityId: string = 'newactivity';
    var index: number;
    if(activityId){
      index = this.state.fullList.findIndex(actv => actv.id === activityId);
      if(index !== -1){
        newactivityId = activityId;
      }
    }
    
    let newItems: Array<{}>;
    newItems = [];
    this.state.items.forEach(newItem => {
      const castedItem = newItem as any;
      if (castedItem.is_edit) {
        castedItem.name = castedItem.temp_name;
        castedItem.is_edit = false;
      }
      if (castedItem.id !== newactivityId) {
        newItems.push(castedItem);
      }
    });
    this._forceGridUpdate(newItems);

    let newItemsFull: Array<{}> = [];
    this.state.fullList.forEach(newItem => {
      const castedItem = newItem as any;
      if (castedItem.is_edit) {
        castedItem.name = castedItem.temp_name;
        castedItem.is_edit = false;
      }
      if (castedItem.id !== newactivityId) {
        newItemsFull.push(castedItem);
      }
    });

    const searchFullList: any[] = this.state.activitiesSearchList;
    var newActivity = searchFullList.filter(x => x.id === 'newactivity')[0];
    // Remove unsaved ativity or folder from search filtered list
    if (!isNullOrUndefined(newActivity)) {
      index = searchFullList.indexOf(newActivity);
      if (index > -1) {
        searchFullList.splice(index);
        this.setState({
          activitiesSearchList: searchFullList
        })
      }
    }

    this.setState({ fullList: newItemsFull }, () => {
      const selectedItemAfterReset = this.state.items[this._selection.getSelectedIndices()[0]] as any;
      if (selectedItemAfterReset === null || selectedItemAfterReset === undefined || selectedItemAfterReset.id !== selectedItem.id) {
        this._setSelection(this.state.items, selectedItem);
      }
      this._onSelectionChanged();
    })
  }

  private _forceGridUpdate(items: any[]) {
    this.setState({ items }, () => {
      if (this._detailsList.current) {
        this._detailsList.current.forceUpdate();
      }
    });
  }

  /*private _insertBeforeItem(item: any): void {
    const draggedItems = this._selection.isIndexSelected(_draggedIndex) ? this._selection.getSelection() : [_draggedItem];
    const items: any[] = this.state.items.filter((i: number) => draggedItems.indexOf(i) === -1);
    let insertIndex = items.indexOf(item);

    // if dragging/dropping on itself, index will be 0.
    if (insertIndex === -1) {
      insertIndex = 0;
    }

    items.splice(insertIndex, 0, ...draggedItems);

    this.setState({ items });

  }*/

  private _onActivityNameChanged(item: any, ev: any): void {
    item.name = ev.target.value;
    this._handleActivitySelection(item);
  }

  private _handleActivitySelection(item: any): void {

    this.props.HandleActivitySelection(item);

  }

  private _onSelectionChanged() {
    const item = this.state.items[this._selection.getSelectedIndices()[0]] as any;
    if (this.props.UpdateActivityOnAction && this.props.UpdateActivityOnAction.Activity && (this.props.UpdateActivityOnAction.Activity.id === '' || this.props.UpdateActivityOnAction.Activity.id === undefined)) {
      const items: any[] = this.state.items;
      const editItem = items.filter(x => !isNullOrUndefined(x) && x.is_edit === true);
      if (editItem.length > 0 && item.id !== editItem[0].id) {
        this._resetActivityList('')
      }
      else {
        this._handleActivitySelection(item);
      }
    }
    else {
      this._handleActivitySelection(item);
    }
  }

  private _handleActivityActionButtonClick(nextProps: any) {
    if (this.state.cutActivities.length > 0 && nextProps.UpdateActivityOnAction.ActivityAction !== ActivityAction.Paste
      && nextProps.UpdateActivityOnAction.ActivityAction !== ActivityAction.ExpandAll && nextProps.UpdateActivityOnAction.ActivityAction !== ActivityAction.CollapseAll) {
      this._reAddCutActivityOrFolder(this.state.cutActivities, nextProps);
    }
    else {
      return this._handleActivityActionSwitch(nextProps);
    }
  }

  private _handleActivityActionSwitch(nextProps: any) {
    switch (nextProps.UpdateActivityOnAction.ActivityAction) {
      case ActivityAction.AddActivity:
        return this._onCreateActivityorFolder(nextProps.UpdateActivityOnAction.Activity);
      case ActivityAction.AddFolder:
        return this._onCreateActivityorFolder(nextProps.UpdateActivityOnAction.Activity);
      case ActivityAction.ExpandAll:
        return this._onExpandAllorCollapseAll(false);
      case ActivityAction.CollapseAll:
        return this._onExpandAllorCollapseAll(true);
      case ActivityAction.Delete:
        return this._onRemoveActivityorFolder(nextProps.UpdateActivityOnAction.Activity);
      case ActivityAction.Cut:
        return this._onCutActivityorFolder(nextProps.UpdateActivityOnAction.Activity);
      case ActivityAction.Copy:
        return this._onCopyActivityorFolder(nextProps.UpdateActivityOnAction.Activity);
      case ActivityAction.Paste:
        return this._onPasteActivityorFolder(nextProps.UpdateActivityOnAction.ActivitiesFullList, nextProps.UpdateActivityOnAction.Activity,nextProps.UpdateActivityOnAction.MoveActivity);
      case ActivityAction.CustomColumnSelection:
        return this._onCustomColumnChange(nextProps);
      default:
        return;
    }
  }

  private _handleDialogActionClick(actionAttributes: IActivityActionAttribute) {
    if (actionAttributes.DialogAction === DialogAction.No) {
      this._resetActivityList(actionAttributes.movedActivityId);
    }
    else if (actionAttributes.DialogAction === DialogAction.Yes) {
      const items: any[] = this.state.items;
      this._setSelection(items, actionAttributes.Activity);
    }
  }

  private _updateActivityList(activity: any) {
    const activityListService = new ActivityListService();
    const activityList: any[] = [];
    const activityItem: any = { item: activity, level: 0 }
    activityList.push(activityItem);
    const _activityDefinitionsForSearchedWorkspaces = !isNullOrUndefined(this.props.ActivitySearch) ? this.props.ActivitySearch.ActivityDefinitionsForSearchedWorkspaces : [];
    const activities = activityListService.MapActivityList(activityList, this.props.ActivityDef, _activityDefinitionsForSearchedWorkspaces)[0];
    activities.is_edit = false;
    const newactivityid: string = 'newactivity';
    let newItems: Array<{}>;
    newItems = [];
    this.state.items.forEach(newItem => {
      const castedItem = newItem as any;
      if (castedItem.is_edit) {
        castedItem.is_edit = false;
      }
      if (castedItem.id !== activity.id && castedItem.id !== newactivityid) {
        if (castedItem.id === activity.parent_node_id) {
          castedItem.haschildnodes = true;
        }
        newItems.push(castedItem);
      }
      else {
        activities.level = castedItem.level;
        activities.haschildnodes = castedItem.haschildnodes;
        newItems.push(activities);
      }
    });
    this._forceGridUpdate(newItems);

    newItems = [];
    this.state.fullList.forEach(newItem => {
      const castedItem = newItem as any;
      if (castedItem.is_edit) {
        castedItem.is_edit = false;
      }
      if (castedItem.id !== activity.id && castedItem.id !== newactivityid) {
        if (castedItem.id === activity.parent_node_id) {
          castedItem.haschildnodes = true;
        }
        newItems.push(castedItem);
      }
      else {
        activities.level = castedItem.level;
        activities.haschildnodes = castedItem.haschildnodes;
        newItems.push(activities);
      }
    });
    this.setState({ fullList: newItems }, () => {
      this._handleActivitySelection(activities);
      this._updateActivitySearchList(activities);
    });
  }

  private _updateActivitySearchList(activity: any, addnewactivityorFolder: boolean = false) {
    const fullItems: any[] = this.state.activitiesSearchList;
    const item: any[] = fullItems.filter(x => x.id === activity.id);
    if (item.length > 0) {
      const index: number = fullItems.indexOf(item[0]);
      fullItems.splice(index, 1, activity);
      this.setState({
        activitiesSearchList: fullItems
      }, () => {
        if (!addnewactivityorFolder)
          this.props.ActivitySearchList(fullItems);
      });
    }
    else {
      fullItems.push(activity);
      this.setState({
        activitiesSearchList: fullItems
      }, () => {
        if (!addnewactivityorFolder)
          this.props.ActivitySearchList(fullItems);
      });
    }
  }

  private _handleActivitySearch(activitySearch: IActivitySearch) {
    if (activitySearch.ClearSearch && this.props.ActivitySearch.ClearSearch !== activitySearch.ClearSearch) {
      this.setState({ fullList: this.state.activitiesSearchList });
      this._forceGridUpdate(this.state.activitiesSearchList);
    }
    else if (!isNullOrUndefined(activitySearch.Activities) && activitySearch.Activities.length >= 0 && !activitySearch.ClearSearch) {
      if (activitySearch.ShowHierarchy ){ 
         if( isNullOrUndefined(this.props.ActivitySearch) || isNullOrUndefined(this.props.ActivitySearch.Activities[0]) || this.props.ActivitySearch.Activities[0].id!==activitySearch.Activities[0].id)
         this._filterActivitiesList(activitySearch.Activities[0]);}
      else {
        const activityListService: ActivityListService = new ActivityListService();
        const mappedActivities = activityListService.MapSearchResults(activitySearch.Activities, this.props.ActivityDef, activitySearch.ActivityDefinitionsForSearchedWorkspaces);
        let forceUpdate: boolean = true;
        if (!isNullOrUndefined(this.state.fullList) && !isNullOrUndefined(mappedActivities) && (this.state.fullList.length > 0 && mappedActivities.length > 0
          && this.state.fullList.length === mappedActivities.length && this.state.fullList[0].activityid === mappedActivities[0].activityid)) {
          forceUpdate = false;
        }

        if (forceUpdate) {
          const isInSearchMode = !isNullOrUndefined(this.props.ActivitySearch) && !this.props.ActivitySearch.ClearSearch;
          const _customColumns =
            this.getColumns(this.props.ActivityDef, this.props.SelectedActivityColumns, this.props.UserPrefColumn, isInSearchMode);
          this.setState({ fullList: mappedActivities, items: mappedActivities, columns: _customColumns }, () => {
            if (mappedActivities.length > 0) this._setSelection(mappedActivities, mappedActivities[0], forceUpdate);
            this._forceGridUpdate(mappedActivities);
          });
        }
      }
    }
  }

  private _filterActivitiesList(activity: any) {
    const fullitems: any[] = this.state.activitiesSearchList;
    const newItems: any[] = [];
    if (activity.is_folder) {
      this._filterActivity(fullitems, activity.id, newItems);
      this._filterCategory(fullitems, activity, newItems);
    }
    else {
      this._filterActivity(fullitems, activity.id, newItems);
    }
    this._forceGridUpdate(newItems);
    this.setState({ fullList: newItems}, () => {
      // var newActivityOrFolder = newItems.filter(x => x.id === 'newactivity')[0];

      // const item = this._selection.getSelectedIndices()[0];
      // const index: number = items.indexOf(editactivity)
      this._setSelection(newItems, activity);
    });
  }

  private _filterActivity(fullitems: any[], activityid: string, newItems: any[]) {
    const item: any = fullitems.filter(a => a.id === activityid)[0];
    if (!isNullOrUndefined(item) && item.parent_node_id !== null && item.parent_node_id !== '' && item.parent_node_id !== undefined) {
      this._filterActivity(fullitems, item.parent_node_id, newItems);
      newItems.push(item);
    }
    else {
      newItems.push(item);
    }
  }

  private _filterCategory(fullitems: any[], activity: any, newItems: any[]) {
    const items: any[] = fullitems.filter(a => !isNullOrUndefined(a) && a.parent_node_id === activity.id);
    if (items.length > 0) {
      items.forEach(child => {
        newItems.push(child);
        if (child.is_folder) {
          this._filterCategory(fullitems, child, newItems);
        }
      });
    }
  }

  private _onRemoveActivityorFolder(activity: any) {

    let activitiesSearchList: any[] = this.state.activitiesSearchList;
    let fullList: any[] = this.state.fullList;
    let items: any[] = this.state.items;


    if (!activity.is_folder || !activity.haschildnodes) {
      items = this._removeItemFromArray(items, activity.id).fullList;
      fullList = this._removeItemFromArray(fullList, activity.id).fullList;
      activitiesSearchList = this._removeItemFromArray(activitiesSearchList, activity.id).fullList;
    }
    else {
      items = this._removeFolderFromList(items, activity.id);
      fullList = this._removeFolderFromList(fullList, activity.id);
      activitiesSearchList = this._removeFolderFromList(activitiesSearchList, activity.id);
    }

    this.setState({ fullList }, () => {
      this._forceGridUpdate(items);
    });

    this.setState({ activitiesSearchList }, () => {
      const item = this._selection.getSelectedIndices()[0];
      if (item >= 0 && this._detailsList.current) {
        this._detailsList.current.focusIndex(item, true)
        this._handleActivitySelection(items[item]);
      }
      this.props.ActivitySearchList(activitiesSearchList);
    });
  }

  private _removeItemFromArray(fullList: any[], activityid: string) {
    const item: any[] = fullList.filter(x => x.id === activityid);
    let index: number = 0;
    if (item.length > 0) {
      index = fullList.indexOf(item[0]);
      fullList.splice(index, 1);
    }
    return { fullList, index };
  }

  private _removeFolderFromList(items: any[], folderId: string): any[] {
    let fullItems: Array<{}> = [];
    let newItems: Array<{}>;
    items.forEach(newItem => {
      const castedItem = newItem as any;
      if (folderId === castedItem.id) {
        castedItem.is_deleted = true;
      }
      else {
        castedItem.is_deleted = undefined;
      }
      fullItems.push(castedItem);
    });

    newItems = [];
    fullItems.forEach(newItem => {
      const castedItem = newItem as any;
      let hideItem: boolean;
      hideItem = false;
      let parentId = castedItem.parent_node_id;
      while (parentId) {
        hideItem = this.iterateAndDetermineHide(parentId, this.state.fullList);
        if (hideItem) {
          break;
        }
      }
      if (!hideItem && !castedItem.is_deleted) {
        newItems.push(castedItem);
      }
    });
    return newItems;
  };

  private _onCutActivityorFolder(activity: any) {
    if (activity.id !== undefined) {
      let activitiesSearchList: any[] = this.state.activitiesSearchList;
      let fullList: any[] = this.state.fullList;
      let items: any[] = this.state.items;
      const cutActivities: any[] = [];

      if (!activity.is_folder || !activity.haschildnodes) {
        const searchList = this._removeItemFromArray(activitiesSearchList, activity.id);
        activitiesSearchList = searchList.fullList;
        items = this._removeItemFromArray(items, activity.id).fullList;
        fullList = this._removeItemFromArray(fullList, activity.id).fullList;
        cutActivities.push({ index: searchList.index, activity: activity });
        this.setState({ cutActivities });
      }
      else {
        items = this._removeFolderFromList(items, activity.id);
        fullList = this._removeFolderFromList(fullList, activity.id);
        activitiesSearchList = this._cutFolderFromList(activitiesSearchList, activity.id, cutActivities);
      }
      this.setState({ fullList }, () => {
        this._forceGridUpdate(items);
      });
      this.setState({ activitiesSearchList }, () => {
        this.props.ActivitySearchList(activitiesSearchList);
      });
      this.setState({ isLoading: false });
      const selecteditem = this._selection.getSelectedIndices()[0];
      if (selecteditem >= 0 && this._detailsList.current) {
        this._detailsList.current.focusIndex(selecteditem, true);
        this._handleActivitySelection(items[selecteditem]);
      }
    }
  }

  private _onPasteActivityorFolder(activitiesList: any[], activity: any,isMovedActivity:boolean) {
    if (this.state.cutActivities.length > 0) {
      this._cutPasteActivityOrCategory(activitiesList, activity,isMovedActivity);
    }
    if (this.state.copiedActivities.length > 0) {
      this._onCopyPasteActivityorFolder(activitiesList, activity);
    }
    this.setState({ isLoading: false, cutActivities: [] });
  }
  private _onCustomColumnChange(nextProps) {
    if (nextProps.ActivityDef && nextProps.SelectedActivityColumns && nextProps.SelectedActivityColumns.length > 0) {
      this.UpdateGrid(nextProps.ActivityDef, nextProps.SelectedHierarchyNodeId, nextProps.roles, nextProps.SelectedActivityColumns, nextProps.UserPrefColumn, nextProps.ActivitySearch);
    }
  }
  private _reAddCutActivityOrFolder(cutactivities: any[], nextProps: any) {
    let newItems: any[];
    newItems = [];
    let fullItems: any[];
    fullItems = [];
    let activitySearchListItems: any[];
    activitySearchListItems = this.state.activitiesSearchList;

    // Expand the parent node if collapsed
    this.state.fullList.forEach(newItem => {
      const castedItem = newItem as any;
      if (cutactivities[0].activity.parent_node_id !== '' && cutactivities[0].activity.parent_node_id === castedItem.id && castedItem.iscollapsed) {
        castedItem.iscollapsed = !castedItem.iscollapsed;
      }
      fullItems.push(castedItem);

    });

    if (fullItems.length === activitySearchListItems.length) {
      cutactivities.forEach(item => {

        fullItems.splice(item.index, 0, item.activity);
        activitySearchListItems.splice(item.index, 0, item.activity);
      });
    }

    fullItems.forEach(newItem => {
      const castedItem = newItem as any;
      let hideItem: boolean;
      hideItem = false;
      let parentId = castedItem.parent_node_id;
      while (parentId) {
        hideItem = this.iterateAndDetermineHide(parentId, this.state.fullList);
        if (hideItem) {
          break;
        }
      }
      if (!hideItem) {
        if (castedItem.is_edit) {
          castedItem.is_edit = false;
        }
        newItems.push(castedItem);
      }
    });
    this.setState({
      fullList: fullItems,
      items: newItems,
      cutActivities: [],
      activitiesSearchList: activitySearchListItems
    }, () => {
      this._handleActivityActionSwitch(nextProps);
    });
  }

  private _cutFolderFromList(items: any[], folderId: string, cutActivities: any[]): any[] {
    cutActivities = [];
    let fullItems: Array<{}> = [];
    let newItems: Array<{}>;
    items.forEach(newItem => {
      const castedItem = newItem as any;
      if (folderId === castedItem.id) {
        castedItem.is_deleted = true;
      }
      else {
        castedItem.is_deleted = undefined;
      }
      fullItems.push(castedItem);
    });

    newItems = [];
    let i: number = 0;
    fullItems.forEach(newItem => {
      const castedItem = newItem as any;
      let hideItem: boolean;
      hideItem = false;
      let parentId = castedItem.parent_node_id;
      while (parentId) {
        hideItem = this.iterateAndDetermineHide(parentId, this.state.fullList);
        if (hideItem) {
          break;
        }
      }
      if (!hideItem && !castedItem.is_deleted) {
        newItems.push(castedItem);
      }
      else {
        cutActivities.push({ index: i, activity: castedItem });
      }
      i++;

    });
    this.setState({ cutActivities });
    return newItems;
  }

  private _cutPasteActivityOrCategory(activities: any[], activity: any,isMovedActivity:boolean) {
    const activityListService = new ActivityListService();
    if(!isMovedActivity){   
      const _activitiesSummary = activityListService.MapActivityList(activities, this.props.ActivityDef);
      activity = _activitiesSummary.filter(a => a.id === activity.id)[0];
      this.setState({
        items: _activitiesSummary ? _activitiesSummary : [],
        fullList: _activitiesSummary ? _activitiesSummary : [],
        activitiesSearchList: _activitiesSummary ? _activitiesSummary : [],
        isLoading: false,
        cutActivities: []
      }, () => {
        this._setSelection(_activitiesSummary, activity);
        this.props.ActivitySearchList(this.state.activitiesSearchList);
      });
    }
    else{
      this.setState({isActivityMoved:true},()=>{
        this._insertActivityOrCategory(activity);
      })
      
    }
  }
  private _onCopyActivityorFolder(activity: any) {
    if (activity.id !== undefined) {
      const copyActivities: any[] = [];
      copyActivities.push(activity);
      this.setState({ copiedActivities: copyActivities });
    }
  }
  private _onCopyPasteActivityorFolder(activities: any[], activity: any) {
    const activityListService = new ActivityListService();
    const _activitiesSummary = activityListService.MapActivityList(activities, this.props.ActivityDef);
    const activitiesLst: any[] = _activitiesSummary.filter(a => a.name === activity.name);
    const activitiesSearchList: any[] = this.state.activitiesSearchList;
    activitiesLst.forEach(newItem => {
      activities = activitiesSearchList.filter(a => a.id === newItem.id);
      if (activities.length < 1) {
        activity = newItem;
      }
    });
    this.setState({
      items: _activitiesSummary ? _activitiesSummary : [],
      fullList: _activitiesSummary ? _activitiesSummary : [],
      activitiesSearchList: _activitiesSummary ? _activitiesSummary : [],
      isLoading: false,
      copiedActivities: []
    }, () => {
      this._setSelection(_activitiesSummary, activity);
      this.props.ActivitySearchList(this.state.activitiesSearchList);
    });
  }

}