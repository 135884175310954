import * as React from 'react';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import FileSaver from 'file-saver';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Config, AppSettings } from '../../../Configuration';
import { adalApiFetch } from '../../../adalConfig';

export class SearchExportButton extends React.Component<{
  SearchFilters: any
},
  {
    hideSpinner: boolean
  }>{
  public constructor(props: {
    SearchFilters: any
  }) {
    super(props);
    this._onExportClicked = this._onExportClicked.bind(this);
    this.state = {
      hideSpinner: true
    }
  }

  public render(): JSX.Element {
    return (
      <div className="activityActionButtonContainer">
        <div style={{ display: 'flex' }}>
          <ActionButton
            data-automation-id="test"
            ariaLabel="Export Searched Activities"
            text="Export"
            iconProps={{ iconName: 'Download' }}
            disabled={!this.state.hideSpinner}
            allowDisabledFocus={true}
            className="advancedSearchPanelButtons"
            menuProps={{
              shouldFocusOnMount: true,
              items: [
                {
                  key: 'exportForImport',
                  text: 'Export for Import',
                  ariaLabel: 'Export Activities For Import',
                  onClick: this._onExportClicked.bind(this, false),
                  style: {
                    fontSize: "12px",
                    fontWeight: 500
                  },
                },
                {
                  key: 'exportDetails',
                  text: 'Export Details',
                  ariaLabel: 'Export Activities With Details',
                  onClick: this._onExportClicked.bind(this, true),
                  style: {
                    fontSize: "12px",
                    fontWeight: 500
                  },
                }
              ]
            }}
          />
          {
            !this.state.hideSpinner ?
              <div>
                <Spinner className="activityLoader" size={SpinnerSize.xSmall} hidden={this.state.hideSpinner} />
              </div> : ''
          }
        </div>
      </div>
    );
  }

  private _onExportClicked(showFlattenedHierarchy: boolean) {
    this.setState({ hideSpinner: false });
    const locale = Config.locale;
    const dtNow = this.replaceAll((new Date()).toISOString(), ":", "");
    const fileName = 'ActivitySearchResults' + dtNow.substring(0, dtNow.length - 5) + '.xlsx';
    const opts = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.props.SearchFilters)
    };
    return adalApiFetch(fetch, `${AppSettings.ActivityBaseUrl()}/${Config.Actvity.ExportSearch}/${locale}/${showFlattenedHierarchy}`, opts)
      .then(response => {
        return Promise.all([response.blob(), response.headers]);
      }).then(([blob, headers]) => {
        FileSaver.saveAs(blob, fileName);
        this.setState({ hideSpinner: true });
      })
      .catch((error) => {
        this.setState({
          hideSpinner: true
        });
      });

  }

  private replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }
}