import { IActivityDetails } from '../contracts/IActivityDetails';

export class ActivityDetails{

    public  Get(internalActivityId:string):IActivityDetails{
        return(
            {
                id:'',
                title:' ',
                activityId:'',
                parent_org_id:'',
                parent_node_id:'',
                properties:[                  
                    ]
            }
        );
    }
}