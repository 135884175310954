import * as React from 'react';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { ActivityAction } from '../../../../contracts/ActivityAction';
import { AuthorizationService } from'@commercialsalesandmarketing/pet-common';
import { Permissions } from '../../../../contracts/Permissions';
import { AppSettings } from '../../../../Configuration';

export class DeleteButton extends React.Component<{
  OnActivityActionButtonClick: (action: ActivityAction) => void,
  roles: any[],
  disabled: boolean
},
  {
    checked: boolean;
    isRoleAvailable: boolean;
  }> {
  public constructor(props: {
    OnActivityActionButtonClick: (action: ActivityAction) => void,
    roles: any[],
    disabled: false
  }) {
    super(props);
    this._onDeleteClicked = this._onDeleteClicked.bind(this);
    const authorizationService = new AuthorizationService(AppSettings);
    const isRoleAvailable = authorizationService.IsRolePresent(this.props.roles, Permissions.DeleteActivity);
    this.state = {
      checked: false,
      isRoleAvailable
    }
  }

  public render(): JSX.Element {
    const { isRoleAvailable, checked } = this.state;
    return (
      <div className="activityActionButtonContainer">
        <TooltipHost
          className="actionButtontoolTip"
          calloutProps={{ gapSpace: -10 }}
          closeDelay={100}
          hostClassName="selectedTaxonomyValueTooltip"
          tooltipProps={{
            onRenderContent: () => {
              return (
                <div>
                  <ul style={{ margin: 0, padding: 0 }}>
                    <li><span>Delete</span></li>
                  </ul>
                </div>
              );
            }
          }}
          delay={TooltipDelay.zero}
          directionalHint={DirectionalHint.topCenter}
        >
          <div style={{ display: 'flex' }}>
            <ActionButton
              data-automation-id="test"
              iconProps={{ iconName: 'Delete' }}
              allowDisabledFocus={true}
              disabled={!isRoleAvailable || this.props.disabled}
              checked={checked}
              onClick={this._onDeleteClicked}
              className="activityActionButton" >Delete</ActionButton>
          </div>
        </TooltipHost>
      </div>
    );
  }

  public componentWillReceiveProps(nextProp: any) {
    const authorizationService = new AuthorizationService(AppSettings);
    const isRoleAvailable = authorizationService.IsRolePresent(nextProp.roles, Permissions.DeleteActivity);
    this.setState({
      checked: false,
      isRoleAvailable
    })
  }

  private _onDeleteClicked(event): void {
    this.props.OnActivityActionButtonClick(ActivityAction.Delete);
  }
}