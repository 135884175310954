import 'office-ui-fabric-core/dist/css/fabric.min.css';
import * as React from 'react';
import { Hierarchy } from '@commercialsalesandmarketing/pet-org-hierarchy';
import { ActivitiesDetails } from './DetailControls/ActivitiesDetails';
import { Search } from './DetailControls/Search';
import { AdvancedSearch } from './DetailControls/AdvancedSearch';
import { IHierarchyDetails } from '../contracts/IHierarchyNode';
import { IUserDetails } from '../contracts/IUserDetails';
import { POUnMappingDetails } from './DetailControls/POUnMappingDetails';
import { IActivitySearch, SearchType } from '../contracts/IActivitySearch';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Config, AppSettings } from '../Configuration';
import { isNullOrUndefined } from 'util';
import {IActivityNotification} from '../contracts/IActivityNotification';
import {RequestTypeEnum} from '../contracts/RequestTypeEnum';

export class Details extends React.Component<{
        user: IUserDetails,
        notification:(ActivityNotification:IActivityNotification) => void,
        sethelppageid:(HelpPageId:string)=>void
    },
    {
        SelectedHieraryNodeId: string,
        SelectedHieraryNodeName: string,
        OnActivityDetails: boolean,
        OnUnMappedPO: boolean,
        OnUnMappedPODetails: boolean,
        OnMappedPODetails: boolean,
        UserHierarchyDetails: IHierarchyDetails,
        SelectedNode: any,
        roles: any[],
        leaflevelworkspaces: any[],
        workSpacesFullList: any[],
        childNodeMap: Map<string, string[]>,
        parentNodeMap: Map<string, string[]>,
        selectedActivityNode: any,
        unMappedPOs: any[],
        MappingUpdated: boolean,
        ActivitiesListForSelectedWorkSpace: any[],
        ActivitySearch: IActivitySearch,
        SameCountryWorkSpaces: any[],
        mapBHide: boolean,
        AdvancedSearchEnabled: boolean,
        IsSearchInProgress: boolean,
        SearchPageView: SearchType,
        unMappedPOsFullLst: any[],
        ShowUserManagement: boolean,
        ShowAnalytics: boolean,
        HelpPageId: string,
        ClearSearch:boolean,
        SelectedNodeActivities:any[],
        SearchedMappedPO:any[]
    }>
{

    constructor(props) {
        super(props);
        this.HandleHieraryNodeSelection = this.HandleHieraryNodeSelection.bind(this);
        this.HandlePOMappingSelection = this.HandlePOMappingSelection.bind(this);
        this.HandleUNPOMappingSelection = this.HandleUNPOMappingSelection.bind(this);
        this.UpdateWorkspacesList = this.UpdateWorkspacesList.bind(this);
        this.HandleUnPOMappingSave = this.HandleUnPOMappingSave.bind(this);
        this.HandleGetActivitySearchList = this.HandleGetActivitySearchList.bind(this);
        this.HandleOnActivitySearchClick = this.HandleOnActivitySearchClick.bind(this);
        this.HandleOnActivitySearchCompleted = this.HandleOnActivitySearchCompleted.bind(this);
        this._handleUnMapPOSaveComplete = this._handleUnMapPOSaveComplete.bind(this);
        this._setHelpPageId = this._setHelpPageId.bind(this);
        this._handleClearSearch=this._handleClearSearch.bind(this);
        this.HandleAdvancedSearchClose = this.HandleAdvancedSearchClose.bind(this);
        this.HandleAdvancedSearch = this.HandleAdvancedSearch.bind(this);
        this.state = {
            SelectedHieraryNodeId: '',
            SelectedHieraryNodeName: '',
            roles: [],
            OnUnMappedPO: true,
            OnActivityDetails: true,
            OnUnMappedPODetails: true,
            OnMappedPODetails:false,
            UserHierarchyDetails: {
                SelectedHieraryNodeId: '',
                UserHierarchyNodes: this.props.user.user_paths,
                ParentNodesMap:new Map()
            },
            leaflevelworkspaces: [],
            SameCountryWorkSpaces: [],
            unMappedPOs: [],
            selectedActivityNode: {
                Title: '',
                activitiesNamedList: []
            },
            SelectedNode: {},
            MappingUpdated: false,
            ActivitiesListForSelectedWorkSpace: [],
            ActivitySearch: { ClearSearch: true, Activities: [], ShowHierarchy: true, SelectedWorkspaces: [], ActivityDefinitionsForSearchedWorkspaces: [] },
            mapBHide: false,
            AdvancedSearchEnabled: false,
            IsSearchInProgress: false,
            SearchPageView: SearchType.None,
            unMappedPOsFullLst: [],
            ShowUserManagement: false,
            workSpacesFullList: [],
            childNodeMap: new Map(),
            parentNodeMap: new Map(),
            ShowAnalytics: false,
            HelpPageId: Config.Help.HomePage,
            ClearSearch:false,
            SelectedNodeActivities:[],
            SearchedMappedPO:[]
        };
    }

    public componentDidMount() {
        if(this.state.HelpPageId===Config.Help.HomePage)
        {
        this.props.sethelppageid(this.state.HelpPageId);
        }
    }

    public componentWillReceiveProps(nextProps: any) {
    }

    public HandleHieraryNodeSelection(selectedNode: any, roles: any[]) {
        if (selectedNode !== undefined && selectedNode !== null) {
            if (this.state.SelectedHieraryNodeId !== selectedNode.key) {
                let searchActivity = this.state.ActivitySearch;
                searchActivity.ClearSearch = true;
                searchActivity.Activities = [];
                this.setState({
                    ActivitySearch: searchActivity
                });
            }
            this.setState({
                SelectedHieraryNodeId: selectedNode.key,
                SelectedHieraryNodeName: selectedNode.text,
                SelectedNode: selectedNode,
                UserHierarchyDetails: {
                    SelectedHieraryNodeId: selectedNode.key,
                    UserHierarchyNodes: this.props.user.user_paths,
                    ParentNodesMap:this.state.parentNodeMap
                },
                roles
            });

        }
    }

    public HandlePOMappingSelection(OnActivityDetails: boolean, SelectionDetails: any) {
        this.setState({
            OnActivityDetails,
            OnMappedPODetails:true,
            selectedActivityNode: SelectionDetails,
            mapBHide: true,
            AdvancedSearchEnabled:false
        });
        const activityDetails=   {
            RequestType : RequestTypeEnum.MappedPO,
            SelectedActivityNode : SelectionDetails
        }
        this.props.notification(activityDetails);
    }

    public HandleUNPOMappingSelection(OnUnMappedPODetails: boolean, unMappedPOs: any[], mapBHide: boolean) {
        this.setState({
            mapBHide,
            OnActivityDetails: false,
        });
        if (!isNullOrUndefined(this.state.ActivitySearch.Activities) && this.state.ActivitySearch.Activities.length > 0) {
            this._handleClearSearch();
        }
        const activityDetails = {
            RequestType: RequestTypeEnum.UnMappedPO,
            SelectedActivityNode: {
                Title: '',
                ActivitiesList: []
            }
        }
        this.props.notification(activityDetails);
    }


    public HandleUnPOMappingSave(): void {
        this.setState({
            MappingUpdated: true
        });
    }

    public UpdateWorkspacesList(leaflevelworkspaces: any[], workSpacesFullList: any[], childNodeMap: Map<string, string[]>, parentNodeMap: Map<string, string[]>,countryOptions: any) {
        this.setState({ 
             workSpacesFullList :Array.from(new Set(workSpacesFullList)) , childNodeMap,parentNodeMap});
    }

    public HandleGetActivitySearchList(activitiesList: any[]): void {
        this.setState({
            ActivitiesListForSelectedWorkSpace: activitiesList
        });
    }

    public HandleOnActivitySearchClick(): void {
        this.setState({
            IsSearchInProgress: true
        });
    }

    public HandleOnActivitySearchCompleted(activitySearch: any): void {
        this.setState({
            ActivitySearch: activitySearch, IsSearchInProgress: false,ClearSearch:false
        });
    }

    public render(): JSX.Element {

        return (
                 <Stack grow verticalFill style={{overflow:'auto'}}>
                    <Stack.Item>
                        <div className="hierarcy-panel">
                            <Stack>
                                <Stack.Item>
                                    <Stack>
                                        <Stack.Item className='ms-Grid'>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md6">
                                                    <div data-att="xyz"></div>
                                                    <Hierarchy HandleHieraryNodeSelection={this.HandleHieraryNodeSelection}
                                                        UserHierarchyDetails={this.state.UserHierarchyDetails}
                                                        UpdateWorkspacesList={this.UpdateWorkspacesList}
                                                        Enabled={!this.state.AdvancedSearchEnabled && this.state.OnActivityDetails} 
                                                        AppSettings={AppSettings} /> 

                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md6">
                                                    <Search SelectedNode={this.state.SelectedNode}
                                                        ActivitiesList={this.state.ActivitiesListForSelectedWorkSpace}
                                                        EnableAdvancedSearch={true}
                                                        OnActivitySearch={this.HandleOnActivitySearchCompleted}
                                                        OnAdvancedSearchClicked={this.HandleAdvancedSearch}
                                                        ClearSearch={this.state.ClearSearch}
                                                        />
                                                </div>
                                            </div>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    {
                                        <POUnMappingDetails SelectedHieraryNode={this.state.SelectedNode}
                                            OnPOClick={this.HandleUNPOMappingSelection}
                                            roles={this.state.roles}
                                            forceUpdate={this.state.MappingUpdated}
                                            mapBHide={this.state.mapBHide}
                                            OnSaveComplete={this._handleUnMapPOSaveComplete}
                                            AdvancedSearchEnabled={this.state.AdvancedSearchEnabled} />
                                    }
                                </Stack.Item>
                                
                            </Stack>
                        </div>
                    </Stack.Item>
                    <Stack.Item>
                        {
                            this.state.AdvancedSearchEnabled ?
                                <AdvancedSearch
                                    OnSearchStarted={this.HandleOnActivitySearchClick}
                                    OnSearchCompleted={this.HandleOnActivitySearchCompleted}
                                    OnBackToHome={this.HandleAdvancedSearchClose}
                                    WorkSpacesFullList={this.state.workSpacesFullList}
                                    ChildNodeMap={this.state.childNodeMap}
                                    SelectedNodeId={this.state.SelectedHieraryNodeId}
                                    SearchPageView={this.state.SearchPageView}
                                    SelectedNode={this.state.SelectedNode} 
                                    />
                                : <div />
                        }
                    </Stack.Item>
                    <Stack.Item grow >
                        {
                                <ActivitiesDetails
                                    Workspaces={this.state.workSpacesFullList}
                                    selectedNode={this.state.SelectedNode}
                                    OnPOClick={this.HandlePOMappingSelection}
                                    ActivitySearchList={this.HandleGetActivitySearchList}
                                    roles={this.state.roles}
                                    ActivitySearch={this.state.ActivitySearch}
                                    UserHierarchyDetails={this.state.UserHierarchyDetails}
                                    IsInAdvancedSearchMode={this.state.AdvancedSearchEnabled}
                                    IsSearchInProgress={this.state.IsSearchInProgress}
                                    SetHelpPageId={this._setHelpPageId}
                                    HandleClearSearch={this._handleClearSearch}
                                />
                        }
                    </Stack.Item>
                </Stack>
        );
    }
 
    private HandleAdvancedSearch = () => {
        const advSearch = this.state.AdvancedSearchEnabled;
        if (advSearch) this.HandleAdvancedSearchClose();
        else this.setState({
            AdvancedSearchEnabled: true,
            ActivitySearch: { Activities: [], ClearSearch: false, ShowHierarchy: false, SelectedWorkspaces: [], ActivityDefinitionsForSearchedWorkspaces: [] },
            SearchPageView:  SearchType.Activity 
        });
    }

    private HandleAdvancedSearchClose = () => {
            this.setState({
                AdvancedSearchEnabled: false,
                SearchPageView: SearchType.None,
                OnMappedPODetails:false,
                ActivitySearch: { Activities: [], ClearSearch: true, ShowHierarchy: true, SelectedWorkspaces: [], ActivityDefinitionsForSearchedWorkspaces: [] },
            });
    }

    private _handleUnMapPOSaveComplete(unMappedPOs: any[]) {
        this.setState({
            MappingUpdated: false
        });
    }
    private _setHelpPageId(helpPageId: string) {
        if (this.state.HelpPageId !== helpPageId) {
            this.setState({
                HelpPageId: helpPageId

            });
            this.props.sethelppageid(helpPageId);

        }
    }

    private _handleClearSearch()
    {
        this.setState({
            ClearSearch:true,
            AdvancedSearchEnabled: false,
            SearchPageView: SearchType.None,
            ActivitySearch: { Activities: [], ClearSearch: true, ShowHierarchy: true, SelectedWorkspaces: [], ActivityDefinitionsForSearchedWorkspaces: [] },
        });
    }
} 