import * as React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { DatePicker } from '@uifabric/date-time';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { TooltipHost, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { ActionButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { isNullOrUndefined } from 'util';
import { SearchType } from '../../contracts/IActivitySearch';
import {WorkSpaceDropDownWithSelectAll} from '@commercialsalesandmarketing/pet-org-hierarchy';
import { AppSettings } from '../../Configuration';

export class AdvancedSearchFilterItem extends React.Component<{
    FilterId: number,
    AttributeType: string,
    ActivityDefMembers: Array<{ key: string, text: any }>,
    SelectedActivityDefMember: { key: string, text: any },
    AvailableFilterValues: Array<{ key: string, text: any }>,
    SelectedFilterValues: string[],
    IsRequired: boolean,
    selectedOperator:string,
    searchType:SearchType,
    additionalOptions:any,
    OnActivityDefSelectionChanged: (selectedKey: string, filterId: number) => void,
    OnFilterValueSelectionChanged: (selectedKeys: string[], filterId: number,selectedOperator:string) => void,
    OnDeleteFilter: (filterId: number) => void
}, {
    activityDefOptions: Array<{ key: string, text: any }>,
    availableFilterValues: Array<{ key: string, text: any }>,
    selectedActivityDef: { key: string, text: any },
    selectedFilterValues: string[],
    isAlertHidden: boolean,
    multiline: boolean,
    selectedOperator:string
}
    > {
    private _alertMessage: string;

    constructor(props: {
        FilterId: number,
        AttributeType: string,
        ActivityDefMembers: Array<{ key: string, text: any }>,
        SelectedActivityDefMember: { key: string, text: any },
        AvailableFilterValues: Array<{ key: string, text: any }>,
        SelectedFilterValues: string[],
        IsRequired: boolean,
        selectedOperator:string,
        searchType:SearchType,
        additionalOptions:any,
        OnActivityDefSelectionChanged: (selectedKey: string) => void,
        OnFilterValueSelectionChanged: (selectedKeys: string[]) => void,
        OnDeleteFilter: (filterId: number) => void
    }) {
        super(props);

        this.state = {
            activityDefOptions: [],
            availableFilterValues: [],
            selectedActivityDef: null,
            selectedFilterValues: [],
            isAlertHidden: true,
            multiline: false,
            selectedOperator:'IN'
        };

        this._enrichActivityDefinition = this._enrichActivityDefinition.bind(this);
        this._enrichFilterValues = this._enrichFilterValues.bind(this);
        this._onActivityDefSelectionChanged = this._onActivityDefSelectionChanged.bind(this);
        this._onFilterValueSelectionChanged = this._onFilterValueSelectionChanged.bind(this);
        this._closeAlertDialog = this._closeAlertDialog.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onTextFieldValueChange = this._onTextFieldValueChange.bind(this);
        this._onTextFieldValueSave = this._onTextFieldValueSave.bind(this);
        this._onOperatorSelectionChanged=this._onOperatorSelectionChanged.bind(this);
        this._onWorkSpaceChanged=this._onWorkSpaceChanged.bind(this);
    }

    public componentWillReceiveProps(nextProps: any) {
        this._enrichActivityDefinition(nextProps.ActivityDefMembers, nextProps.SelectedActivityDefMember);
        this._enrichFilterValues(nextProps.AvailableFilterValues, nextProps.SelectedFilterValues,nextProps.selectedOperator);
    }

    public componentDidMount() {
        this._enrichActivityDefinition(this.props.ActivityDefMembers, this.props.SelectedActivityDefMember);
        this._enrichFilterValues(this.props.AvailableFilterValues, this.props.SelectedFilterValues,this.props.selectedOperator);
    }

    public render() {
        const _this = this;
        const alertMessage = this._alertMessage;

        const _selectedActivityDefKey = _this.state.selectedActivityDef !== undefined && _this.state.selectedActivityDef !== null ? _this.state.selectedActivityDef.key : '';
        const _activityDefSelected = _selectedActivityDefKey !== '' ? true : false;

        let _selectedTaxonomyValuesText: string = '';
        if (_this.state.availableFilterValues !== undefined && _this.state.availableFilterValues !== null) {
            _this.state.availableFilterValues.forEach(x => {
                if (_this.state.selectedFilterValues !== undefined && _this.state.selectedFilterValues !== null && _this.state.selectedFilterValues.some(t => t === x.key)) {
                    _selectedTaxonomyValuesText = _selectedTaxonomyValuesText + x.text + ', ';
                }
            });
            _selectedTaxonomyValuesText = _selectedTaxonomyValuesText.substring(0, _selectedTaxonomyValuesText.length - 2);
        }
        let dateTimeSelectedValue: Date = null;
        if (this.isDateTimeField(this.props.AttributeType) && _this.state.selectedFilterValues !== undefined && _this.state.selectedFilterValues !== null && _this.state.selectedFilterValues.length > 0) {
            dateTimeSelectedValue = new Date(_this.state.selectedFilterValues[0]);
        }

        let textSelectedValue: string = "";
        if (this.isTextField(this.props.AttributeType) && _this.state.selectedFilterValues !== undefined && _this.state.selectedFilterValues !== null && _this.state.selectedFilterValues.length > 0) {
            _this.state.selectedFilterValues.forEach(x => {
                textSelectedValue = textSelectedValue + x + ",";
            });
            if (textSelectedValue.length > 0) { textSelectedValue = textSelectedValue.substring(0, textSelectedValue.length - 1) }
        }

        let numSelectedValue: string = "";
        if (this.isNumberField(this.props.AttributeType) && _this.state.selectedFilterValues !== undefined && _this.state.selectedFilterValues !== null && _this.state.selectedFilterValues.length > 0) {
            numSelectedValue =_this.state.selectedFilterValues[0];
        }

        return (
            <div>
                <Dialog
                    hidden={this.state.isAlertHidden}
                    onDismiss={this._closeAlertDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Validation Error',
                        subText: alertMessage
                    }}
                    modalProps={{
                        isBlocking: true,
                        containerClassName: 'ms-dialogMainOverride'
                    }}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={this._closeAlertDialog} text="OK" />
                    </DialogFooter>
                </Dialog>

                <Stack horizontal gap={5}>
                    <Stack.Item align="start">
                        <Dropdown style={{ fontSize: 12, width: "30vh" }}
                            styles={{ root: { fontSize: 12 }, dropdownOptionText: { fontSize: "12px" } }}
                            label=" "
                            placeholder="Select"
                            options={_this.state.activityDefOptions}
                            disabled={_selectedActivityDefKey === "FiscalYear" ? true : false}
                            selectedKey={_selectedActivityDefKey}
                            onChange={_this._onActivityDefSelectionChanged}
                            ariaLabel={!isNullOrUndefined(_selectedActivityDefKey)&&_selectedActivityDefKey!==''?_selectedActivityDefKey:'Select filter' }
                        />
                    </Stack.Item>

                    <Stack.Item align="center"  styles={{ root: { paddingTop: "16px" } }}>
                        {
                            this.isTextField(this.props.AttributeType) ?
                                <TooltipHost
                                    calloutProps={{ gapSpace: 10 }}
                                    tooltipProps={{
                                        onRenderContent: () => {
                                            return (
                                                <div>
                                                    <ul style={{ margin: 0, padding: 0 }}>
                                                        <li>Enter comma seperated text values.</li>
                                                        <li>Accepts full text value or part of the text.</li>
                                                    </ul>
                                                </div>
                                            );
                                        }
                                    }}
                                    delay={TooltipDelay.zero}
                                    id={_selectedActivityDefKey}
                                    directionalHint={DirectionalHint.rightCenter}
                                >
                                    <ActionButton
                                        aria-label='info'
                                        style={{ zoom: "70%" }}
                                        data-automation-id="test"
                                        iconProps={{ iconName: 'Info' }}
                                        allowDisabledFocus={true}
                                        className="activityActionButton" /> </TooltipHost>
                                : 
                                (this.isNumberField(this.props.AttributeType) || this.isDateTimeField(this.props.AttributeType)) && this.props.searchType !== SearchType.Activity ?
                                <Dropdown
                                selectedKey={this.state.selectedOperator}
                                onChange={this._onOperatorSelectionChanged}
                                ariaLabel="Select Operator"
                                options={[
                                    { key: 'Equals', text: '==',title: 'equals',ariaLabel: 'Equals'},
                                    { key: 'GreaterThan', text: '>',title: 'greater than',ariaLabel: 'GreaterThan' },
                                    { key: 'GreaterThanOrEqualto', text: '>=',title: 'greater than or equal to',ariaLabel: 'GreaterThanOrEqualto' },
                                    { key: 'LessThan', text: '<',title: 'less than',ariaLabel: 'LessThan' },
                                    { key: 'LessThanOrEqualto', text: '<=',title: 'less than or equal to',ariaLabel: 'LessThanOrEqualto' }
                                ]}
                                styles={{ dropdown: { fontSize: 12, width: "13vh" }, dropdownOptionText: { fontSize: "12px" } }}
                            />
                                :
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        }
                    </Stack.Item>

                    <Stack.Item align="end">
                        {
                            this.isDateTimeField(this.props.AttributeType) ?
                                <DatePicker style={{ fontSize: 12 }}
                                    value={dateTimeSelectedValue}
                                    placeholder="Select a date..."
                                    ariaLabel="Select a date"
                                    onSelectDate={(date: Date) => {
                                        let values = [];
                                        values.push(this.convertToUTC(date).toISOString());
                                        this.props.OnFilterValueSelectionChanged(values, this.props.FilterId,this.state.selectedOperator)
                                    }}
                                    textField={{ style: { fontSize: "12px", width:"51vh" } }} />
                                : this.isTextField(this.props.AttributeType) ?
                                    <TextField
                                        multiline={this.state.multiline}
                                        onChange={this._onTextFieldValueChange}
                                        onBlur={this._onTextFieldValueSave}
                                        value={textSelectedValue}
                                        style={{ fontSize: 12, verticalAlign: "bottom", width: "60vh" }}
                                        ariaLabel="Enter Search Term" />
                                    :
                                    this.isNumberField(this.props.AttributeType) ?
                                    <TextField
                                        onChange={this._onTextFieldValueChange}
                                        onBlur={this._onTextFieldValueSave}
                                        value={numSelectedValue}
                                        style={{ fontSize: 12, verticalAlign: "bottom", width: "51vh" }}
                                        ariaLabel="Enter Search Term" />
                                    :
                                    // !isNullOrUndefined(this.props.SelectedActivityDefMember) && this.props.SelectedActivityDefMember.key==='IOLandingCountry'?
                                    // <TextField
                                    //     onChange={this._onTextFieldValueChange}
                                    //     onBlur={this._onTextFieldValueSave}
                                    //     value={this.props.SelectedFilterValues[0]}
                                    //     disabled={true}
                                    //     style={{ fontSize: 12, verticalAlign: "bottom", width: "51vh" }}
                                    //     ariaLabel="Enter Search Term" />
                                    // :      
                                    !isNullOrUndefined(this.props.SelectedActivityDefMember) && this.props.SelectedActivityDefMember.key==='FiscalYear'?
                                    <TextField
                                        onChange={this._onTextFieldValueChange}
                                        onBlur={this._onTextFieldValueSave}
                                        value={this.props.SelectedFilterValues[0]}
                                        disabled={true}
                                        style={{ fontSize: 12, verticalAlign: "bottom", width: "51vh" }}
                                        ariaLabel="Enter Search Term" />
                                    :                                    
                                    !isNullOrUndefined(this.props.SelectedActivityDefMember) && this.props.SelectedActivityDefMember.key==='Workspace'?
                                            <WorkSpaceDropDownWithSelectAll DisplayLabel={false}
                                            IsRequired={this.props.IsRequired}
                                            ControlId="searchWSDropdown" 
                                            WorkspaceOptions={this.props.additionalOptions.workspaces} 
                                            SelectedWSValues={this.state.selectedFilterValues}
                                            ChildNodeMap={this.props.additionalOptions.childNodeMap} 
                                            OnWorkSpaceChanged={this._onWorkSpaceChanged} 
                                            SelectAtleastOneOption={true} AppSettings={AppSettings} />
                                    :
                                    <Dropdown
                                        multiSelect
                                        required={this.props.IsRequired}
                                        disabled={!_activityDefSelected}
                                        placeholder="Select"
                                        selectedKeys={this.state.selectedFilterValues}
                                        onChange={this._onFilterValueSelectionChanged}
                                        options={_this.state.availableFilterValues}
                                        styles={{ dropdown: { fontSize: 12, width: "60vh" }, dropdownOptionText: { fontSize: "12px" } }}
                                        onBlur={_this._onBlur}
                                    />
                        }
                    </Stack.Item>

                    <Stack.Item align="end">
                        {
                            !this.props.IsRequired ?
                                <ActionButton
                                    aria-label='delete'
                                    style={{ zoom: "75%" }}
                                    data-automation-id="test"
                                    iconProps={{ iconName: 'Delete' }}
                                    allowDisabledFocus={true}
                                    disabled={false}
                                    onClick={_this._onDeleteFilter}
                                    className="activityActionButton" /> : <div />
                        }
                    </Stack.Item>
                </Stack >
            </div>
        );
    }

    private convertToUTC = (dtLocal: Date): Date => {
        if (dtLocal !== null && dtLocal !== undefined && dtLocal.toString() !== 'Invalid Date') {
            const dtUTC = new Date();
            dtUTC.setUTCFullYear(dtLocal.getFullYear(), dtLocal.getMonth(), dtLocal.getDate());
            dtUTC.setUTCHours(0, 0, 0, 0);
            return dtUTC;
        }
        return dtLocal;
    }

    private _onTextFieldValueChange = (ev: any, newText: string): void => {
        const newMultiline = newText.length > 50;
        if (newMultiline !== this.state.multiline) {
            this.setState({ multiline: newMultiline });
        }
    };

    private _onTextFieldValueSave = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        let textValues = [];
        if(!isNullOrUndefined(ev.currentTarget)) {
            ev.currentTarget.value.split(",").filter(x=>x).forEach(x => {
                if (/\S/.test(x)) textValues.push(x); // Only push the value if it has some non-whitespace character(s)
            })
        }
        if (this.state.selectedActivityDef !== undefined && this.state.selectedActivityDef !== null) {
            this.props.OnFilterValueSelectionChanged(textValues, this.props.FilterId,this.state.selectedOperator)
        }
    };

    private isDateTimeField(type: string): boolean {
        return type === 'datetime' || type === 'date';
    }

    private isTextField(type: string): boolean {
        return type === 'text' || type === 'string';
    }

    private isNumberField(type: string): boolean {
        return type === 'number';
    }

    private _onBlur = (ev: any) => {
        if (this.state.selectedActivityDef !== undefined && this.state.selectedActivityDef !== null) {
            this.props.OnFilterValueSelectionChanged(this.state.selectedFilterValues, this.props.FilterId,this.state.selectedOperator)
        }
    }

    private _onDeleteFilter = () => {
        this.props.OnDeleteFilter(this.props.FilterId);
    }

    private _enrichActivityDefinition = (activityDefMembers: Array<{ key: string, text: any }>, selectedActivityDef: { key: string, text: any }): void => {
        const _this = this;
        if (activityDefMembers !== undefined && activityDefMembers !== null) {
            if (selectedActivityDef !== undefined && selectedActivityDef !== null) {
                if (selectedActivityDef !== undefined && selectedActivityDef !== null && selectedActivityDef.key === 'Workspace') {
                    activityDefMembers = [{ key: 'Workspace', text: 'Workspace' }]
                }
                _this.setState({ activityDefOptions: activityDefMembers, selectedActivityDef: selectedActivityDef });
            }
            else {
                const _options = !isNullOrUndefined(activityDefMembers) ? activityDefMembers.filter(x => x.key !== 'Workspace') : [];
                _this.setState({ activityDefOptions: _options });
            }
        }
    }

    private _enrichFilterValues = (taxonomies: Array<{ key: string, text: any }>, selectedTaxonomies: string[],selectedOperator:string): void => {
        const _this = this;
        _this.setState({ availableFilterValues: taxonomies, 
            selectedFilterValues: selectedTaxonomies,
            selectedOperator: !isNullOrUndefined(selectedOperator) && selectedOperator!=='' ? selectedOperator:'IN' });
    }

    private _onActivityDefSelectionChanged = (ev: any, item: any) => {
        this.props.OnActivityDefSelectionChanged(item.key, this.props.FilterId);
    }

    private _onFilterValueSelectionChanged = (event: any, item: any): void => {
        const newSelectedItems = this.state.selectedFilterValues !== undefined && this.state.selectedFilterValues !== null ? [...this.state.selectedFilterValues] : [];
        if (item.selected) {
            newSelectedItems.push(item.key as string);
        } else {
            const currIndex = newSelectedItems.indexOf(item.key as string);
            if (currIndex > -1) {
                newSelectedItems.splice(currIndex, 1);
            }
        }
        if (!isNullOrUndefined(this.props.SelectedActivityDefMember) && (this.props.SelectedActivityDefMember.key==='IOLandingCountry')  && (newSelectedItems === undefined || newSelectedItems === null || newSelectedItems.length <= 0)) {
            this._alertMessage = 'Select atleast one Country';
            this.setState({ isAlertHidden: false });
        }
        else
         {
            this.setState({ selectedFilterValues: newSelectedItems },()=>{
                this._onBlur(event);
            });
        }
    };

    private _closeAlertDialog = () => {
        const _this = this;
        _this._alertMessage = '';
        _this.setState({ isAlertHidden: true });
    }
    
    private _onOperatorSelectionChanged = (event: any, item: any): void => {
            this.setState({ selectedOperator: item.key },()=>{
                if (this.state.selectedActivityDef !== undefined && this.state.selectedActivityDef !== null) {
                    this.props.OnFilterValueSelectionChanged(this.state.selectedFilterValues, this.props.FilterId,this.state.selectedOperator)
                }
            });
    };
    private _onWorkSpaceChanged(SelectedWSValues:string[])
    {
        if (SelectedWSValues === undefined || SelectedWSValues === null || SelectedWSValues.length <= 0) {
            this._alertMessage = 'Select atleast one workspace';
            this.setState({ isAlertHidden: false });
        }
        else
        {
            this.setState({
               selectedFilterValues: SelectedWSValues
              },()=>{
                  this._onBlur(SelectedWSValues);
              });
        }
    }
  
}