import { IActivityDefinition } from '../contracts/IActivityDefinition';
import { IGroup } from 'office-ui-fabric-react/lib/GroupedList';
import { ActivityType } from '../contracts/ActivityTypeEnum';
import { isNullOrUndefined } from 'util';
import { AppSettings, Config } from '../Configuration';
import { adalApiFetch } from '../adalConfig';

interface IActivityWithLevel {
    level: number,
    item: IActivity,
    haschildnodes: boolean
}

interface IActivity {
    id: string,
    properties: IProperty[],
    relations: IRelation[],
    parent_node_id: string,
    is_node_activity: boolean,
    parent_org_id: string,
    is_folder: boolean,
    ActivityDefinition: IActivityDefinition
}
interface IProperty {
    key: string,
    values: string
}
interface IRelation {
    key: string,
    values: string[]
}

export class ActivityListService {

    public MapActivityList(data: any, def: IActivityDefinition, searchedActivityDefinitions: any[] = []): any[] {
        const relation = 'relation';
        if (!data) {
            return data;
        }
        const activityListFromServer = data as IActivityWithLevel[];
        let activityList: Array<{}>;
        activityList = [];
        activityListFromServer.forEach(activity => {
            let activityDetail: any;
            activityDetail = {};
            const definitionToUse : IActivityDefinition = this.GetActivityDefinition(activity, def, searchedActivityDefinitions); 
            definitionToUse.members.forEach(member => {
                if (member.type === relation || member.type === 'io') {
                    if (activity.item.relations !== null) {
                        activityDetail[member.name] = activity.item.relations[member.name];
                    }
                }
                else {
                    activityDetail[member.name] = activity.item.properties[member.name];
                }
            })

            activityDetail.level = activity.level;
            activityDetail.is_folder = activity.item.is_folder;
            activityDetail.iscollapsed = false;
            activityDetail.id = activity.item.id;
            activityDetail.parent_node_id = activity.item.parent_node_id;
            activityDetail.is_edit = false;
            activityDetail.temp_name = activityDetail.name;
            activityDetail.parent_org_id = activity.item.parent_org_id;
            activityDetail.haschildnodes = activity.haschildnodes;
            activityDetail.type = activity.item.is_folder ?
                ((activity.item.parent_node_id === '' || activity.item.parent_node_id === null || activity.item.parent_node_id === undefined)
                    ? ActivityType.Category : ActivityType.SubCategory) : ActivityType.Activity;
            // Folder's "activityid" is picked up from "id"
            activityDetail.activityid = activity.item.id;
            activityList.push(activityDetail);
        });
        return activityList;
    }

    public MapSearchResults(activitySearchResults: any, def: IActivityDefinition, searchedActivityDefinitions: any[] = []): any[] {
        const relation = 'relation';
        if (!activitySearchResults) {
            return activitySearchResults;
        }

        let activityList: Array<{}>;
        activityList = [];
        activitySearchResults.forEach(activity => {
            let activityDetail: any;
            activityDetail = {};
            const definitionToUse : IActivityDefinition = this.GetActivityDefinition(activity, def, searchedActivityDefinitions); 
            definitionToUse.members.forEach(member => {
                if (member.type === relation || member.type === 'io') {
                    if (activity.relations !== null) {
                        activityDetail[member.name] = activity.relations[member.name];
                    }
                }
                else {
                    activityDetail[member.name] = activity.properties[member.name];
                }
            })

            activityDetail.temp_name = activityDetail.name;
            activityDetail.is_folder = activity.is_folder;
            activityDetail.id = activity.id;
            activityDetail.parent_node_id = activity.parent_node_id;
            activityDetail.is_edit = false;
            activityDetail.parent_org_id = activity.parent_org_id;
            activityDetail.type = ActivityType.Activity;

            activityList.push(activityDetail);
        });

        return activityList;
    }

    public MapGroupList(data: any): IGroup[] {
        const groupsFromServer = data.groups as IGroup[];
        return groupsFromServer;
    }

    public GetActivityList(selectedItem: any, allItems: any[]): any {

        const result = {
            Title: selectedItem.name,
            activities: [{}]
        }
        if (!selectedItem.is_folder) {
            result.activities = [{
                id: selectedItem.id,
                name: selectedItem.name
            }];
        }
        else {
            let isStarted = false;

            for (const item of allItems) {
                if (item.id === selectedItem.id) {
                    isStarted = true;
                    continue;
                }
                if (isStarted) {
                    if (item.level <= selectedItem.level) {
                        break;
                    }
                    if (item.is_folder === false) {
                        result.activities.push({
                            id: item.id,
                            name: item.name
                        });
                    }
                }


            }
        }
        return result;
    }

    public fetchActivityDefinition(nodeTaxonomies: any) {
        const opts = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(nodeTaxonomies)
        }
        return adalApiFetch(fetch, `${AppSettings.ActivityDefinitionUrl()}/${Config.Actvity.DefinitionId}`, opts)
            .then(response => response.json())
    }   

    private GetActivityDefinition(activity: any, defaultDef: IActivityDefinition, searchedActivityDefinitions: any[]): IActivityDefinition {
        let resultDefinition: IActivityDefinition = defaultDef;
        if (!isNullOrUndefined(searchedActivityDefinitions) && searchedActivityDefinitions.length > 0
            && searchedActivityDefinitions.findIndex(d => d.workspaceID === activity.parent_org_id
                || (activity.item !== undefined && activity.item !== null && d.workspaceID === activity.item.parent_org_id)) >= 0) {
            const def = searchedActivityDefinitions.find(d => d.workspaceID === activity.parent_org_id
                || (activity.item !== undefined && activity.item !== null && d.workspaceID === activity.item.parent_org_id)).activityDef;
            if (!isNullOrUndefined(def)) resultDefinition = def;
        }
        return resultDefinition;
    }
}