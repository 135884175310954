import * as React from 'react';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { ActivityAction } from '../../../../contracts/ActivityAction';
import { AuthorizationService } from'@commercialsalesandmarketing/pet-common';
import { Permissions } from '../../../../contracts/Permissions';
import { AppSettings } from '../../../../Configuration';

export class CopyButton extends React.Component<{
  OnActivityActionButtonClick: (action: ActivityAction) => void,
  roles: any[],
  SelectedActivity: any,
  disabled: boolean
},
  {
    checked: boolean;
    isCreateEditActivityRoleAvailable: boolean;
    isCreateEditFolderRoleAvailable: boolean;
    isRoleAvailable: boolean
  }> {
  public constructor(props: {
    OnActivityActionButtonClick: (action: ActivityAction) => void,
    roles: any[],
    SelectedActivity: any,
    disabled: false
  }) {
    super(props);
    this._onCopyClicked = this._onCopyClicked.bind(this);
    this.state = {
      checked: false,
      isCreateEditFolderRoleAvailable: false,
      isCreateEditActivityRoleAvailable: false,
      isRoleAvailable: false
    }
  }

  public render(): JSX.Element {
    const { isRoleAvailable, checked } = this.state;

    return (
      <div className="activityActionButtonContainer">
        <TooltipHost
          className="actionButtontoolTip"
          calloutProps={{ gapSpace: -10 }}
          hostClassName="selectedTaxonomyValueTooltip"
          tooltipProps={{
            onRenderContent: () => {
              return (
                <div>
                  <ul style={{ margin: 0, padding: 0 }}>
                    <li><span>Copy</span></li>
                  </ul>
                </div>
              );
            }
          }}
          delay={TooltipDelay.zero}
          directionalHint={DirectionalHint.topCenter}
        >
          <div style={{ display: 'flex' }}>
            <ActionButton
              data-automation-id="test"
              iconProps={{ iconName: 'Copy' }}
              allowDisabledFocus={true}
              onClick={this._onCopyClicked}
              disabled={!isRoleAvailable || this.props.disabled}
              checked={checked}
              className="activityActionButton" >Copy</ActionButton>
          </div>
        </TooltipHost>
      </div>
    );
  }

  public componentWillReceiveProps(nextProps: any) {
    const authorizationService = new AuthorizationService(AppSettings);
    const isCreateEditActivityRoleAvailable = authorizationService.IsRolePresent(nextProps.roles, Permissions.CreateUpdateActivity);
    const isCreateEditFolderRoleAvailable = authorizationService.IsRolePresent(nextProps.roles, Permissions.SubFolders);
    this.setState({
      isCreateEditFolderRoleAvailable,
      isCreateEditActivityRoleAvailable,
      isRoleAvailable: isCreateEditFolderRoleAvailable || isCreateEditActivityRoleAvailable
    })
    if (this.state.isCreateEditActivityRoleAvailable || this.state.isCreateEditFolderRoleAvailable) {
      if (nextProps !== null && nextProps.SelectedActivity && nextProps.SelectedActivity.id !== undefined) {
        this.setState({
          checked: false,
          isRoleAvailable: (nextProps.SelectedActivity.is_folder && this.state.isCreateEditFolderRoleAvailable) || (!nextProps.SelectedActivity.is_folder && this.state.isCreateEditActivityRoleAvailable)
        })
      }
    }
  }
  private _onCopyClicked(event): void {
    this.props.OnActivityActionButtonClick(ActivityAction.Copy);
  }
}