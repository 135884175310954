import * as React from 'react';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { TextField, ITextField } from 'office-ui-fabric-react/lib/TextField';
import { Coachmark, DirectionalHint, TeachingBubbleContent } from 'office-ui-fabric-react';
export class ActivityDetailTitle extends React.Component<{
    ActivityTitle: string,
    ActivityID: string,
    IsActivity: boolean,
    OnTitleModified: (newName: string) => void,
},
    {
        Title: string,
        isCoachmarkVisible: boolean
    }>{
    private _activityTitle = React.createRef<ITextField>();
    private _targetButton = React.createRef<HTMLDivElement>();
    constructor(props: {
        ActivityTitle: string,
        ActivityID: string,
        DisplayValidationMessageBar: boolean,
        DisplaySuccessMessageBar: boolean,
        DisplayErrorMessageBar: boolean,
        ErrorMessage: string,
        IsActivity: boolean,
        OnTitleModified: (newName: string) => void
    }) {
        super(props);
        this.onTextFocus = this.onTextFocus.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.state = {
            Title: '',
            isCoachmarkVisible: true
        }
        this._onDismiss = this._onDismiss.bind(this);
    }

    public componentWillReceiveProps(nextProps: any) {
        this.setState({ Title: nextProps.ActivityTitle });
    }
    public componentDidMount() {
        this.setState({ Title: this.props.ActivityTitle });
        setTimeout(() => {
            if (this._activityTitle.current) {
                this._activityTitle.current.focus();
                this._activityTitle.current.select();
            }
        }, 2000);
    }
    public render(): JSX.Element {
        return (
            <div>
                <div key={"titleDiv"} ref={this._targetButton}>
                    <Label aria-label="ActivityTitle">
                        <TextField className="activityTitleLabel font-Size-16"
                            placeholder="Enter Activity Name" defaultValue={this.state.Title}
                            componentRef={this._activityTitle} onClick={this.onTextFocus}
                            onChange={this.onTextChange}
                            style={{ fontWeight: 500 }}
                        /></Label>
                </div>
                {this.state.isCoachmarkVisible && (
                    <Coachmark
                        target={this._targetButton.current}
                        styles={{ root: { paddingRight: '8vh' } }}
                        positioningContainerProps={{
                            directionalHint: DirectionalHint.bottomRightEdge,
                            doNotLayer: false,
                            className: 'coachMarkActivityTitle'
                        }}
                        ariaAlertText="New Activity dialog"
                        ariaDescribedByText={'Press enter or alt + C to open the Coachmark notification'}
                        ariaLabelledByText={'New Activity dialog'}
                    >
                        <TeachingBubbleContent
                            headline="Activity Title"
                            hasCloseIcon={true}
                            closeButtonAriaLabel="Close"
                            onDismiss={this._onDismiss}
                            ariaDescribedBy={'example-description1'}
                            ariaLabelledBy={'example-label1'}
                            styles={{ headline: { fontSize: '20px' } }}
                        >
                            Enter Text above to change the Activity Title
                        </TeachingBubbleContent>
                    </Coachmark>)}
                <div>
                    {this.props.IsActivity && <Label className="font-Size-36">Activity ID: {this.props.ActivityID}</Label>}
                </div>
                {
                    this.props.IsActivity ?
                        <Label style={{ marginRight: '5%' }} className="requiredField">
                            <span style={{ color: "rgb(168, 0, 0)" }}>*</span> Required Field
                        </Label> :
                        <div />
                }
            </div>
        );
    }
    private onTextFocus(event): void {
        this._activityTitle.current.select();
        this.setState({ isCoachmarkVisible: false });
    }
    private onTextChange(event): void {
        this.props.OnTitleModified(event.target.value);
    }
    private _onDismiss = (): void => { this.setState({ isCoachmarkVisible: false }); };
}
