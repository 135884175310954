import { initializeIcons } from '@uifabric/icons';
import * as React from 'react';
import './App.css';
import { IUserDetails } from './contracts/IUserDetails';
import {AuthorizedUserDetails}  from './components/AuthorizedUserDetails'
import { IActivityNotification } from './contracts/IActivityNotification';
initializeIcons();

class App extends React.Component<{
  user: IUserDetails,
  configUrl : string,
  notification:(ActivityNotification:IActivityNotification) => void,
  sethelppageid:(HelpPageId:string)=>void
}> {
  public render() {
    return (
      <AuthorizedUserDetails user={this.props.user} configUrl={this.props.configUrl} notification={this.props.notification} sethelppageid={this.props.sethelppageid} />
    );
  }
}

export default App;
