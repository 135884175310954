import * as React from 'react';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { Modal } from 'office-ui-fabric-react/lib/Modal';
import { ImportPanel } from'@commercialsalesandmarketing/pet-common';
import { AnyRecord } from 'dns';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { Config, AppSettings } from '../../../Configuration';
import { WorkSpaceDropDownWithSingleSelect} from '@commercialsalesandmarketing/pet-org-hierarchy';
import { isNullOrUndefined } from 'util';
import { HierarchyService } from '@commercialsalesandmarketing/pet-org-hierarchy';
import { AuthorizationService } from'@commercialsalesandmarketing/pet-common';
import { Permissions } from '../../../contracts/Permissions';
import { ContextualMenu } from 'office-ui-fabric-react';

export class ActivityImportPanel extends React.Component<
    {
        additionalProperties: {},
        showModal: boolean,
        UserHierarchyDetails: any,
        onCloseImportPanel: any,
        Workspaces: any[]
    },
    {
        showModal: boolean,
        isUploadInProgress: boolean,
        bannerMessage: string,
        displayBanner: boolean,
        isError: boolean,
        additionalProperties: any,
        filteredWorkspaces: any[]
    }
    > {
    constructor(props: {
        additionalProperties: {},
        showModal: boolean,
        UserHierarchyDetails: AnyRecord,
        onCloseImportPanel: any,
        Workspaces: any
    }) {
        super(props);
        this.state = {
            showModal: this.props.showModal,
            isUploadInProgress: false,
            bannerMessage: '',
            displayBanner: false,
            isError: false,
            additionalProperties:{},
            filteredWorkspaces:[]
        };
        this.closeModal = this.closeModal.bind(this);
        this.uploadInProgress = this.uploadInProgress.bind(this);
        this.dismissMessageBar = this.dismissMessageBar.bind(this);
        this.setSelectedWorkSpace=this.setSelectedWorkSpace.bind(this);
        this.DoNothing=this.DoNothing.bind(this);
        this.filterWorkspacesWithUserPermissions=this.filterWorkspacesWithUserPermissions.bind(this);
    }

    public render(): JSX.Element {
        const _msgBarType = this.state.isError ? MessageBarType.error : MessageBarType.success;
        return (
            <Modal styles={{
                scrollableContent: {
                    minHeight: "80vh !important",
                    minWidth: "70vw !important"
                }
            }}
                scrollableContentClassName="scrollDisable"
                isOpen={this.state.showModal}
                onDismiss={this.closeModal}
                isBlocking={false} className="modalBulkImport"
                dragOptions={{ moveMenuItemText: "move", closeMenuItemText: "close", menu: ContextualMenu }} >
                <Stack className="bulkImportModalStack">
                    <Stack.Item>
                        <div className="ms-fontSize-l" style={{ justifyContent: "space-between" }}>
                            <span id="spnImportTitle" >Import Activity Data</span>
                            <IconButton disabled={false} checked={false} iconProps={{ iconName: 'Clear' }} title="Close" ariaLabel="Close"
                                key={"hideImportPanelBtn"}
                                onClick={this.closeModal} style={{ float: "right" }} />
                            {
                                this.state.displayBanner ?
                                    <MessageBar messageBarType={_msgBarType} isMultiline={false} onDismiss={this.dismissMessageBar}
                                        dismissButtonAriaLabel="Close" >
                                        {this.state.bannerMessage}
                                    </MessageBar> : <div />
                            }
                        </div>
                    </Stack.Item>
                    <br /><br />
                    <Stack.Item styles={{ root: { width: "25%", height: "3%", maxHeight: "4%" } }}>
                        <WorkSpaceDropDownWithSingleSelect
                            Disabled={this.state.isUploadInProgress} 
                            OnWorkSpaceChanged={this.setSelectedWorkSpace} 
                            SelectedWSValue={this.state.additionalProperties.WorkspaceId} 
                            IsSearchable={false}
                            WSDropdownId="ImportWorkSpaceDropdown"
                            WSStyles={{FontSize:'14px !important',
                            Width: '302px !important',
                            Height :'32px !important',
                            paddingTop:'0px !important',
                            border: '1px Solid #666666 !important',
                            borderFocused: '1px Solid rgb(0, 120, 212) !important',
                            borderHovered: '1px Solid #666666 !important'}}
                            WorkspaceOptions={this.state.filteredWorkspaces} 
                            onMenuDismissed= {this.DoNothing}
                            AppSettings={AppSettings}
                        />  
                    </Stack.Item>
                    <br />
                    <Stack.Item>
                        <ImportPanel additionalProperties={this.state.additionalProperties}
                            HandleUploadInProgress={this.uploadInProgress}
                            HandleResponseMessage={this.setResponse}
                            entityType={Config.BulkImportType.Activity}
                            AppSettings={AppSettings}  />
                    </Stack.Item>
                </Stack>
            </Modal>
        );
    }

    public componentDidMount() {
        this.setState({ showModal: this.props.showModal, bannerMessage: '', displayBanner: false,additionalProperties:this.props.additionalProperties });
        if(!isNullOrUndefined(this.props.Workspaces))
        {
            this.filterWorkspacesWithUserPermissions();
        
        }
    }

    private filterWorkspacesWithUserPermissions() {
        const filteredWorkSpaces = [];
        const hierachyServie = new HierarchyService(AppSettings);
        var userpaths = hierachyServie.GetPathListByPermission(this.props.UserHierarchyDetails.UserHierarchyNodes, Permissions.CreateUpdateActivity);
        const authService = new AuthorizationService(AppSettings);
        var extractedPaths = authService.GetChildNodes(userpaths, this.props.UserHierarchyDetails.ParentNodesMap);
        for (const workspace of this.props.Workspaces) {
            if (extractedPaths.some(x => x === workspace.key)) {
                filteredWorkSpaces.push(workspace);
            }
        }
        this.setState({ filteredWorkspaces: filteredWorkSpaces });
    }

    public componentWillReceiveProps(nextProps: any) {
        if (nextProps.showModal && !this.props.showModal) {
            this.setState({ showModal: nextProps.showModal, bannerMessage: '', displayBanner: false });
            if( isNullOrUndefined(this.state.additionalProperties.WorkspaceId) || this.state.additionalProperties.WorkspaceId==='')
            {
                this.setState({additionalProperties:this.props.additionalProperties});
            }
        }
        else {
            this.setState({ showModal: nextProps.showModal });
        }
        
    }
    public dismissMessageBar() {
        this.setState({ displayBanner: false })
    }

    private closeModal = (): void => {
        this.setState({ showModal: false });
        this.props.onCloseImportPanel();
    };

    private uploadInProgress = (_isUploadInProgress: boolean): void => {
        this.setState({ isUploadInProgress: _isUploadInProgress });
    }

    private setResponse = (responseMessage: string, isError: boolean): void => {
        this.setState({ bannerMessage: responseMessage, displayBanner: true, isError: isError });
    }
    
    private setSelectedWorkSpace(item:any)
    {
       var additionalProperties=this.state.additionalProperties;
       additionalProperties.WorkspaceId=item.key;
       this.setState({additionalProperties});
    }
   
    private DoNothing()
    {
    }
}