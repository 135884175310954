import { IActivityDefinition } from './IActivityDefinition';

export interface IActivitySearch {
    Activities: any[],
    ClearSearch: boolean,
    ShowHierarchy: boolean,
    SelectedWorkspaces: Array<{id: string, name: string, nodeTaxonomies: any[]}>,
    ActivityDefinitionsForSearchedWorkspaces:  Array<{workspaceID: string, activityDef: IActivityDefinition}>
}

export enum SearchType{
    None=0,
    Activity=1
}