import * as React from 'react';
//import { ActivityAction } from '../../../../contracts/ActivityAction';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { Config } from '../../../../Configuration';
import { IActivityDeifinitionMember } from '../../../../contracts/IActivityDefinitionMember';
import { ActivityAction } from '../../../../contracts/ActivityAction';
import { Callout } from 'office-ui-fabric-react/lib/Callout';

export class CustomizeColumnsButton extends React.Component<{
  OnActivityActionButtonClick: (action: ActivityAction) => void,
  OnSelectedColumns: (items: IActivityDeifinitionMember[]) => void,
  AllColumns: any[]  
},
{
 
  avialableCloumns: any[],
  SelectedActivityDef: any[],
  showModal : boolean

}> {
public constructor(props: {
  OnActivityActionButtonClick: (action: ActivityAction) => void,
  OnSelectedColumns: (items: IActivityDeifinitionMember[]) => void,
  AllColumns: any[]  
}) {
  super(props);
  this._onCustomizeCicked = this._onCustomizeCicked.bind(this);
  this._onClose = this._onClose.bind(this);  
  this._onCheckBoxChnage = this._onCheckBoxChnage.bind(this);
  this.removeDefn = this.removeDefn.bind(this);
  this.addDefn = this.addDefn.bind(this);
  this.state = {
    avialableCloumns:[],
    SelectedActivityDef:[],
    showModal : false
  }
  
}

public componentWillReceiveProps(nextProps: any) {
    if (nextProps !== null && nextProps.AllColumns) {
      this.setState({avialableCloumns : nextProps.AllColumns});
    }
  }
  private _menuButtonElement = React.createRef<HTMLDivElement>();
public render(): JSX.Element {  
  return (
    <div className="activityActionButtonContainer" style={{marginRight:"7px"}} >
      <TooltipHost
          className="actionButtontoolTip"
          calloutProps={{ gapSpace: -10 }}
          closeDelay={100}
          hostClassName="selectedTaxonomyValueTooltip"
          tooltipProps={{
            onRenderContent: () => {
              return (
                <div>
                  <ul style={{ margin: 0, padding: 0 }}>
                    <li><span>Customize Columns</span></li>
                  </ul>
                </div>
              );
            }
          }}
          delay={TooltipDelay.zero}
          directionalHint={DirectionalHint.topCenter}
        >
          <div style={{ display: 'flex',position:"relative"}}>
           <div ref={this._menuButtonElement}>
            <ActionButton
              data-automation-id="test"
              iconProps={{ iconName: 'ColumnLeftTwoThirdsEdit' }}
              allowDisabledFocus={true}
              onClick={this._onCustomizeCicked}
              className="activityActionButton">Customize Columns</ActionButton></div>

        { this.state.showModal?
          <Callout 
           target={this._menuButtonElement.current}
           onDismiss={this._onClose}
           gapSpace={0}
           setInitialFocus={true}  
           >        
           
              <div id="columnCheckDiv">
               {                 
                 this.state.avialableCloumns ?
                    this.state.avialableCloumns.map(item => (    
                      <Checkbox name={item.name} 
                                label = {item.labels[Config.locale]?item.labels[Config.locale] : item.name} 
                                key = {item.name} 
                                defaultChecked = {item.is_checked ? true : false}
                                onChange = {this._onCheckBoxChnage}
                                disabled = {Config.DisabledActivityColumns.indexOf(item.name)>=0}
                                 />                   
                    )) 
                    : ''
               }
                
                </div>
            
          </Callout>
          :''
        }

        

          </div>
        </TooltipHost>
      </div>
    );
  }
  
  private _onCustomizeCicked(): void {
    this.setState({showModal : true});
  }

  private _onClose(): void {
    this.setState({showModal : false});
    this.props.OnSelectedColumns(this.state.avialableCloumns);    
  } 

  private _onCheckBoxChnage = (ev, isChecked: boolean) => {
    if(!isChecked){
      this.removeDefn(ev.target.name);
    }
    else{
      this.addDefn(ev.target.name);
    }
  };

  private removeDefn(defnName : string) {
    var array = [...this.state.avialableCloumns]; 
    var index = array.findIndex(actv => actv.name === defnName);
    if(index !== -1){
      array[index].is_checked = false;
      this.setState({avialableCloumns: array});
    }  
  }

  private addDefn(defnName : string) {
    var array = [...this.state.avialableCloumns]; 
    var index = array.findIndex(actv => actv.name === defnName);
    if(index !== -1){
      array[index].is_checked = true;
      this.setState({avialableCloumns: array});
    }
    
  }
  
}