import * as React from 'react';

import { NormalPeoplePicker, IBasePicker } from 'office-ui-fabric-react/lib/Pickers';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { IconButton, ActionButton } from 'office-ui-fabric-react/lib/Button';
import { IActivitySearch } from '../../contracts/IActivitySearch';
import { IPersonaProps } from 'office-ui-fabric-react/lib/Persona';
import { ActivityType } from '../../contracts/ActivityTypeEnum';
import { Config } from '../../Configuration';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

export interface ISearchSuggestions extends IPersonaProps {
  key: string,
  type: number,
  displayname: string,
  activity: any
}

export class Search extends React.Component<{
  ActivitiesList: any[],
  SelectedNode: any,
  EnableAdvancedSearch: boolean,
  ClearSearch: boolean,
  OnActivitySearch: (ActivitySearch: IActivitySearch) => void,
  OnAdvancedSearchClicked: () => void
}, {
  searchItems: ISearchSuggestions[],
  currentSelectedItems: ISearchSuggestions[],
  totalResultsCount: number,
  enableAdvancedSearch: boolean
}
  > {
  // All pickers extend from BasePicker specifying the item type.
  public _picker = React.createRef<IBasePicker<ISearchSuggestions>>();

  constructor(props: {
    ActivitiesList: any[],
    SelectedNode: any,
    EnableAdvancedSearch: boolean,
    ClearSearch: boolean,
    OnActivitySearch: (ActivitySearch: IActivitySearch) => void,
    OnAdvancedSearchClicked: () => void
  }) {
    super(props);
    this._onAdvancedSearchClicked = this._onAdvancedSearchClicked.bind(this);
    this._removeSelectedItem = this._removeSelectedItem.bind(this);
    this._onRenderItem = this._onRenderItem.bind(this);
    this._onSelectionChange = this._onSelectionChange.bind(this);
    this._onRenderSuggestionsItem = this._onRenderSuggestionsItem.bind(this);
    this._getTextFromItem = this._getTextFromItem.bind(this);
    this._resultsFooterFull = this._resultsFooterFull.bind(this);
    this._resultsFooter = this._resultsFooter.bind(this);
    this._onItemSelected = this._onItemSelected.bind(this);
    this._onFilterChanged = this._onFilterChanged.bind(this);
    this._geticon = this._geticon.bind(this);
    this.state = {
      searchItems: [],
      currentSelectedItems: [],
      totalResultsCount: 0,
      enableAdvancedSearch: true
    };
  }

  public componentWillReceiveProps(nextProps: any) {
    if (nextProps !== null && nextProps.ActivitiesList != null) {
      const searchItems: any[] = [];
      nextProps.ActivitiesList.forEach((element) => {
        searchItems.push({
          key: element.id,
          type: element.type,
          text: element.name,
          displayname: element.is_folder ?
            "  " + element.name
            : "  " + (element.activityid !== '' && element.activityid !== undefined ? element.activityid + " - " : '') + element.name,
          activity: element
        });
      });
      this.setState({ searchItems });
      if (this.props.SelectedNode !== null && this.props.SelectedNode.key !== nextProps.SelectedNode.key) {
        this.setState({ currentSelectedItems: [] });
      }
    }
    this.setState({ enableAdvancedSearch: nextProps.EnableAdvancedSearch })
    if (nextProps.ClearSearch) {
      this.setState({ currentSelectedItems: [] })
    }
  }

  public componentDidMount() {
    if (this.props !== null && this.props.ActivitiesList != null) {
      const searchItems: any[] = [];
      this.props.ActivitiesList.forEach((element) => {
        searchItems.push({
          key: element.id,
          type: element.type,
          text: element.name,
          displayname: element.is_folder ?
            "  " + element.name
            : "  " + (element.activityid !== '' && element.activityid !== undefined ? element.activityid + " - " : ' ') + element.name,
          activity: element
        });
      });
      this.setState({ searchItems });
    }
    this.setState({ enableAdvancedSearch: this.props.EnableAdvancedSearch })
  }
  public render() {
    const self = this;
    const labelMessage = self.state.totalResultsCount > 0 ? 'Showing ' + (self.state.totalResultsCount <= Config.Actvity.DisplaySearchResultsCount ? self.state.totalResultsCount : Config.Actvity.DisplaySearchResultsCount) + ' of ' + self.state.totalResultsCount + ' matched results' : ''
    return (
      <Stack horizontalAlign="end" role="search">
        <Stack.Item className="divActivitySearch">
          <NormalPeoplePicker
            onResolveSuggestions={self._onFilterChanged}
            getTextFromItem={self._getTextFromItem}
            className={'ms-PeoplePicker'}
            removeButtonAriaLabel={'Remove'}
            componentRef={self._picker}
            key={'controlled'}
            selectedItems={self.state.currentSelectedItems}
            onChange={self._onSelectionChange}
            pickerSuggestionsProps={{
              noResultsFoundText: 'No matches Found',
              showRemoveButtons: false,
              resultsFooter: self._resultsFooter,
              resultsFooterFull: self._resultsFooterFull,
              resultsMaximumNumber: Config.Actvity.DisplaySearchResultsCount,
              searchingText: 'Searching..',
              suggestionsAvailableAlertText: 'Results found',
              suggestionsContainerAriaLabel: 'Suggestions Panel',
              searchForMoreText: labelMessage,
              suggestionsClassName: 'divSuggestionsLst',
              className: 'divSearchActivity',
              loadingText: 'Loading',
            }}
            itemLimit={1}
            inputProps={{
              'aria-label': 'Activity Search',
              placeholder: 'Search'
            }}
            onRenderSuggestionsItem={self._onRenderSuggestionsItem}
            onRenderItem={self._onRenderItem}
            onItemSelected={self._onItemSelected}
            styles={{
              itemsWrapper: {
                display: self.state.currentSelectedItems.length > 0 ? "block" : "none"
              }
            }}
          />
          {this.state.currentSelectedItems.length > 0 ?
            <IconButton iconProps={{ iconName: "Cancel", style: { fontSize: 14 } }} className="icon-activitysearchclear" onClick={this._removeSelectedItem} title="Clear Search" ariaLabel="Clear Search" />
            :
            <Icon iconName='Search' className="icon-activitysearch" title="Search" ariaLabel="Search" />
          }

        </Stack.Item>
        {this.state.enableAdvancedSearch && <Stack.Item>
          <ActionButton
            data-automation-id="test"
            allowDisabledFocus={true}
            onClick={this._onAdvancedSearchClicked}
            className="advancedSearchButton">Activity Advance Search</ActionButton>
        </Stack.Item>}
      </Stack>
    );
  }

  private _onAdvancedSearchClicked = () => {
    this.setState({ currentSelectedItems: [] }, () => {
      this.props.OnAdvancedSearchClicked();
    })
  }

  private _removeSelectedItem() {
    if (this._picker.current && this._picker.current.items) {
      this.setState({ currentSelectedItems: [] }, () => {
        this.props.OnActivitySearch({ ClearSearch: true, Activities: [], ShowHierarchy: true, SelectedWorkspaces: [], ActivityDefinitionsForSearchedWorkspaces: [] })
      })
    }
  }

  private _onRenderItem(props: any): JSX.Element {

    return (
      <div className="divSelectedActivity font-Size-12" role="listitem">
        <Icon className="color-blue" iconName={this._geticon(props.item.type)} ariaLabel={props.item.type === ActivityType.Category ? "Category" : props.item.type === ActivityType.SubCategory ? "Sub-category" : "Activity"} title={props.item.type === ActivityType.Category ? "Category" : props.item.type === ActivityType.SubCategory ? "Sub-category" : "Activity"} />
        <span className="spnSelectedActivity" title={props.item.displayname} id="slectedlistitem" >{props.item.displayname}</span>
      </div>
    );
  }

  private _onSelectionChange(items: any[]): void {
    this.setState({
      currentSelectedItems: items
    });
  };

  private _onRenderSuggestionsItem(props: ISearchSuggestions, itemprops: any): JSX.Element {
    return (
      <div className="divActivitySuggestions font-Size-12">
        <Icon className="color-blue" iconName={this._geticon(props.type)} ariaLabel={props.type === ActivityType.Category ? "Category" : props.type === ActivityType.SubCategory ? "Sub-category" : "Activity"} title={props.type === ActivityType.Category ? "Category" : props.type === ActivityType.SubCategory ? "Sub-category" : "Activity"} />
        <span className="spnSearchActivity"> {props.displayname}</span></div>
    );
  }

  private _getTextFromItem(item: IPersonaProps): string {
    return '';
  }

  private _resultsFooterFull(): JSX.Element {
    return <div>{'Showing ' + Config.Actvity.DisplaySearchResultsCount + ' of ' + this.state.totalResultsCount + ' matched results'}</div>
  }

  private _resultsFooter(): JSX.Element {
    return <div>{'Showing ' + this.state.totalResultsCount + ' of ' + this.state.totalResultsCount + ' matched results'}</div>
  }

  private _onItemSelected = (item: ISearchSuggestions): ISearchSuggestions | null => {
    let activitiesList: any[] = [];
    activitiesList.push(item.activity);
    this.props.OnActivitySearch({ ClearSearch: false, Activities: activitiesList, ShowHierarchy: true, SelectedWorkspaces: [], ActivityDefinitionsForSearchedWorkspaces: [] })
    return item;
  };

  private _onFilterChanged = (filterText: string, tagList: ISearchSuggestions[]): ISearchSuggestions[] => {
    if (filterText && filterText.trim() !== '') {
      const filteredItems: any[] = this.state.searchItems
        .filter(tag => tag.text.toLowerCase().indexOf(filterText.toLowerCase()) >= 0 ||
          (tag.key.indexOf(filterText) === 0 && tag.type === ActivityType.Activity)).sort((a, b) => a.text.localeCompare(b.text));

      this.setState({
        totalResultsCount: filteredItems.length
      })

      return filteredItems;
    }
    return [];
  };

  private _geticon(type: number): string {
    if (type === ActivityType.Category) {
      return "FolderOpen";
    }
    else if (type === ActivityType.SubCategory) {
      return "FormLibrary";
    }
    else if (type === ActivityType.Activity) {
      return "Table";
    }
    return "";
  }
}