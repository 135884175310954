export class AppSettings{
    private static _configData:any;
    static updateConfig(configData:any):void{
        this._configData = configData;
    }
    public static ConfigData():any{
        return this._configData;
    }
    public static AuthorizationUrl():string{
        return  `${this._configData.authorization.base_url}/${this._configData.authorization.request_uri}` ;
    }
    public static GetAllUsersUrl():string{
        return  `${this._configData.authorization.base_url}/${this._configData.authorization.get_all_users}` ;
    }
    public static AuthorizationBaseUrl():string{
         return  `${this._configData.authorization.base_url}` ;
    } 
    public static UserPreferenceUrl():string{
        return  `${this._configData.userpreference.base_url}` ;
    }
    public static ActivityBaseUrl():string{
        return this._configData.activity.base_url;
    }
    public static ActivityDefinitionUrl():string{
        return this._configData.activity.definition_url;
    }
    public static CategoryUrl():string{
        return this._configData.activity.category_url;
    }
    public static HierarchyUrl():string{
        return this._configData.hierarchy.base_url;
    }
    public static IOServiceUrl():string{
        return this._configData.io.base_url;
    }
    public static POServiceUrl():string{
        return this._configData.po.base_url;
    }
    public static MappingServiceUrl():string{
       return this._configData.mapping.base_url;
    }
    public static UnMappedPOServiceUrl():string{
        return this._configData.unmappedpo.base_url;
    }
    public static TaxonomyServiceUrl():string{
        return this._configData.taxonomy.base_url;
    }
    public static TaxonomyServiceV2Url():string{
        return this._configData.taxonomy.base_url_v2;
    }
    public static MyOrderBaseUrl():string{
        return this._configData.myorder.base_url;
    }
    public static QSGUrl():string{
        return this._configData.qsg.base_url
    }
    public static BulkImportUrl():string{
        return this._configData.bulkimport.base_url
    }
    public static AIInstrumentationKey():string{
        return this._configData.ai_instrumentationKey;
    }
    public static Env():string{
        return this._configData.env;
    }
    public static AuditServiceUrl():string{
        return this._configData.audit.base_url
    }
    public static HomePageUrl(): string {
        return this._configData.homePageURL;   
    }
    public static OCVAppID():number{
        return this._configData.ocv.appid;
    }
    public static OCVProd():number{
        return this._configData.ocv.prod;
    }
    public static defaultValue_coexecutionwithmcs():string{
        return this._configData.defaultValues.coexecutionwithmcs;
    }
    public static AnalyticsUrl():string{
        return this._configData.insight.url;
    }
    public static InsightFYs():any[]{
        return this._configData.insight.fy;
    }
    public static InsightDeaultFY():string{
        return this._configData.insight.default.FY;
    }
    public static InsightDetailReportDownloadUrl():string{
        return this._configData.insight.reportUrl;
    }
    public static HelpInfoUrl():string{
        return this._configData.help.helpURL;
    }
    public static DisplayHelp():boolean{
        return this._configData.help.displayHelp;
    }
    public static HelpAppId():string{
        return this._configData.help.helpappid;
    }
    public static FAQUrl(): string {
        return this._configData.help.faqURL;
    }
}
export const Config= {
    Audit:{
        Search:'search',
        Export:'export'
    },
    BulkImport:{
        BulkImportFileHistory:'importhistory',
        BulkImportDownloadFileFromHistory:'importhistoryfile'
    },
    Actvity:{
       Search:'search',
       SearchByOrgPath:'byOrgPathId',
       SearchByOrgPathIds:'byOrgPathIds',
       SearchByOrgPathWithoutFolder:'byOrgPathIdWithoutFolder',
       DefinitionId:'activityDef',
       DisplaySearchResultsCount:10,
       UpdateParentEndPoint:'updateparent',
       CopyandPasteActivity:'copyactivity',
       CopyandPasteFolder:'copyfolder',
       ExportSearch:'exportsearch'
    },
    Hierarchy: {
        WorkSpaceUrl: 'api/organization/getUserNodes'
    },
    Authorization: {
        RequestUri: 'api/auth/authenticateUser',
        validateUserAlias : 'api/auth/ValidateUserAlias',
        validateUserAliases : 'api/auth/ValidateUserAliases',
        GetSimilarAlias : 'api/auth/GetSimilarAlias',
        GetAllRoles:"api/role/GetAllRoles",
        GetUsersByRole:"api/role/GetUsersByRole",
        UpdateUserOnboardingRequest:"api/onboard/updateUserOnboardingReq",
        UpdateNewUserOnboardingRequest:"api/onboard/updateNewUserOnboardingReq",
        GetOnboardingRequests: "api/auth/getOnboardingRequests",
        DeleteUser: "api/admin/deleteuser",
        OnBoardNewUsersByAdmin:"api/onboard/onboardnewusers",
        CheckUserAlreadyOnboarded:"api/auth/validateOnboardingUsers"
    },
    UserPreference: {
        BaseUri: 'api/auth',
        UpdateUserPreference: 'updateUserPreference',
        GetUserPreference: 'getUserPreferenceByUser'
    },
    Mapping: {
        MappedEndPoint: 'mapping/mapped',
        UpdateMapping: '/mapping/map',
        MapDefinition: 'definition/pomapdef',
        SearchMappedPO:"mapping/Searchmapped",
        UnMapDefinition: '/definition/pounmapdef',
        LandingCountryEntityDefinition: 'country.taxonomy.def.id'
    },
    Taxonomy: {
        GetTaxonomiesUrl: 'search',
        GetDependantTaxonomiesUrl: 'search',
        GetAll:'getall/false',
        GetOwnerAlias:'ownerAliasList',
        UpdateownerAlias:'UpdateownerAliasList'
    },
    locale: 'en-us',
    localeCurrency:'USD',
    InsightsAggregatableProperties: 'activityid', // Add commna seperated list of property names
    BulkImportType: {
        Activity:'activity',
        UserOnboard: 'UserOnBoard',
        MapPO: 'MapPO'
    },
    QSG: {
        Params: [
            'Campaign/Gep+Name',
            'Execution+Team',
            'Co-Execution+With+Service',
            'Primary+Objective',
            'Engine',
            'Target+Segment',
            'ActivityID',
            'Activity+Name',
            'Landing+Geo',
            'Activity+Owner'
        ]
    },
    DisabledActivityColumns:["name","activityid"],
    NotToBeDisplayedActivityColumns:["notes","eventseries"],
    PreferenceKeys:{
        HirerarchyNode : "HirerarchyNode",
        ActivityColumnView : "ActivityColumnView"
    },
    FYRootId:["25946"],
    AdminRoleId : 2,
    UserOnboardingStatus : {
        Pending:"Pending",
        Approved:"Approved",
        Rejected:"Rejected"
    },
    DPNURL:"https://microsoft.sharepoint.com/teams/msdpn/sitepages/default.aspx",
    Help:{
        HomePage:"LandingPage",
        ActivityAttributes:"ActivityAttributes",
        UnMappedPO:"UnMappedPO",
        MappedPO:"MappedPO",
        AdvancedSearch:"AdvancedSearch"
    }
};