export enum ActivityAction {
    none = 0,
    AddFolder = 1,
    AddActivity = 2,
    ExpandAll = 3,
    CollapseAll = 4,
    Delete = 5,
    Cut = 6,
    Copy = 7,
    Paste = 8,
    CustomColumnSelection = 9
}
export enum DialogAction{
    none=0,
    Yes=1,
    No=2
}