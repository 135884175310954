import * as React from 'react';
import { TextField, ITextFieldProps } from 'office-ui-fabric-react/lib/TextField';
import { DatePicker } from '@uifabric/date-time';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { isNullOrUndefined } from 'util';
import { Stack, StackItem } from 'office-ui-fabric-react/lib/Stack';
import { ActivityPropertyTooltip } from './ActivityPropertyTooltip';

export class ActivityProperty extends React.Component<{
    name: string,
    label: string,
    isMandatory: boolean,
    attributeType: string,
    onValueChanged: any,
    propertyValue: any,
    informationText:string
}>{
    constructor(props) {
        super(props);
        this.onDateValueChanged = this.onDateValueChanged.bind(this);
        this.onTextValueChanged = this.onTextValueChanged.bind(this);
    }
    public render() {
        const dateTime = (this.props.propertyValue !== null && this.props.propertyValue !== undefined) ? this.convertToLocal(new Date(this.props.propertyValue)) : null;
        return (
            <div>
                {
                    this.isDateTime(this.props.attributeType) ?
                        <div className="activityProperty-Date" id={"datepicker"+this.props.label+ this.props.propertyValue}>
                        <Stack>
                            <StackItem>
                            <Stack horizontal grow>
                                    <StackItem>
                                    <Label required={this.props.isMandatory} className="font-Size-12">{this.props.label}</Label>
                                    </StackItem>
                                    <StackItem>
                                    { !isNullOrUndefined(this.props.informationText) && this.props.informationText!==""  &&
                                    <ActivityPropertyTooltip informationText={this.props.informationText} />
                                }
                                </StackItem>
                            </Stack>
                        </StackItem>
                        <StackItem>
                            <DatePicker style={{ fontSize: 12 }}
                                value={dateTime}
                                placeholder="Select a date..."
                                ariaLabel={this.props.label + "Select a date"}
                                onSelectDate={this.onDateValueChanged}
                                textField={{ style: { fontSize: "12px" } }}
                                pickerAriaLabel ="Select a date"
                                data-automation-id={this.props.name}
                                title="Select a date"
                                />
                                </StackItem>
                                </Stack>
                        </div>
                        :  
                            (!isNullOrUndefined(this.props.informationText) && this.props.informationText!=="" )? <Label aria-label={this.props.label}  style={{paddingTop:'5px' }} >
                                <TextField label={this.props.label} required={this.props.isMandatory} 
                                onBlur={this.onTextValueChanged} value={isNullOrUndefined(this.props.propertyValue)?"":this.props.propertyValue}
                                onRenderLabel={this._onRenderLabel} id={'txt'+this.props.label.replace(/\s/g, "")}
                                ariaLabel= { this.props.label+"Enter text"} aria-labelledby={'lbl'+this.props.label.replace(/\s/g, "")} className="textProperties" />
                          </Label>:
                                <TextField label={this.props.label} required={this.props.isMandatory} 
                                onBlur={this.onTextValueChanged} value={isNullOrUndefined(this.props.propertyValue)?"":this.props.propertyValue}
                                id={'txt'+this.props.label.replace(/\s/g, "")}
                                ariaLabel= { this.props.label+" Enter text"} 
                                className="textProperties" styles={{root:{fontSize:'12px !important'}}} />
                        
                }
            </div>

        );
    }

    private isDateTime(type: string): boolean {
        return type === 'datetime';
    }
 
    private onTextValueChanged = (ev: any): void => {
        const oldValue = isNullOrUndefined(this.props.propertyValue) ? "" : this.props.propertyValue; //Treat null and empty string to be the same
        let newValue = !isNullOrUndefined(ev.currentTarget) ? ev.currentTarget.value : ev.target.value;
        newValue = isNullOrUndefined(newValue) ? "" : newValue;
        if (oldValue !== newValue) {
            this.props.onValueChanged(!isNullOrUndefined(ev.currentTarget) ? ev.currentTarget.value : ev.target.value, this.props.name);
        }
    }

    private onDateValueChanged = (item: Date): void => {
        this.props.onValueChanged(!(item === null || item === undefined)? this.convertToUTC(item).toISOString() : item, this.props.name);
    }

    private _onRenderLabel = (props: ITextFieldProps): JSX.Element => {
        return (
            <div>
                { !isNullOrUndefined(this.props.informationText) && this.props.informationText!==""  &&
                    <Stack className="ms-CustomRenderExample" style={{ display: 'flex', alignItems: 'center' }} horizontal grow>
                        <StackItem>
                            <Label className="font-Size-12" aria-label={props.label} required={props.required} id={'lbl'+props.label.replace(/\s/g, "")} htmlFor={'txt'+props.label.replace(/\s/g, "")}>{props.label}</Label>
                        </StackItem>
                        <StackItem>
                            <ActivityPropertyTooltip informationText={this.props.informationText} />
                        </StackItem> 
                    </Stack>
                }
                { (isNullOrUndefined(this.props.informationText) || this.props.informationText==="" ) &&
                    <Label className="font-Size-12" aria-label={props.label} required={props.required} id={'lbl'+props.label.replace(/\s/g, "")} htmlFor={'txt'+props.label.replace(/\s/g, "")}>{props.label}</Label>
                }
            </div>
        );
    };

    // The date time for start date persisted in backend should always be the date user selected and time 00:00 and there should be no timezone conversion
    // Likewise end date should be the date user selected with time being 23:59:59.
    // Always persist this date & time in UTC format in db and display the same to user upon re-fetch
    private convertToLocal = (dtUTC: Date): Date => {

        const dtLocal = new Date();

        if (dtUTC !== null && dtUTC !== undefined && dtUTC.toString() !== 'Invalid Date' && dtUTC.toISOString().includes("Z")) {
         
            dtLocal.setFullYear(dtUTC.getUTCFullYear(), dtUTC.getUTCMonth(), dtUTC.getUTCDate());
            dtLocal.setHours(dtUTC.getUTCHours(), dtUTC.getUTCMinutes(), dtUTC.getUTCSeconds(), 0);
            return dtLocal;
        }

        return dtLocal;
    }

    private convertToUTC = (dtLocal: Date): Date => {

        const dtUTC = new Date();

        if (dtLocal !== null && dtLocal !== undefined && dtLocal.toString() !== 'Invalid Date') {
            // Set end date's time as 23:59:59 always
            const _hours = this.props.name.includes("startdate") ? 0 : 23;
            const _minutes = this.props.name.includes("startdate") ? 0 : 59;
            const _seconds = this.props.name.includes("startdate") ? 0 : 59;

           
            dtUTC.setUTCFullYear(dtLocal.getFullYear(), dtLocal.getMonth(), dtLocal.getDate());
            dtUTC.setUTCHours(_hours, _minutes, _seconds, 0);
            return dtUTC;
        }

        return dtUTC;
    }
}