import * as React from 'react';
import { ActivityDetailTitle } from './ActivityAttributeControls/ActivityDetailTitle';
import { TaxonomyMultiSelect } from '@commercialsalesandmarketing/pet-taxonomy';
import { TaxonomySingleSelect } from '@commercialsalesandmarketing/pet-taxonomy';
import { IActivityDetails } from '../../../../contracts/IActivityDetails';
import { IActivityDefinition } from '../../../../contracts/IActivityDefinition';
import { ActivityDefinitionService } from '../../../../services/ActivityDefinitionService';
import { AppSettings, Config } from '../../../../Configuration';
import { ActivityProperty } from './ActivityAttributeControls/ActivityProperty';
import { ActivityAlias } from './ActivityAttributeControls/ActivityAlias';
import { DefaultButton, PrimaryButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { adalApiFetch } from '../../../../adalConfig';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { AuthorizationService } from '@commercialsalesandmarketing/pet-common';
import { Permissions } from '../../../../contracts/Permissions';
import { QSGControl } from './ActivityAttributeControls/QSGControl';
import { ITaxonomyDetail } from '../../../../contracts/ITaxonomyDetails';
import { Panel, PanelType, IPanel } from 'office-ui-fabric-react/lib/Panel';
import { Stack, IStackStyles } from 'office-ui-fabric-react/lib/Stack';
import { isNullOrUndefined } from 'util';
import { IActivityActionAttribute } from '../../../../contracts/IActivityAction';
export class ActivityAttribute extends React.Component<{
    selectedActivity: IActivityDetails
    ActivityDef: IActivityDefinition
    handleHideActivityDetailsClicked: any
    saveActivity: IActivityActionAttribute,
    handleActivitySaveClick: (isLoading: boolean, activity: any) => void,
    handleActivityChanged: (activityId: string, isActivityChanged: boolean) => void,
    selectedNodeTaxonomies: ITaxonomyDetail[],
    roles: any[],
    isOpen: boolean,
    SetHelpPageId: (helpPageId: string) => void

},
    {
        items: any[], inprogress: boolean, activity: any, errorDetails: string,
        isFormValid: boolean, isAlertHidden: boolean, isError: boolean, saveCompleted: boolean, isRoleAvailable: boolean,
        TaxonomyLookup: any[],
        isHide: boolean,
        userAliasList: string[],
        canCreateorUpdateFolders: boolean
    }>{
    private _alertMessage: string;
    private _pannel = React.createRef<IPanel>();
    constructor(props: {
        selectedActivity: IActivityDetails,
        ActivityDef: IActivityDefinition,
        handleHideActivityDetailsClicked: any,
        saveActivity: IActivityActionAttribute,
        handleActivitySaveClick: (isLoading: boolean, activity: any) => void,
        handleActivityChanged: (activityId: string, isActivityChanged: boolean) => void,
        selectedNodeTaxonomies: [],
        roles: any[],
        isOpen: boolean,
        SetHelpPageId: (helpPageId: string) => void
    }) {
        super(props);
        this.state = {
            items: [],
            inprogress: true,
            errorDetails: '',
            isFormValid: false,
            activity: { activityId: ' ', title: ' ', id: '', parent_org_id: '', parent_node_id: '' },
            isAlertHidden: true,
            isError: false,
            saveCompleted: false,
            isRoleAvailable: false,
            TaxonomyLookup: [],
            isHide: !this.props.isOpen,
            userAliasList: [],
            canCreateorUpdateFolders: false
        }
        this._onDismiss = this._onDismiss.bind(this);
        this.handleTaxonomySelectionChanged = this.handleTaxonomySelectionChanged.bind(this);
        this.handleActivityPropertyValueChanged = this.handleActivityPropertyValueChanged.bind(this);
        this.handleHideActivityDetailsClicked = this.handleHideActivityDetailsClicked.bind(this);
        this.closeAlertDialog = this.closeAlertDialog.bind(this);
        this._saveActivityorFolder = this._saveActivityorFolder.bind(this);
        this._validateAttributes = this._validateAttributes.bind(this);
        this._isFormValid = this._isFormValid.bind(this);
        this.NameUpdated = this.NameUpdated.bind(this);
        this._onHideClick = this._onHideClick.bind(this);
        this._updateAliasList = this._updateAliasList.bind(this);
        this.setUserAliasList = this.setUserAliasList.bind(this);
        this.findParentTaxonomyDisplayName = this.findParentTaxonomyDisplayName.bind(this);
        this.clearChildSelectionIfParentIsEmpty = this.clearChildSelectionIfParentIsEmpty.bind(this);
    }

    public componentDidMount() {
        this.loadState(this.props);
    }

    public componentWillReceiveProps(nextProps: any) {
        if (this.state.isHide && nextProps.isOpen) {
            this.setState({ isHide: !nextProps.isOpen }, () => {
                // this.props.SetHelpPageId(Config.Help.HomePage);
            })
        }
        if (nextProps.isOpen && this.props.isOpen !== nextProps.isOpen) {
            this.props.SetHelpPageId(Config.Help.ActivityAttributes);
        }
        else if (this.props.isOpen !== nextProps.isOpen) {
            this.props.SetHelpPageId(Config.Help.HomePage);
        }
        this.loadState(nextProps);
    }

    public handleActivityPropertyValueChanged = (item?: any, propertyName?: string) => {
        const cystartdatename = "cylineitemstartdate";
        const cyenddatename = "cylineitemenddate";
        const fystartdatename = "fylineitemstartdate";
        const fyenddatename = "fylineitemenddate";
        let errorMessage: string = "";
        let validationResult: boolean = true;
        if (this.state.activity !== undefined) {
            if (propertyName === cystartdatename || propertyName === cyenddatename) {
                errorMessage = "Activity start date cannot be greater than the end date";
                if (propertyName === cystartdatename) {
                    validationResult = this.validateDates(String(item), this.state.activity[cyenddatename]);
                }
                else {
                    validationResult = this.validateDates(this.state.activity[cystartdatename], String(item));
                }
            }
            else if (propertyName === fystartdatename || propertyName === fyenddatename) {
                errorMessage = "Activity FY Line Item Start Date cannot be greater than the FY Line Item End Date";
                if (propertyName === fystartdatename) {
                    validationResult = this.validateDates(String(item), this.state.activity[fyenddatename]);
                }
                else {
                    validationResult = this.validateDates(this.state.activity[fystartdatename], String(item));
                }

            }
            if (validationResult) {
                let tempActivity = this.state.activity;
                tempActivity[propertyName] = String(item);
                this.setState({ activity: tempActivity });
            }
            else {
                this.setState({ isAlertHidden: false });
                this._alertMessage = errorMessage;
            }
        }
        this.setState({ inprogress: false });
        this._isFormValid();
    }
    public handleTaxonomySelectionChanged = (item?: any, taxonomyDefinitionId?: string, isPageLoad: boolean = false) => {
        const taxonomyName = this.getMemberNameFromTaxonomyDefId(taxonomyDefinitionId);
        // Set the right taxonomy on the parent (whose value is just updated by the user)
        if (this.state.activity !== null && this.state.activity !== undefined) {
            if (!isNullOrUndefined(item) && item.length > 0) {
                let tempActivity = this.state.activity;
                tempActivity[taxonomyName] = item.map(x => {
                    return {
                        taxonomy_id: x.key
                        , percentage: x.percentage
                        , comment: x.comment
                        , taxonomyDisplayValue: x.name
                    }
                });
                this.setState({ activity: tempActivity });
            }
            else {
                let tempActivity = this.state.activity;
                tempActivity[taxonomyName] = [];
                if (!isNullOrUndefined(item)) {
                    tempActivity[taxonomyName].push(
                        {
                            taxonomy_id: item.key
                            , taxonomyDisplayValue: item.text
                            , percentage: 100
                        });
                }
                else {
                    tempActivity = this.clearChildSelectionIfParentIsEmpty(taxonomyDefinitionId, tempActivity);
                }
                this.setState({ activity: tempActivity });
            }
        }

        this.setState({ inprogress: false });
        this._isFormValid(isPageLoad);
    }

    public clearChildSelectionIfParentIsEmpty(taxonomyDefinitionId: string, activity: any): any {
        this.state.items.forEach(member => {
            if (member.is_controlled && member.controlled_by === taxonomyDefinitionId) {
                const taxonomyName = this.getMemberNameFromTaxonomyDefId(member.associated_taxonomy_def_id);
                activity[taxonomyName] = [];
                this.clearChildSelectionIfParentIsEmpty(member.associated_taxonomy_def_id, activity);
            }
        });
        return activity;
    }

    public UpdateTaxonomyLookup = (item?: any, taxonomyDefinitionId?: string) => {
        if (item) {
            const taxonomyName = this.getMemberNameFromTaxonomyDefId(taxonomyDefinitionId);
            let lookup = this.state.TaxonomyLookup[taxonomyName];
            if (item.length > 0) {
                lookup = item.map(x => {
                    return {
                        taxonomy_id: x.key
                        , taxonomyDisplayValue: x.name
                    }
                });
            }
            else if (item.key) {
                lookup = [{
                    taxonomy_id: item.key,
                    taxonomyDisplayValue: item.text
                }];
            }
            this.state.TaxonomyLookup[taxonomyName] = lookup;
        }
    }

    public render(): JSX.Element {
        const _this = this;
        const locale = Config.locale;
        const alertMessage = _this._alertMessage;
        const stackStyles: IStackStyles = {
            root: {
                width: `45%`
            }

        };
        const dateStyles: IStackStyles = {
            root: {
                width: `47%`
            }
        };
        var evenCount = 0;
        return (
            <Panel isOpen={!this.state.isHide} isBlocking={false} type={PanelType.custom}
                customWidth={"60%"}
                isFooterAtBottom={true}
                onRenderFooterContent={this._onRenderFooterContent}
                onRenderHeader={this._onRenderHeader}
                componentRef={this._pannel}
                hasCloseButton={false}
                onDismiss={this._onDismiss}>

                <Stack horizontal >
                    <Stack.Item grow align="center" className={"absoluteleft show-hide-button"}>
                        <IconButton
                            iconProps={{ iconName: 'DoubleChevronRight8' }}
                            title="Hide Details" ariaLabel="Hide Activity Details" id="ActivityDetailsviewButton"
                            style={{ color: 'white' }}
                            onClick={this._onHideClick}
                        />
                    </Stack.Item>
                    {
                        this.state.isHide ?
                            <div />
                            :
                            <Stack.Item grow={20}>
                                <div >
                                    <Dialog
                                        hidden={_this.state.isAlertHidden}
                                        onDismiss={_this.closeAlertDialog}
                                        dialogContentProps={{
                                            type: DialogType.normal,
                                            title: 'Validation Error',
                                            subText: alertMessage
                                        }}
                                        modalProps={{
                                            isBlocking: true,
                                            containerClassName: 'ms-dialogMainOverride'
                                        }}
                                    >
                                        <DialogFooter>
                                            <PrimaryButton onClick={_this.closeAlertDialog} text="OK" />
                                        </DialogFooter>
                                    </Dialog>
                                    {!this.props.saveActivity.IsLoading ?
                                        <Dialog
                                            hidden={!this.state.saveCompleted}
                                            onDismiss={_this.closeAlertDialog}
                                            dialogContentProps={{
                                                type: DialogType.normal,
                                                title: this.state.isError ? 'Failure' : 'Success!',
                                                subText: this.state.isError ? this.state.errorDetails : `${(this.state.activity.is_folder !== null && this.state.activity.is_folder !== undefined && !this.state.activity.is_folder) ? "Activity" : "Category"} Saved Successfully!`
                                            }}
                                            modalProps={{
                                                isBlocking: true,
                                                containerClassName: 'ms-dialogMainOverride'
                                            }}
                                        >
                                            <DialogFooter>
                                                <PrimaryButton onClick={_this.closeAlertDialog} text="OK" />
                                            </DialogFooter>
                                        </Dialog> : ""
                                    }
                                    <div style={{ paddingLeft: "1vh" }}>
                                        {
                                            (_this.state.activity !== null
                                                && _this.state.activity !== undefined
                                                && (_this.state.activity.is_folder !== null && _this.state.activity.is_folder !== undefined)) ?
                                                (
                                                    <Stack id="activitydetail-odd" className="font-Size-12" horizontal wrap gap={'1 10'} style={{ border: "2px" }}>
                                                        <Stack.Item>
                                                            <Stack horizontal wrap gap={'1 10'} >
                                                                {
                                                                    _this.state.items.map((member) => {
                                                                        evenCount++
                                                                        if (member.name.includes('startdate')) {
                                                                            return <Stack horizontal horizontalAlign="space-between" gap={5} key={"dates"} className="dateItems" styles={dateStyles}>
                                                                                <Stack.Item grow key={"items" + member.name}  >
                                                                                    <ActivityProperty key={member.name} name={member.name}
                                                                                        label={member.labels[locale]}
                                                                                        isMandatory={member.is_mandatory && !_this.state.activity.is_folder}
                                                                                        attributeType={member.type}
                                                                                        onValueChanged={_this.handleActivityPropertyValueChanged}
                                                                                        propertyValue={_this.state.activity[member.name]}
                                                                                        informationText={member.information_text} />
                                                                                </Stack.Item>
                                                                                <Stack.Item className="endDateStyle" grow key={"iteme" + member.name} >
                                                                                    <ActivityProperty key={_this.state.items[evenCount].name}
                                                                                        name={_this.state.items[evenCount].name}
                                                                                        label={_this.state.items[evenCount].labels[locale]}
                                                                                        isMandatory={_this.state.items[evenCount].is_mandatory && !_this.state.activity.is_folder}
                                                                                        attributeType={_this.state.items[evenCount].type}
                                                                                        onValueChanged={_this.handleActivityPropertyValueChanged}
                                                                                        propertyValue={_this.state.activity[_this.state.items[evenCount].name]}
                                                                                        informationText={_this.state.items[evenCount].information_text} />
                                                                                </Stack.Item>
                                                                            </Stack>
                                                                        }
                                                                        if (member.name.includes('enddate')) {
                                                                            return <div key={"no-content" + member.name} />
                                                                        }
                                                                        let currentAttribute: any;
                                                                        const selectedTaxonomies: Array<{ key: string, name: string, percentage: number }> = [];
                                                                        let activityPropertyValue: any;
                                                                        if (_this.state.activity !== undefined) {
                                                                            currentAttribute = _this.state.activity[member.name];
                                                                            if (member.associated_taxonomy_def_id === null || member.associated_taxonomy_def_id === undefined) {
                                                                                activityPropertyValue = currentAttribute;
                                                                            }
                                                                            else if (currentAttribute !== null && currentAttribute !== undefined) {
                                                                                currentAttribute.forEach(x =>
                                                                                    selectedTaxonomies.push({ key: x.taxonomy_id, name: x.taxonomyDisplayValue, percentage: x.percentage }));
                                                                            }

                                                                        }

                                                                        const parentTaxonomyDefinitionId: string = member.is_controlled ? member.controlled_by : null;
                                                                        const parentTaxonomyIds = member.is_controlled ? _this.propAssignedValues(member.controlled_by) : [];

                                                                        let selectedParent: any = [];
                                                                        selectedParent.push({ ParentTaxonomyDefinitionID: parentTaxonomyDefinitionId, ParentTaxonomyIDs: parentTaxonomyIds });

                                                                        const singleSelectTaxonomyId = selectedTaxonomies !== undefined && selectedTaxonomies.length > 0 ? selectedTaxonomies[0].key : '';
                                                                        const selectedNodeFY: string = this.props.selectedNodeTaxonomies.findIndex(x => x.taxonomyDefinitionId === 'fy') >= 0
                                                                            ? this.props.selectedNodeTaxonomies.find(x => x.taxonomyDefinitionId === 'fy').taxonomy_id
                                                                            : "";

                                                                        return _this.isRelation(member.type) || _this.isIO(member.type)
                                                                            ? _this.isMultiSelect(member.selection_type)
                                                                                ?
                                                                                <Stack.Item key={member.name} grow styles={stackStyles}>
                                                                                    <TaxonomyMultiSelect label={member.labels[locale]}
                                                                                        isMandatory={member.is_mandatory && !_this.state.activity.is_folder}
                                                                                        associatedTaxonomyDefinitionId={member.associated_taxonomy_def_id}
                                                                                        selectedOptions={selectedTaxonomies}
                                                                                        key={member.name}
                                                                                        dropDownSelectionChanged={_this.handleTaxonomySelectionChanged}
                                                                                        selectedParentTaxonomies={selectedParent}
                                                                                        LookUpdate={this.UpdateTaxonomyLookup}
                                                                                        taxonomyType={member.type}
                                                                                        informationText={member.information_text}
                                                                                        selectedNodeFY={selectedNodeFY}
                                                                                        ParentTaxonomyDisplayName={_this.findParentTaxonomyDisplayName(parentTaxonomyDefinitionId)}
                                                                                        AppSettings={AppSettings}
                                                                                        isSelectAllRequired={member.is_selectall_required}
                                                                                    />
                                                                                </Stack.Item>
                                                                                : <Stack.Item key={member.name} grow styles={stackStyles}>
                                                                                    <TaxonomySingleSelect
                                                                                        label={member.labels[locale]}
                                                                                        isMandatory={member.is_mandatory && !_this.state.activity.is_folder}
                                                                                        associatedTaxonomyDefinitionId={member.associated_taxonomy_def_id}
                                                                                        key={member.name}
                                                                                        optionSelected={singleSelectTaxonomyId}
                                                                                        dropDownSelectionChanged={_this.handleTaxonomySelectionChanged}
                                                                                        isDisabled={member.is_inherited}
                                                                                        selectedParentTaxonomies={selectedParent}
                                                                                        LookUpdate={this.UpdateTaxonomyLookup}
                                                                                        informationText={member.information_text}
                                                                                        selectedNodeFY={selectedNodeFY}
                                                                                        AppSettings={AppSettings} />
                                                                                </Stack.Item>
                                                                            : _this.isAlias(member.selection_type)
                                                                                ? <Stack.Item key={member.name} grow styles={stackStyles}>
                                                                                    <ActivityAlias key={member.name} name={member.name} label={member.labels[locale]}
                                                                                        isMandatory={member.is_mandatory && !_this.state.activity.is_folder}
                                                                                        onValueChanged={_this.handleActivityPropertyValueChanged}
                                                                                        propertyValue={activityPropertyValue}
                                                                                        userlist={_this.setUserAliasList}
                                                                                        informationText={member.information_text}
                                                                                    />
                                                                                </Stack.Item>
                                                                                :
                                                                                <Stack.Item key={member.name} grow styles={stackStyles}>
                                                                                    <ActivityProperty key={member.name} name={member.name} label={member.labels[locale]}
                                                                                        isMandatory={member.is_mandatory && !_this.state.activity.is_folder}
                                                                                        attributeType={member.type}
                                                                                        onValueChanged={_this.handleActivityPropertyValueChanged}
                                                                                        propertyValue={activityPropertyValue}
                                                                                        informationText={member.information_text} />
                                                                                </Stack.Item>
                                                                    })
                                                                }
                                                            </Stack>
                                                        </Stack.Item>
                                                    </Stack>
                                                ) : <div />
                                        }

                                    </div>
                                </div>

                            </Stack.Item>
                    }

                </Stack>
            </Panel >
        )
    }

    private _onHideClick(event: any) {
        this.setState({ isHide: !this.state.isHide }, () => {
            this.props.handleHideActivityDetailsClicked();
        });
    }

    private _onDismiss(event: any) {
        this.setState({ isHide: true });
    }

    private _onRenderHeader = (): JSX.Element => {
        return (
            <div className="activityDetailTitle" >
                {
                    this.state.isHide ?
                        <div />
                        :
                        <ActivityDetailTitle
                            ActivityID={this.state.activity.activityId}
                            ActivityTitle={this.state.activity.title}
                            IsActivity={(this.state.activity.is_folder !== null && this.state.activity.is_folder !== undefined && !this.state.activity.is_folder)}
                            OnTitleModified={this.NameUpdated} />
                }
            </div>
        );
    }
    private NameUpdated(text: string): void {
        const activity = this.state.activity;
        activity.title = text;
        this.setState({ activity }, () => {
            this._isFormValid();
        });
    }
    private _onRenderFooterContent = (): JSX.Element => {
        return (
            <div>
                {
                    this.state.isHide ? <div /> :
                        <div className="detailBottomDiv">
                            {
                                (this.state.activity !== null
                                    && this.state.activity !== undefined
                                    && (this.state.activity.is_folder !== null && this.state.activity.is_folder !== undefined && !this.state.activity.is_folder)) ?
                                    <div className="qsg-button"><QSGControl ActivityDef={this.props.ActivityDef} activity={this.state.activity} TaxonomyLookup={this.state.TaxonomyLookup} /></div>
                                    : <div />
                            }
                            <div className="detailBottomSave">
                                <DefaultButton
                                    primary={true}
                                    text="Save"
                                    allowDisabledFocus={true}
                                    onClick={this._saveActivityorFolder}
                                    disabled={!this.state.isFormValid}
                                    className='side-activity-footer-button'
                                />
                            </div>
                        </div>
                }
            </div>
        );
    };
    // Get value assigned for this taxonomyDefinition for the selected activity (in state)
    private propAssignedValues(taxonomyDefinitionId: string): string[] {
        const results: string[] = [];
        let propMatch: any;
        if (this.state.activity !== undefined) {
            propMatch = this.state.activity[this.getMemberNameFromTaxonomyDefId(taxonomyDefinitionId)];
            if (propMatch === null || propMatch === undefined) {
                propMatch = [];
                propMatch.push(this.props.selectedNodeTaxonomies.find(x => x.taxonomyDefinitionId === taxonomyDefinitionId));
            }
            if (propMatch !== null && propMatch !== undefined && propMatch.length > 0) {
                propMatch.forEach(x => {
                    if (x !== null && x !== undefined && !isNullOrUndefined(x.taxonomy_id)) {
                        results.push(x.taxonomy_id);
                    }
                });
            }
        }
        return results;
    }


    private isRelation(type: string): boolean {
        return type === 'relation';
    }

    private isIO(type: string): boolean {
        return type === 'io';
    }

    private isMultiSelect(selection_type: string): boolean {
        return selection_type === 'multi'
    }

    private isAlias(selection_type: string): boolean {
        return selection_type === 'alias'
    }

    private _saveActivityorFolder(): void {
        this.props.handleActivitySaveClick(false, null);
        let isValid: boolean = true;
        let activityInstance: any | undefined;
        ({ activityInstance, isValid } = this._getActivityDetails(isValid));
        if (isValid) {
            this.setState({ isFormValid: true });
            const activityDetails: any = {
                activity: activityInstance,
                definitionId: Config.Actvity.DefinitionId,
                sourceWorkspaceId: activityInstance.sourceWorkspaceId
            }
            const isSave: boolean = activityInstance.id === '' ? true : false
            if (isSave) {
                this._createActivityorFolder(activityDetails);
            }
            else {
                this._updateActivityorFolder(activityDetails);
            }
            this._updateAliasList(activityDetails.activity.properties.activityowner)
        }
        else {
            this.setState({ isFormValid: false })
        }
        this.props.handleActivitySaveClick(true, null);
    }

    private _getActivityDetails(isValid: boolean) {
        const activityInstance: any = {
            id: this.state.activity.id === 'newactivity' ? '' : this.state.activity.id,
            is_folder: this.state.activity.is_folder,
            parent_node_id: this.state.activity.parent_node_id,
            parent_org_id: this.state.activity.parent_org_id,
            sourceWorkspaceId: this.state.activity.sourceWorkspaceId,
            properties: {
                name: this.state.activity.title
            },
            Relations: {}
        };
        const relation: string = 'relation';
        const io: string = 'io';
        this.state.items.forEach(member => {

            if (this.state.activity[member.name] !== undefined) {
                const attribute: any = this.state.activity[member.name];
                if (member.type !== relation && member.type !== io) {
                    if (isNullOrUndefined(attribute) || attribute === '' || attribute.length < 1) {
                        isValid = member.is_mandatory && !this.state.activity.is_folder ? false : isValid;
                        activityInstance.properties[member.name] = null;
                    }
                    else {
                        activityInstance.properties[member.name] = attribute;
                    }
                }
                else if (attribute.length < 1 || !attribute[0] ||
                    attribute[0].taxonomy_id === undefined ||
                    attribute[0].taxonomy_id === null ||
                    attribute[0].taxonomy_id === '') {
                    isValid = member.is_mandatory && !this.state.activity.is_folder ? false : isValid;
                }
                else if (member.type === relation || member.type === io) {

                    const properties: any[] = [];
                    attribute.forEach(rel => {
                        const property: any = {
                            taxonomy_id: rel.taxonomy_id
                        };
                        if (rel.percentage !== undefined) {
                            property.percentage = rel.percentage;
                        }
                        properties.push(property);
                    });
                    activityInstance.Relations[member.name] = properties;
                }
            }
            else if (member.is_mandatory && !this.state.activity.is_folder) {
                isValid = false;
            }
        });

        return { activityInstance, isValid };
    }

    private _createActivityorFolder(activityDetails: any) {
        const opts = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: activityDetails.activity.is_folder ? JSON.stringify(activityDetails.activity) : JSON.stringify(activityDetails)
        };
        const url: any = activityDetails.activity.is_folder ? `${AppSettings.CategoryUrl()}` : `${AppSettings.ActivityBaseUrl()}`
        adalApiFetch(fetch, url, opts)
            .then(response => response.json())
            .then(data => {
                if (data.id !== undefined) {
                    this.props.handleActivitySaveClick(true, data);
                    this.setState({
                        saveCompleted: true
                    });
                    return;
                }
                else {
                    this._setError(data.Message === undefined ? data : data.Message);
                }
            })
            .catch((error) => {
                this._setError(error.message);
            });
    }
    private _setError(message: any) {
        this.props.handleActivitySaveClick(true, null);

        this.setState({
            isError: true,
            errorDetails: message
        });
    }

    private _updateActivityorFolder(activityDetails: any) {
        const opts = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: activityDetails.activity.is_folder ? JSON.stringify(activityDetails.activity) : JSON.stringify(activityDetails)
        };
        const url: any = activityDetails.activity.is_folder ? `${AppSettings.CategoryUrl()}/${activityDetails.activity.id}`
            : `${AppSettings.ActivityBaseUrl()}/${activityDetails.activity.id}`

        adalApiFetch(fetch, url, opts)
            .then(response => response.json())
            .then(data => {
                if (data.id !== undefined) {
                    this.props.handleActivitySaveClick(true, data);
                    this.setState({
                        saveCompleted: true
                    });
                    return;
                }
                else {
                    this._setError(data.Message === undefined ? data : data.Message);
                }
            })
            .catch((error) => {
                this._setError(error.message);
            });
    }


    private _updateAliasList(alias: string) {
        if (alias !== undefined && alias !== "") {
            if (this.state.userAliasList && this.state.userAliasList.length > 0 && this.state.userAliasList.includes(alias)) {
                return;
            }
            const opts = {
                method: "POST",
                headers: { "Content-Type": "application/json" }
            };
            const url: any = `${AppSettings.TaxonomyServiceUrl()}/${Config.Taxonomy.UpdateownerAlias}/?alias=${alias}`;

            adalApiFetch(fetch, url, opts)
                .then(response => response.json())
                .then(data => {
                    if (data !== undefined && data === true) {

                        return;
                    }
                })
                .catch((error) => {
                    //this._setError(error.message);
                });
        }
    }
    public setUserAliasList = (item?: string[]) => {
        this.setState(
            {
                userAliasList: item
            }
        );
    }



    private _validateAttributes(): boolean {
        if ((!this.state.isRoleAvailable && !this.state.activity.is_folder)
            || (!this.state.canCreateorUpdateFolders && this.state.activity.is_folder)) {
            return false;
        }
        else if (this.state.activity.title === '') {
            return false;
        }
        else if (this.state.activity.is_folder) {
            return true;
        }
        else {
            let isValid: boolean = true;
            const relation: string = 'relation';
            const mandatoryAttributes: any[] = this.state.items.filter(x => x.is_mandatory);
            mandatoryAttributes.forEach(member => {
                if (this.state.activity[member.name] !== undefined) {
                    const attribute: any = this.state.activity[member.name];
                    if (member.type !== relation) {
                        if (isNullOrUndefined(attribute) || attribute === '' || attribute.length < 1) {
                            isValid = false;
                        }
                    }
                    else if (attribute.length < 1 || !attribute[0] ||
                        attribute[0].taxonomy_id === undefined ||
                        attribute[0].taxonomy_id === null ||
                        attribute[0].taxonomy_id === '') {
                        isValid = false;
                    }
                }
                else {
                    isValid = false;
                }

            });
            return isValid;
        }
    }
    private _isFormValid(isPageLoad: boolean = false): void {
        this.setState({
            isError: false,
            errorDetails: '',
            saveCompleted: false
        });
        const isValid: boolean = this._validateAttributes();
        this.setState({ isFormValid: isValid });

        if (!isPageLoad) {
            this.props.handleActivityChanged(this.state.activity, true);
        }
    }
    private handleHideActivityDetailsClicked() {
        this.props.handleHideActivityDetailsClicked();
    }

    private closeAlertDialog() {
        this._alertMessage = '';
        this.setState({ isAlertHidden: true });
        this.setState({ saveCompleted: false });
    }

    private getMemberNameFromTaxonomyDefId(taxonomyDefinitionId: string) {
        if (taxonomyDefinitionId.indexOf(".") > 0) {
            return taxonomyDefinitionId.split(".")[0];
        }
        else { return taxonomyDefinitionId; }
    }

    private validateDates(startDatePropValue: string, endDatePropValue: string): boolean {
        if (startDatePropValue !== null && startDatePropValue !== undefined
            && endDatePropValue !== null && endDatePropValue !== undefined) {
            const startDate = new Date(startDatePropValue);
            const endDate = new Date(endDatePropValue);
            if (startDate > endDate) {
                return false;
            }
        }
        return true;
    }

    private loadState(nextProps: any) {
        const _this = this;
        if (!isNullOrUndefined(nextProps.selectedActivity) && _this.state.activity.id !== nextProps.selectedActivity.id) {
            const authorizationService = new AuthorizationService(AppSettings);
            const isRoleAvailable = authorizationService.IsRolePresent(nextProps.roles, Permissions.CreateUpdateActivity);
            _this.setState({ isRoleAvailable });
            const canCreateorUpdateFolders = authorizationService.IsRolePresent(nextProps.roles, Permissions.SubFolders);
            _this.setState({ canCreateorUpdateFolders });
            const activityDefinitionService = new ActivityDefinitionService();
            const _detailAttributes = activityDefinitionService.MapActivityDefitionForDetailPanel(nextProps.ActivityDef);
            _this.setState({ items: _detailAttributes }, () => {
                const activity: any = this.state.activity;
                if (nextProps.selectedActivity != null) {
                    activity.title = nextProps.selectedActivity.name;

                    activity.is_folder = nextProps.selectedActivity.is_folder;
                    activity.id = nextProps.selectedActivity.id;
                    activity.activityId = nextProps.selectedActivity.activityid;
                    activity.parent_node_id = nextProps.selectedActivity.parent_node_id;
                    activity.parent_org_id = nextProps.selectedActivity.parent_org_id;
                    activity.sourceWorkspaceId = nextProps.selectedActivity.sourceWorkspaceId;
                    _this.state.items.forEach(x => {
                        if (x.associated_taxonomy_def_id === null) {
                            const value = nextProps.selectedActivity[x.name];
                            activity[x.name] = value;
                        }
                        else {

                            activity[x.name] = nextProps.selectedActivity[x.name] !== null && nextProps.selectedActivity[x.name] !== undefined ?
                                _this.state.activity[x.name] = nextProps.selectedActivity[x.name].map(attr => {
                                    return { taxonomy_id: attr.taxonomy_id, percentage: attr.percentage, comment: attr.comment }
                                })
                                : [];
                        }
                    });
                }
                _this.setState({ inprogress: false });
                _this._isFormValid(true);

            });
        }
    }
    private findParentTaxonomyDisplayName(associatedTaxonomyDefinitionParentId: string): string {
        const locale = Config.locale;
        for (const item of this.state.items) {
            if (item.associated_taxonomy_def_id === associatedTaxonomyDefinitionParentId) {
                return item.labels[locale];
            }
        }
        return '';
    }
}