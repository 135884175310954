import * as React from 'react';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { ActivityAction } from '../../../../contracts/ActivityAction';

export class PasteButton extends React.Component<{  
   OnActivityActionButtonClick: (action: ActivityAction) => void,
   disabled: boolean
},
{
  checked: boolean;
}> {
  public constructor(props: {
    OnActivityActionButtonClick: (action: ActivityAction) => void,
    disabled: false
  }) {
    super(props);
    this._onPasteClicked = this._onPasteClicked.bind(this);
    this.state = {
      checked: false
    }
  }

  public render(): JSX.Element {
    const {  checked } = this.state;

    return (
      <div className="activityActionButtonContainer">
        <TooltipHost
          className="actionButtontoolTip"
          calloutProps={{ gapSpace: -10 }}
          hostClassName="selectedTaxonomyValueTooltip"
          tooltipProps={{
            onRenderContent: () => {
              return (
                <div>
                  <ul style={{ margin: 0, padding: 0 }}>
                    <li><span>Paste</span></li>
                  </ul>
                </div>
              );
            }
          }}
          delay={TooltipDelay.zero}
          directionalHint={DirectionalHint.topCenter}
        >
          <div style={{ display: 'flex' }}>
            <ActionButton
              data-automation-id="test"
              iconProps={{ iconName: 'Paste' }}
              allowDisabledFocus={true}
              checked={checked}
              className="activityActionButton"
              disabled={this.props.disabled}
              onClick={this._onPasteClicked} >Paste</ActionButton>
          </div>
        </TooltipHost>
      </div>
    );
  }

  private _onPasteClicked(event): void {
    this.props.OnActivityActionButtonClick(ActivityAction.Paste);
  }
}