import * as React from 'react';
import { IUserDetails } from '../contracts/IUserDetails';
import { IUserOnboardRequest } from '../contracts/IUserOnboardRequest';
import { adalApiFetch, getToken } from '../adalConfig';
import { AppSettings } from '../Configuration';
import { Main } from './Main';
import { AppInsights } from "applicationinsights-js";
import { isNullOrUndefined } from 'util';
import { LoginFailure } from './LoginFailure';
import { IActivityNotification } from '../contracts/IActivityNotification';

export class AuthorizedUserDetails extends React.Component<
    {
        user: IUserDetails,
        configUrl: string,
        notification:(ActivityNotification:IActivityNotification) => void,
        sethelppageid:(HelpPageId:string)=>void
    },
    {
        isAuthorizedUser: boolean,
        isError: boolean,
        isLoading: boolean,
        errorDetails: string,
        loggedinUser: IUserDetails,
        isOnboardingReqExist: boolean,
        ExsitingOnboardingRequest: IUserOnboardRequest

    }

    >
{
    constructor(props) {
        super(props);
        this.state =
            {
                isAuthorizedUser: false,
                isError: false,
                isLoading: true,
                errorDetails: '',
                loggedinUser: { id: '', upn: '', name: '', CorrelationId: '', user_paths: [] },
                isOnboardingReqExist: false,
                ExsitingOnboardingRequest: { user_id: '', status: '', created_by: '', modified_by: '', workspace: '', role: 0, reason: '', notes: '', admin_alias: '', modified_date: '', modified_time: '', pounmap_notification: false, user_alias: '', workspaces: [], users: [], cc_aliases: '', id: 0, role_name: '' },
            }
        this.validateAndFetchCurrentUserDetails = this.validateAndFetchCurrentUserDetails.bind(this);
    }

    componentDidMount() {
        if (AppSettings.ConfigData() === undefined) {
            fetch(this.props.configUrl)
                .then(response => response.json())
                .then(data => {
                    AppSettings.updateConfig(data);
                    if (AppInsights !== undefined) {
                        AppInsights.downloadAndSetup({ instrumentationKey: AppSettings.AIInstrumentationKey() });
                        AppInsights.trackPageView(
                            "Authorization",
                            null,
                            { env: AppSettings.Env() }
                        );
                    }
                    if (this.props.user) {
                        this.setUserState();
                    } else {
                        this.validateAndFetchCurrentUserDetails();
                    }

                });
        }
        else {
            if (this.props.user) {
                this.setUserState();
            } else {
                this.validateAndFetchCurrentUserDetails();
            }
        }
    }

    public setUserState() {
        this.setState({
            isAuthorizedUser: true,
            isLoading: false,
            isError: false,
            errorDetails: "",
            loggedinUser: this.props.user
        });
    }

    public validateAndFetchCurrentUserDetails() {
        const token = getToken();
        if (token === "" || token === undefined) {
            this.setState({
                isAuthorizedUser: false,
                isLoading: false
            });
        } else {
            adalApiFetch(fetch, AppSettings.AuthorizationUrl(), {})
                .then(response => response.json())
                .then(data => {
                    if (isNullOrUndefined(data.ExceptionMessage) || data.ExceptionMessage === "") {
                        const _user = data as IUserDetails;
                        if (_user.id === "" || _user.id === undefined) {
                            const onboardingReq = data as IUserOnboardRequest;
                            if (isNullOrUndefined(onboardingReq.user_id) || onboardingReq.user_id === "") {
                                const res = data as string;
                                if (res === "NewRequest") {
                                    this.setState({
                                        isError: true,
                                        isLoading: false,
                                        isAuthorizedUser: false,
                                        isOnboardingReqExist: false
                                    });
                                }
                            }
                            else {
                                this.setState({
                                    isError: true,
                                    isLoading: false,
                                    isAuthorizedUser: false,
                                    errorDetails: "Onboarding Request pending.",
                                    isOnboardingReqExist: true,
                                    ExsitingOnboardingRequest: onboardingReq
                                });

                            }

                        } else {
                            this.setState({
                                isAuthorizedUser: true,
                                isLoading: false,
                                isError: false,
                                errorDetails: "",
                                loggedinUser: _user
                            });

                        }
                    }
                    else {
                        this.setState({
                            isError: false,
                            isLoading: false,
                            isAuthorizedUser: false,
                            errorDetails: data.ExceptionMessage
                        });
                    }

                })
                .catch((error) => {
                    this.setState({
                        isError: false,
                        isLoading: false,
                        isAuthorizedUser: false,
                        errorDetails: error.message
                    });
                });
        }
    }

    public render(): JSX.Element {
        if (this.props.user && this.state.isLoading) {
            return (null);
        }
        if (this.state.isLoading) {
            return <LoginFailure />
        }
        else {
            if (!this.state.isAuthorizedUser && !this.state.isError) {
                return <LoginFailure displayText={'Authentication failed.Please contact administrator.'} />
            }
            else {
                return <Main user={this.state.loggedinUser} notification={this.props.notification} sethelppageid={this.props.sethelppageid} />
            }

        }
    }
}