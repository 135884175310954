import 'office-ui-fabric-core/dist/css/fabric.min.css';
import * as React from 'react';
import { IUserDetails } from '../contracts/IUserDetails';


export interface ILoginFailureProps {displayText : string; FailureMessage : string; IsFailure : boolean;user : IUserDetails}

export class LoginFailure extends React.Component<ILoginFailureProps> {

    public static defaultProps : Partial<ILoginFailureProps> = {
        displayText : 'Authenticating ...',
        FailureMessage : '',
        IsFailure : false,
        user : {id:'',upn:'',name:'',CorrelationId:'',user_paths : []},
    };
    
    public render(): JSX.Element{        
         return (
            <div className="ms-Grid full-height"  dir="ltr">
                <div className="ms-Grid-row full-height" >                   
                   <div className="displayDivWithOutSideBar">
                        <div className="displayText">{this.props.displayText}</div>                        
                        <div className = {this.props.IsFailure ? "FailureMessageTextShow" : "FailureMessageTextHide"} >{this.props.FailureMessage}</div>
                    
                    </div>
                </div>
            </div>
             
         );
     }
  } 