import * as React from 'react';
import { adalApiFetch } from '../../adalConfig';
import { AppSettings, Config } from '../../Configuration';
import { IActivityDefinition } from '../../contracts/IActivityDefinition';
import { AdvancedSearchFilterItem } from '../DetailControls/AdvancedSearchFilterItem';
import { TaxonomyService } from '@commercialsalesandmarketing/pet-taxonomy';
import { Callout, DirectionalHint } from 'office-ui-fabric-react/lib/Callout';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { ActionButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { IActivityDeifinitionMember } from '../../contracts/IActivityDefinitionMember';
import { ITaxonomyDetail } from '../../contracts/ITaxonomyDetails';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { IActivitySearch, SearchType } from '../../contracts/IActivitySearch';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { isNullOrUndefined } from 'util';
import { SearchExportButton } from './AdvancedSearchControls/SearchExportButton'
import { ActivityListService } from '../../services/ActivityListService';

export class AdvancedSearch extends React.Component<{
	WorkSpacesFullList: any[],
	ChildNodeMap: Map<string, string[]>,
	SelectedNodeId: any,
	SelectedNode: any,
	SearchPageView: SearchType,
	OnSearchStarted: () => void,
	OnSearchCompleted: (activities: any) => void,
	OnBackToHome: () => void
}, {
	ActivityDef: IActivityDefinition,
	ActivityDefMembers: IActivityDeifinitionMember[],
	AllTaxonomies: Array<{ associatedTaxonomyDefinitionId: string, taxonomies: ITaxonomyDetail[] }>,
	SearchFilters: Array<{ filterId: number, activityDefMember: { key: string, text: any }, selectedFilterValues: string[], selectedOperator: string }>,
	IsLoading: boolean,
	IsFilterPanelVisible: boolean,
	IsWorkspaceSelected: boolean,
	OnSearchCompleted: boolean,
	SearchActivitiesResult: IActivitySearch,
	ExportSearchFilters: any,
	ExportColumns: IActivityDeifinitionMember[],
	ExportData: any[],
	ExportWorkspaceName: string,
	SearchedFilters: Array<{ activityDefMember: { key: string, text: any }, selectedFilterValues: Array<{ value: string, operator: string }> }>,
	additionalOptions: any,
	ActivityDefinitionsForSearchedWorkspaces: Array<{ workspaceID: string, activityDef: IActivityDefinition }>,
	isError: boolean,
	errorDetails: string,
}
	> {
	constructor(props: {
		WorkSpacesFullList: any[],
		ChildNodeMap: Map<string, string[]>,
		SelectedNodeId: any,
		SelectedNode: any,
		SearchPageView: SearchType,
		OnSearchStarted: () => void,
		OnSearchCompleted: (activities: any) => void,
		OnBackToHome: () => void,
	}) {
		super(props);
		this.state = {
			ActivityDef: null,
			ActivityDefMembers: [],
			AllTaxonomies: [],
			SearchFilters: [],
			IsLoading: false,
			IsFilterPanelVisible: false,
			IsWorkspaceSelected: true,
			OnSearchCompleted: false,
			SearchActivitiesResult: null,
			ExportSearchFilters: {},
			SearchedFilters: [],
			additionalOptions: {},
			ExportColumns: [],
			ExportData: [],
			ExportWorkspaceName: "",
			ActivityDefinitionsForSearchedWorkspaces: [],
			isError: false,
			errorDetails: ""
		};

		this._onBackToHomeBtnClick = this._onBackToHomeBtnClick.bind(this);
		this._onSearch = this._onSearch.bind(this);
		this._onFilterPanelOpenClick = this._onFilterPanelOpenClick.bind(this);
		this._onAddNewFilter = this._onAddNewFilter.bind(this);
		this._onActivityDefChanged = this._onActivityDefChanged.bind(this);
		this._onFilterValueChanged = this._onFilterValueChanged.bind(this);
		this._getWorkspaceName = this._getWorkspaceName.bind(this);
		this._searchActivities = this._searchActivities.bind(this);
		this._onCancelClick = this._onCancelClick.bind(this);
		this._getNodeTaxonomiesForSearchedWorkspaces = this._getNodeTaxonomiesForSearchedWorkspaces.bind(this);
		this._fetchActivities = this._fetchActivities.bind(this);
	}

	private _menuButtonElement: HTMLElement | null;

	public componentWillReceiveProps(nextProps: any) {
		// if (nextProps.SearchData.length !== this.props.SearchData.length) {
		// 	this._onSearch();
		// }
	}

	public componentDidMount() {
		const locale = Config.locale;
		this.setState({ IsLoading: true });
		let opts = {}; let url = '';
		const isActivitiesSearch: boolean = this.props.SearchPageView === SearchType.Activity;
		let fyid = ""
		if (this.props.SearchPageView === SearchType.Activity) {
			opts =
				{
					method: "post",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify('')
				}
			if(!isNullOrUndefined(this.props.SelectedNode) &&  !isNullOrUndefined(this.props.SelectedNode.data)){
				fyid = this.props.SelectedNode.data.relation['FY'];
			}	
			url = `${AppSettings.ActivityDefinitionUrl()}/${Config.Actvity.DefinitionId}/${fyid}`;
		}
		
		adalApiFetch(fetch, url, opts)
			.then(response => response.json())
			.then(data => {
				const _activityDef = data;
				_activityDef.fyid = fyid;
				const exportColumns = _activityDef.members.filter(x => x.is_searchable);
				let _activityDefMembers = _activityDef.members.filter(x => x.is_searchable).map(x => {
					return {
						associated_taxonomy_def_id: isNullOrUndefined(x.associated_taxonomy_def_id) && x.type === 'relation' ? x.name : x.associated_taxonomy_def_id, 
						labels: x.labels[locale], name: x.name, selection_type: x.selection_type, minWidth: x.minWidth,
						is_detail_title_view: x.is_detail_title_view, is_detail_view: x.is_detail_view,
						is_mandatory: x.is_mandatory, is_summary_view: x.is_summary_view, maxWidth: x.maxWidth, type: x.type, is_searchable: x.is_searchable,
						is_checked: x.is_checked, sql_name: x.sql_name
					};
				});
				if (isActivitiesSearch) {
					_activityDefMembers.push({
						associated_taxonomy_def_id: 'Workspace', labels: 'Workspace', name: 'Workspace', selection_type: 'multi', minWidth: 0, is_detail_title_view: false, is_detail_view: true,
						is_mandatory: true, is_summary_view: false, maxWidth: 10, type: 'relation', is_searchable: true, is_checked: true
					});
				}

				this.setState({
					ActivityDef: _activityDef,
					ActivityDefMembers: _activityDefMembers.sort((a, b) => a.name.localeCompare(b.name)),
					ExportColumns: exportColumns,
					IsLoading: false
				},
					() => {
						const _searchFilters = this.state.SearchFilters;
						const _allTaxonomies = this.state.AllTaxonomies;
						if (isActivitiesSearch) {
							_allTaxonomies.push({ associatedTaxonomyDefinitionId: 'Workspace', taxonomies: [] });
							
							let workspaces:any[]=[];
							
							for (const workspace of this.props.WorkSpacesFullList) {
								if (workspace.data.relation['FY'] === this.props.SelectedNode.data.relation['FY']) {
									workspaces.push(workspace);
								}
							}

							let additionalOptions: any = { workspaces, childNodeMap: this.props.ChildNodeMap };

							this.setState({
								additionalOptions
							});


							let _selectedFilterValues: string[] = [];
							_selectedFilterValues.push(this.props.SelectedNodeId);
							_searchFilters.push({
								filterId: 1,
								activityDefMember: { key: 'Workspace', text: 'Workspace' },
								selectedFilterValues: _selectedFilterValues,
								selectedOperator: ''
							});
							this.setState({ SearchFilters: _searchFilters });
						}

					});
			})
			.catch((ex) => {
				this.setState({
					isError: true,
					errorDetails: ex.message
				});
			});
	}

	public render() {
		const _this = this;
		const searchResultsCount = !isNullOrUndefined(this.state.SearchActivitiesResult) ? this.state.SearchActivitiesResult.Activities.length : 0;
		const workspaceFilter = this.state.SearchFilters.find(x => x.activityDefMember !== null && x.activityDefMember.key === 'Workspace');
		let workspacesSelected: boolean = false;
		if (workspaceFilter !== undefined && workspaceFilter !== null) {
			workspacesSelected = workspaceFilter.selectedFilterValues !== undefined && workspaceFilter.selectedFilterValues !== null && workspaceFilter.selectedFilterValues.length >= 1;
		}
		return (
			<Stack style={{ paddingLeft: 20 }}>
				<Stack.Item>
					<h2 className="ms-fontSize-l" style={{ display: "flex", marginTop: 0, marginBottom: 0, fontWeight: 400 }}>Search</h2>
				</Stack.Item>
				<br />
				<Stack.Item>
					<Stack horizontal horizontalAlign="space-between">
						<Stack.Item styles={{ root: { minWidth: "50%", position: "relative" } }}>
							<div className="advancedSearchPanelCollapse">
								<Link className="font-Size-12" onClick={this._onFilterPanelOpenClick} >Click here for advanced search filters</Link>
								<ActionButton
									data-automation-id="test"
									iconProps={{ iconName: this.state.IsFilterPanelVisible ? 'ChevronUp' : 'ChevronDown' }}
									allowDisabledFocus={true}
									onClick={this._onFilterPanelOpenClick}
									className="icon-activityAdvancedSearch"
									ariaLabel="Expand/Collapse Search Panel" />
							</div>
						</Stack.Item>

						<Stack.Item>
							<Stack horizontal grow>
								<Stack.Item>
									{
										this.state.OnSearchCompleted
											&& !isNullOrUndefined(this.state.SearchActivitiesResult)
											&& this.state.SearchActivitiesResult.Activities.length > 0
											&& this.props.SearchPageView === SearchType.Activity ?
											<SearchExportButton SearchFilters={this.state.ExportSearchFilters} />
											: ''
									}

								</Stack.Item>
								<Stack.Item>
									<ActionButton
										data-automation-id="test"
										iconProps={{ iconName: 'ReturnToSession' }}
										allowDisabledFocus={true}
										onClick={this._onBackToHomeBtnClick}
										className="advancedSearchPanelButtons" >Go to Home</ActionButton></Stack.Item></Stack>

						</Stack.Item>
					</Stack>
				</Stack.Item>
				<Stack.Item >
					{
						<div ref={menuButton => (this._menuButtonElement = menuButton)} aria-live='assertive' aria-atomic={true}>
							{
								_this.state.IsFilterPanelVisible ?
									<Stack verticalFill grow aria-label='Advance Search Filters Popup Opened' role="dialog">
										<Stack.Item>
											<Callout style={{ minHeight: "30vh", minWidth: "50vh", paddingLeft: "10px", paddingRight: "10px", overflow: 'hidden' }}
												className="ms-CalloutExample-callout"
												gapSpace={0}
												target={this._menuButtonElement}
												isBeakVisible={false}
												directionalHint={DirectionalHint.bottomLeftEdge}
												calloutMaxHeight={350}
											>
												<Stack >
													<Stack.Item>
														<ActionButton
															data-automation-id="test"
															styles={{
																root: { float: "right", paddingRight: '0px' }
															}}
															className="advancedSearchPanelButtons font-Color-Blue"
															iconProps={{ iconName: 'Add' }}
															allowDisabledFocus={true}
															onClick={_this._onAddNewFilter} >Add Filter</ActionButton>
													</Stack.Item>

													<Stack.Item>
														{
															_this.state.SearchFilters.map(filter => {
																const _taxonomyValuesForSelection = filter.activityDefMember !== null && filter.activityDefMember !== undefined
																	&& _this.state.ActivityDefMembers !== null && _this.state.ActivityDefMembers !== undefined
																	&& _this.state.ActivityDefMembers.findIndex(x => x.name === filter.activityDefMember.key) >= 0
																	&& _this.state.AllTaxonomies.findIndex(t => t.associatedTaxonomyDefinitionId === _this.state.ActivityDefMembers.find(x => x.name === filter.activityDefMember.key).associated_taxonomy_def_id) >= 0
																	? _this.state.AllTaxonomies.find(t => t.associatedTaxonomyDefinitionId === _this.state.ActivityDefMembers.find(x => x.name === filter.activityDefMember.key).associated_taxonomy_def_id).taxonomies.map(x => {
																		return { key: x.taxonomy_id, text: x.taxonomyDisplayValue }
																	})
																	: null;

																let _activityDefMembers = _this.state.ActivityDefMembers !== undefined && _this.state.ActivityDefMembers !== null
																	? _this.state.ActivityDefMembers.map(x => { return { key: x.name, text: x.labels } }) : [];

																const _selectedActivityDefMemberType = filter.activityDefMember !== undefined && filter.activityDefMember !== null ?
																	_this.state.ActivityDefMembers.find(x => x.name === filter.activityDefMember.key).type : 'relation';

																const isWorkspaceFilter
																	= filter.activityDefMember !== undefined && filter.activityDefMember !== null && (filter.activityDefMember.key === 'Workspace'
																		|| filter.activityDefMember.key === 'IOLandingCountry' || filter.activityDefMember.key === 'FiscalYear')
																		? true : false;

																return <AdvancedSearchFilterItem key={"FilterNo" + filter.filterId} FilterId={filter.filterId}
																	AttributeType={_selectedActivityDefMemberType} IsRequired={isWorkspaceFilter}
																	ActivityDefMembers={_activityDefMembers} SelectedActivityDefMember={filter.activityDefMember}
																	OnActivityDefSelectionChanged={_this._onActivityDefChanged}
																	AvailableFilterValues={_taxonomyValuesForSelection} SelectedFilterValues={filter.selectedFilterValues}
																	OnFilterValueSelectionChanged={_this._onFilterValueChanged}
																	OnDeleteFilter={this._onDeleteFilter}
																	selectedOperator={isNullOrUndefined(filter.selectedOperator) || filter.selectedOperator === '' ?
																		'IN'
																		: filter.selectedOperator}
																	searchType={this.props.SearchPageView}
																	additionalOptions={this.state.additionalOptions} />
															})
														}
													</Stack.Item>

													<Stack.Item align="end">
														<div style={{ display: "flex", float: "right", paddingTop: "50px" }}>
															<ActionButton style={{ paddingBottom: "12px" }}
																data-automation-id="test"
																allowDisabledFocus={true}
																onClick={_this._onCancelClick}
																className="advancedSearchPanelButtons font-Color-Blue" >Cancel</ActionButton>
															<ActionButton
																data-automation-id="test"
																styles={{
																	icon: { backgroundColor: "rgb(16, 110, 190)", color: "white !important", zoom: "90% !important" },
																	root: { fontSize: "12px", fontWeight: "400" }
																}}
																iconProps={{ iconName: 'Search' }}
																allowDisabledFocus={true}
																disabled={!workspacesSelected && this.props.SearchPageView === SearchType.Activity}
																onClick={_this._onSearch}
																className="uploadBulkFile" >Search</ActionButton>
														</div>
													</Stack.Item>
												</Stack>
											</Callout>
										</Stack.Item>
									</Stack> : <div />
							} </div>
					}
				</Stack.Item>

				<br />

				<Stack.Item>
					{
						this.state.OnSearchCompleted && !isNullOrUndefined(this.state.ExportSearchFilters) && this.state.ExportSearchFilters.filters && this.state.ExportSearchFilters.filters.length > 0 ?
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<Label>Showing search results for Activities</Label>
								<Stack horizontal gap={5} padding={10}>
									<Stack.Item>
										<Label className="font-Size-12">Search Filters : </Label>
									</Stack.Item>
									<Stack.Item>
										{
											_this.state.SearchedFilters.map(filter => {
												const activityDefMemberMatch = this.state.ActivityDefMembers.find(x => !isNullOrUndefined(filter.activityDefMember) && x.name === filter.activityDefMember.key);
												const filterName = !isNullOrUndefined(filter.activityDefMember) && filter.activityDefMember.key === 'Workspace' ? filter.activityDefMember.key : (activityDefMemberMatch != null ? activityDefMemberMatch.labels : '');
												return (activityDefMemberMatch !== null && filter.activityDefMember !== null) ?
													<div key={"fiterText" + filter.activityDefMember.key} className="selectedTaxonomyDiv">
														<span key={"selectedTxnText" + filter.activityDefMember.key} title={filterName}
															className="font-Size-12"
															style={{ paddingTop: "4px", paddingRight: "3px", alignContent: "center" }}> {filterName} </span>
														<IconButton disabled={filter.activityDefMember.key === 'Workspace' || filter.activityDefMember.key === 'IOLandingCountry' || filter.activityDefMember.key === 'FiscalYear'} checked={false} iconProps={{ iconName: 'Clear' }} title="Remove" ariaLabel="Remove"
															key={"removeBtn" + filter.activityDefMember.key}
															onClick={this._onDeleteFilterAndTriggerSearch.bind(this, filter.activityDefMember.key)} className="advancedSearchRemoveFiltersButton" />
													</div>
													: <div />
											})
										}
									</Stack.Item>
									<Stack.Item>
										{
											(this.state.SearchActivitiesResult !== null) ?
												<Label className="font-Size-12 font-Weight-Bold font-Color-dark-blue">{searchResultsCount}</Label> : <div />
										}
									</Stack.Item>
									<Stack.Item>
										{
											(this.state.SearchActivitiesResult !== null) ?
												<Label className="font-Size-12" >{" results found"}</Label> : <div />
										}
									</Stack.Item>
								</Stack>
							</div>
							: <div />
					}
				</Stack.Item>
			</Stack >
		);
	}
	public _onFilterPanelOpenClick = () => {
		const _isFilterPanelVisible = this.state.IsFilterPanelVisible;
		this.setState({ IsFilterPanelVisible: !_isFilterPanelVisible }, () => {
			if (!_isFilterPanelVisible) {
				this.setState({ OnSearchCompleted: false }, () => {
					if (this.state.SearchFilters.length < 1) {
						this._onAddNewFilter();
					}
				});

			}
		});
	}

	private _onCancelClick = () => {
		this.setState({ OnSearchCompleted: true, IsFilterPanelVisible: false });
	}

	private _onBackToHomeBtnClick = () => {
		this.props.OnBackToHome();
	}

	private _onSearch = () => {
		//Construct search filters object to call API
		this.props.OnSearchStarted();
		let searchedFilters: any[] = this.state.SearchedFilters;
		searchedFilters = [];
		this.setState({ IsFilterPanelVisible: false, SearchActivitiesResult: null }, () => {
			const activityFilters: any = {
				search_id: 'test',
				filters: [],
			}
			this.state.SearchFilters.forEach(f => {
				if (!isNullOrUndefined(f.activityDefMember) && !isNullOrUndefined(f.activityDefMember.key)) {
					const activityDefMatch = this.state.ActivityDefMembers.find(a => a.name === f.activityDefMember.key);
					if (activityDefMatch !== undefined && activityDefMatch !== null && f.selectedFilterValues !== undefined
						&& f.selectedFilterValues !== null && f.selectedFilterValues.length > 0) {
						if (f.activityDefMember.key === 'Workspace') {

							const fullList: any[] = !isNullOrUndefined(this.props.WorkSpacesFullList) ? this.props.WorkSpacesFullList : [];
							const selectedWS: any[] = fullList
								.filter(x => !x.data.is_folder && f.selectedFilterValues.some(n => n === x.key))
								.map(x => {
									return x.key;
								});
							const filter: any = {
								name: 'parent_org_id',
								operator: activityDefMatch.type === "text" || activityDefMatch.type === "string" ? "CONTAINS" : !isNullOrUndefined(f.selectedOperator) && f.selectedOperator !== '' ? f.selectedOperator : "IN",
								values: selectedWS,
								key: 'parent_org_id',
								type: activityDefMatch.type
							}
							activityFilters.filters.push(filter);
						}

						else {
							const namePrefix = activityDefMatch.type === 'relation' ? 'relations.'
								: 'properties.';
							const filter: any = {
								name: namePrefix + activityDefMatch.name,
								operator: activityDefMatch.type === "text" || activityDefMatch.type === "string" ? "CONTAINS" : !isNullOrUndefined(f.selectedOperator) && f.selectedOperator !== '' ? f.selectedOperator : "IN",
								values: f.selectedFilterValues,
								key: activityDefMatch.name,
								type: activityDefMatch.type
							}
							activityFilters.filters.push(filter);
						}
						const filters = searchedFilters.filter(ele => ele.activityDefMember.key === f.activityDefMember.key);
						if (filters.length > 0) {
							filters.forEach(ele => {
								ele.values.push({ value: f.selectedFilterValues[0], operator: f.selectedOperator });
							});
						}
						else {
							const values: any[] = [];
							values.push({ value: f.selectedFilterValues[0], operator: f.selectedOperator });
							searchedFilters.push({ activityDefMember: f.activityDefMember, values: values });
						}
					}
				}
			});
			this.setState({ SearchedFilters: searchedFilters });
			if (this.props.SearchPageView === SearchType.Activity) {
				this._searchActivities(activityFilters);
			}
		})
	}

	private TransformActivities = (activitySearchResults: IActivitySearch): any => {
		if (!isNullOrUndefined(activitySearchResults) && !isNullOrUndefined(activitySearchResults.Activities)) {
			activitySearchResults.Activities.forEach(a => {
				a.is_edit = false;
				if (!isNullOrUndefined(a.properties) && !isNullOrUndefined(a.properties.oamt) && a.properties.oamt !== '' && a.properties.oamt !== 0) {
					a.properties.oamt = new Intl.NumberFormat(Config.locale, { style: 'currency', currency: Config.localeCurrency }).format(parseFloat(a.properties.oamt));
				}
			});
			activitySearchResults = this.SortActivities(activitySearchResults);
		}
		return activitySearchResults;
	}

	private SortActivities = (activitySearchResults: IActivitySearch): any => {
		activitySearchResults.Activities = activitySearchResults.Activities.sort((a, b) => {
			var wsA = this._getWorkspaceName(a.parent_org_id).toLowerCase();
			var wsB = this._getWorkspaceName(b.parent_org_id).toLowerCase();

			var nameA = a.properties["name"].toLowerCase();
			var nameB = b.properties["name"].toLowerCase();

			if (wsA < wsB) return -1;
			else if (wsA > wsB) return 1;
			else if (wsA === wsB) {
				if (nameA < nameB) return -1;
				if (nameA > nameB) return 1;
			}
			return 0;
		});
		return activitySearchResults;
	}

	private _getWorkspaceName = (workspaceId: string): any => {
		const workspace = this.props.WorkSpacesFullList.find(x => x.key === workspaceId);
		if (!isNullOrUndefined(workspace)) return workspace.text;
		return '';
	}

	private _onAddNewFilter = () => {
		const _searchFilters = this.state.SearchFilters;
		let maxFilterId: number = _searchFilters.length > 0 ? 1 : 0;
		this.state.SearchFilters.forEach(x => {
			if (x.filterId > maxFilterId) maxFilterId = x.filterId;
		})
		_searchFilters.push({ filterId: (maxFilterId + 1), activityDefMember: null, selectedFilterValues: [], selectedOperator: '' });

		this.setState({ SearchFilters: _searchFilters });
	}

	private _onDeleteFilterAndTriggerSearch = (key: string) => {
		const _searchFilters = this.state.SearchFilters;
		_searchFilters.filter(f => f.activityDefMember.key === key).forEach(ele => {
			const currIndex = _searchFilters.findIndex(x => x.filterId === ele.filterId);
			if (currIndex >= 0) {
				_searchFilters.splice(currIndex, 1);
			}
		});

		this.setState({ SearchFilters: _searchFilters }, () => {
			this._onSearch();
		});

	}

	private _onDeleteFilter = (filterId: number) => {
		const _searchFilters = this.state.SearchFilters;
		const currIndex = _searchFilters.findIndex(x => x.filterId === filterId);
		if (currIndex >= 0) {
			_searchFilters.splice(currIndex, 1);
		}
		this.setState({ SearchFilters: _searchFilters });
	}

	private _onActivityDefChanged = (activityDefKey: string, filterId: number) => {
		const activityDefMember = this.state.ActivityDefMembers.find(x => x.name === activityDefKey);
		if (activityDefMember !== null && activityDefMember !== undefined) {
			if (activityDefMember.associated_taxonomy_def_id !== null && activityDefMember.associated_taxonomy_def_id !== undefined) {
				if (this.state.AllTaxonomies.findIndex(x => x.associatedTaxonomyDefinitionId === activityDefMember.associated_taxonomy_def_id) < 0) {
					if (this.props.SearchPageView === SearchType.Activity) {
						const taxonomyService = new TaxonomyService(AppSettings);
						const _allTaxonomies = this.state.AllTaxonomies;
						let fyid = ""
						if(!isNullOrUndefined(this.props.SelectedNode) &&  !isNullOrUndefined(this.props.SelectedNode.data)){
							fyid = this.props.SelectedNode.data.relation['FY'];
						}	
						const apiResult = taxonomyService.fetchTaxonomyData(activityDefMember.associated_taxonomy_def_id, null,fyid);
						if (apiResult !== null && apiResult !== undefined) {
							apiResult.then(data => {
								var taxonomies=taxonomyService.tranformTaxonomySearchResult(data[activityDefMember.associated_taxonomy_def_id]);
								taxonomies = taxonomies.sort((a, b) => a.taxonomyDisplayValue.localeCompare(b.taxonomyDisplayValue));
								_allTaxonomies.push({
									associatedTaxonomyDefinitionId: activityDefMember.associated_taxonomy_def_id,
									taxonomies
								});

								const _searchFilters = this.state.SearchFilters;
								const filterToUpdate = _searchFilters.find(x => x.filterId === filterId);
								if (filterToUpdate !== null) {
									const activityDefMemberMatch = this.state.ActivityDefMembers.find(x => x.name === activityDefKey);
									filterToUpdate.activityDefMember = { key: activityDefMemberMatch.name, text: activityDefMemberMatch.labels };
									filterToUpdate.selectedFilterValues = [];
								}
								this.setState({ AllTaxonomies: _allTaxonomies, SearchFilters: _searchFilters });
							});
						}
					}
					else if (activityDefMember.type === 'relation') {
						const _allTaxonomies = this.state.AllTaxonomies;

						const _searchFilters = this.state.SearchFilters;
						const filterToUpdate = _searchFilters.find(x => x.filterId === filterId);
						if (filterToUpdate !== null) {
							const activityDefMemberMatch = this.state.ActivityDefMembers.find(x => x.name === activityDefKey);
							filterToUpdate.activityDefMember = { key: activityDefMemberMatch.name, text: activityDefMemberMatch.labels };
							filterToUpdate.selectedFilterValues = [];
						}
						this.setState({ AllTaxonomies: _allTaxonomies, SearchFilters: _searchFilters });

					}
				}
				else {
					const _searchFilters = this.state.SearchFilters;
					let filterToUpdate = _searchFilters.find(x => !isNullOrUndefined(x.activityDefMember) && x.activityDefMember.key === activityDefKey);
					if (!isNullOrUndefined(filterToUpdate)) {
						filterToUpdate.selectedFilterValues
							= this.state.AllTaxonomies.find(x => x.associatedTaxonomyDefinitionId === activityDefMember.associated_taxonomy_def_id).taxonomies.map(x => {
								return x.taxonomy_id;
							});
					}
					else {
						filterToUpdate = _searchFilters.find(x => x.filterId === filterId);
						if (filterToUpdate !== null) {
							const activityDefMemberMatch = this.state.ActivityDefMembers.find(x => x.name === activityDefKey);
							if (!isNullOrUndefined(activityDefMemberMatch)) {
								filterToUpdate.activityDefMember = { key: activityDefMemberMatch.name, text: activityDefMemberMatch.labels };
								filterToUpdate.selectedFilterValues = [];
							}
						}
					}
					this.setState({ SearchFilters: _searchFilters });
				}
			}
			else {
				const _searchFilters = this.state.SearchFilters;
				const filterToUpdate = _searchFilters.find(x => x.filterId === filterId);
				if (filterToUpdate !== null) {
					const activityDefMemberMatch = this.state.ActivityDefMembers.find(x => x.name === activityDefKey);
					if (!isNullOrUndefined(activityDefMemberMatch)) {
						filterToUpdate.activityDefMember = { key: activityDefMemberMatch.name, text: activityDefMemberMatch.labels };
						filterToUpdate.selectedFilterValues = [];
					}
				}
				this.setState({ SearchFilters: _searchFilters });
			}
		}
	}

	private _onFilterValueChanged = (selectedValues: string[], filterId: number, selectedOperator: string) => {
		const _searchFilters = this.state.SearchFilters;
		const filterToUpdate = _searchFilters.find(x => x.filterId === filterId);
		if (filterToUpdate !== null) {
			filterToUpdate.selectedFilterValues = selectedValues;
			filterToUpdate.selectedOperator = selectedOperator;

			this.setState({ SearchFilters: _searchFilters });
		}
	}

	private _searchActivities(activityFilters: any) {
		const _this = this;
		if (this.state.SearchFilters.length > 0) {
			const filterValues: boolean[] = [];
			const isFolder: boolean = false;
			filterValues.push(isFolder);
			activityFilters.filters.push({
				name: 'is_folder',
				values: filterValues
			});
		}


		const _selectedWorkspaces = this._getNodeTaxonomiesForSearchedWorkspaces();

		const _activityDefinitionsFetchedSoFar = this.state.ActivityDefinitionsForSearchedWorkspaces.map(x => {
			return ({ workspaceID: x.workspaceID, activityDef: x.activityDef });
		})

		let lstService: ActivityListService = new ActivityListService();
		if (_selectedWorkspaces.some(x => !_activityDefinitionsFetchedSoFar.some(y => y.workspaceID === x.id))) {
			_selectedWorkspaces.forEach(x => {
				if (!_activityDefinitionsFetchedSoFar.some(y => y.workspaceID === x.id)) {
					var selectedNodeTaxonomiesArr = x.nodeTaxonomies as ITaxonomyDetail[]
					var fyid =selectedNodeTaxonomiesArr.length > 0 ? x.nodeTaxonomies.find(x=> x.taxonomyDefinitionId === 'fy').taxonomy_id: "";

					lstService.fetchActivityDefinition(x.nodeTaxonomies)
						.then(data => {
							let _activityDef = data as IActivityDefinition;
							_activityDef.fyid = fyid;
							_activityDefinitionsFetchedSoFar.push({ workspaceID: x.id, activityDef: _activityDef });
							if (!_selectedWorkspaces.some(x => !_activityDefinitionsFetchedSoFar.some(y => y.workspaceID === x.id))) {
								_this.setState({ ActivityDefinitionsForSearchedWorkspaces: _activityDefinitionsFetchedSoFar }, () => {
									this._fetchActivities(activityFilters, _activityDefinitionsFetchedSoFar, _selectedWorkspaces);
								});
							}
						})
				}
			})
		}
		else {
			this._fetchActivities(activityFilters, this.state.ActivityDefinitionsForSearchedWorkspaces, _selectedWorkspaces);
		}
	}

	private _fetchActivities = (activityFilters: any, activityDefinitionsForSearchedWorkspaces: any[], selectedWorkspaces: any[]) => {
		const _this = this;
		const opts = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(activityFilters)
		};

		const url = `${AppSettings.ActivityBaseUrl()}/${Config.Actvity.Search}`;
		adalApiFetch(fetch, url, opts)
			.then(response => response.json())
			.then(activitiesData => {
				const _searchActivitiesResult: IActivitySearch = {
					Activities: activitiesData, ClearSearch: false, ShowHierarchy: false, SelectedWorkspaces: selectedWorkspaces
					, ActivityDefinitionsForSearchedWorkspaces: activityDefinitionsForSearchedWorkspaces

				};
				_this.setState({
					IsFilterPanelVisible: false, OnSearchCompleted: true, SearchActivitiesResult: this.TransformActivities(_searchActivitiesResult),
					ExportSearchFilters: activityFilters
				});
				_this.props.OnSearchCompleted(_searchActivitiesResult);
			});
	}

	private _getNodeTaxonomiesForSearchedWorkspaces = (): any[] => {
		const _selectedWorkspaces: any[] = [];
		this.props.WorkSpacesFullList.forEach(x => {
			if (this.state.SearchFilters.find(fil => fil.filterId === 1 && fil.selectedFilterValues.some(val => val === x.key))) {
				if (!isNullOrUndefined(x.data) && !isNullOrUndefined(x.data.relation)) {
					let selectedNodeTaxonomies: any[] = [];
					selectedNodeTaxonomies.push(
						{
							taxonomy_id: x.data.relation['Organization'], taxonomyDefinitionId: "organization.taxonomy.def.id",
							taxonomyDisplayValue: null, parentTaxonomyDefinitionId: null, parentTaxonomyIds: null
						},
						{
							taxonomy_id: x.data.relation['Country'], taxonomyDefinitionId: "country.taxonomy.def.id",
							taxonomyDisplayValue: null, parentTaxonomyDefinitionId: null, parentTaxonomyIds: null
						},
						{
							taxonomy_id: x.data.relation['FY'], taxonomyDefinitionId: "fy",
							taxonomyDisplayValue: null, parentTaxonomyDefinitionId: null, parentTaxonomyIds: null
						}
					);
					_selectedWorkspaces.push({ id: x.key, name: x.text, nodeTaxonomies: selectedNodeTaxonomies });
				}
				else _selectedWorkspaces.push({ id: x.key, name: x.text, nodeTaxonomies: null });
			}
		}
		)

		return _selectedWorkspaces;
	}
}