import * as React from 'react';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { ActivityImportPanel } from '../../ImportDetails/ActivityImportPanel';
import { AuthorizationService } from'@commercialsalesandmarketing/pet-common';
import { Permissions } from '../../../../contracts/Permissions'
import { AppSettings } from '../../../../Configuration';

export class ImportButton extends React.Component<{
  selectedWorkspaceId: string,
  UserHierarchyDetails: any,
  roles: any[],
  Workspaces: any[],
  disabled: boolean
},
  {
    showModal: boolean;
    isRoleAvailable: boolean;
  }> {
  public constructor(props: {
    selectedWorkspaceId: string,
    UserHierarchyDetails: any,
    roles: any[],
    Workspaces: any[],
    disabled: false
  }) {
    super(props);
    this.onImportClicked = this.onImportClicked.bind(this);
    this.onCloseImportPanel = this.onCloseImportPanel.bind(this);
    const authorizationService = new AuthorizationService(AppSettings);
    const isRoleAvailable = authorizationService.IsRolePresent(this.props.roles, Permissions.CreateUpdateActivity);

    this.state = {
      showModal: false,
      isRoleAvailable
    }
  }

  public render(): JSX.Element {
    var _additionalProperties = {};
    _additionalProperties["WorkspaceId"] = this.props.selectedWorkspaceId;
    return (
      <div className="activityActionButtonContainer">
        <TooltipHost
          className="actionButtontoolTip"
          calloutProps={{ gapSpace: -10 }}
          closeDelay={100}
          hostClassName="selectedTaxonomyValueTooltip"
          tooltipProps={{
            onRenderContent: () => {
              return (
                <div>
                  <ul style={{ margin: 0, padding: 0 }}>
                    <li><span>Import</span></li>
                  </ul>
                </div>
              );
            }
          }}
          delay={TooltipDelay.zero}
          directionalHint={DirectionalHint.topCenter}
        >
          <div style={{ display: 'flex' }}>
            <ActionButton
              data-automation-id="test"
              iconProps={{ iconName: 'Upload' }}
              allowDisabledFocus={true}
              disabled={!this.state.isRoleAvailable || this.props.disabled}
              onClick={this.onImportClicked}
              className="activityActionButton" id="btnActivityImport" >Import</ActionButton>
            <div>
              { this.state.showModal &&
                <ActivityImportPanel additionalProperties={_additionalProperties} showModal={this.state.showModal} UserHierarchyDetails={this.props.UserHierarchyDetails}
                  onCloseImportPanel={this.onCloseImportPanel} Workspaces={this.props.Workspaces}
                   />
              }
            </div>
          </div>
        </TooltipHost>
      </div>
    );
  }

  public componentWillReceiveProps(nextProp: any) {
    const authorizationService = new AuthorizationService(AppSettings);
    const isRoleAvailable = authorizationService.IsRolePresent(nextProp.roles, Permissions.CreateUpdateActivity);
    this.setState({
      showModal: false,
      isRoleAvailable
    })
  }

  private onImportClicked = (): void => {
    this.setState({ showModal: true });
  };

  private onCloseImportPanel = (): void => {
    this.setState({ showModal: false });
  };
}