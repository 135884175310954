import * as React from 'react';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { adalApiFetch } from '../../../../adalConfig';
import { AppSettings, Config } from '../../../../Configuration';
import FileSaver from 'file-saver';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

export class ExportButton extends React.Component<{
  selectedWorkspaceId: string,
  selectedWorkspaceName: string 
},
  {
    hideSpinner: boolean   
  }>{
  public constructor(props: {
    selectedWorkspaceId: string,
    selectedWorkspaceName: string    
  }) {
    super(props);
    this.onExportClicked = this.onExportClicked.bind(this);
    this.state = {
      hideSpinner: true
    }
  }

  public render(): JSX.Element {
    return (
      <div className="activityActionButtonContainer">
        <TooltipHost
          className="actionButtontoolTip"
          calloutProps={{ gapSpace: -10 }}
          closeDelay={100}
          hostClassName="selectedTaxonomyValueTooltip"
          tooltipProps={{
            onRenderContent: () => {
              return (
                <div>
                  <ul style={{ margin: 0, padding: 0 }}>
                    <li><span>Export</span></li>
                  </ul>
                </div>
              );
            }
          }}
          delay={TooltipDelay.zero}
          directionalHint={DirectionalHint.topCenter}
        >
          <div style={{ display: 'flex' }}>
            <ActionButton
              data-automation-id="test"
              disabled={!this.state.hideSpinner}
              iconProps={{ iconName: 'Download' }}
              allowDisabledFocus={true}
              onClick={this.onExportClicked}
              className="activityActionButton" >Export</ActionButton>
            {
              !this.state.hideSpinner ?
                <div>
                  <Spinner className="activityLoader" size={SpinnerSize.xSmall} hidden={this.state.hideSpinner} />
                </div> : ''
            }
          </div>
        </TooltipHost>       

      </div>
    );
  }

  public onExportClicked() {
    const OrgPathIds = [];
    const locale = Config.locale;
    this.setState({ hideSpinner: false })
    const dtNow = this.replaceAll((new Date()).toISOString(), ":", "");
    const fileName = this.props.selectedWorkspaceName + dtNow.substring(0, dtNow.length - 5) + '.xlsx';
    OrgPathIds.push(this.props.selectedWorkspaceId);
    return adalApiFetch(fetch, `${AppSettings.ActivityBaseUrl()}/${Config.Actvity.SearchByOrgPathIds}/${OrgPathIds}/${locale}`, {})
      .then(response => {
        return Promise.all([response.blob(), response.headers]);
      }).then(([blob, headers]) => {
        FileSaver.saveAs(blob, fileName);
        this.setState({ hideSpinner: true });
      })
    }
  
  private replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }
}