import * as React from 'react';
import { NormalPeoplePicker, IBasePicker } from 'office-ui-fabric-react/lib/Pickers';
import { IPersonaProps } from 'office-ui-fabric-react/lib/Persona';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { adalApiFetch } from '../../../../../adalConfig';
import { AppSettings, Config } from '../../../../../Configuration';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { isNullOrUndefined } from 'util';
import { ActivityPropertyTooltip } from './ActivityPropertyTooltip';
import { Stack, StackItem } from 'office-ui-fabric-react/lib/Stack';

export interface IAliasSuggestions extends IPersonaProps {
    key: string,
    type: string,
    text: string,
    displayname: string

}

export class ActivityAlias extends React.Component<{
    name: string,
    label: string,
    isMandatory: boolean,
    onValueChanged: any,
    propertyValue: any,
    userlist: any,
    informationText:string
}
, {
    searchItems: IAliasSuggestions[],
    currentSelectedItems: IAliasSuggestions[],
    InputText: string,
    showSuggestion: string,
    displayErrorMessage: boolean,
    isError: boolean,
    errorDetails: string,
    isLoading : boolean
}
>{
    // All pickers extend from BasePicker specifying the item type.
    public _picker = React.createRef<IBasePicker<IAliasSuggestions>>();
    constructor(props) {
        super(props);
        this._onFilterChanged = this._onFilterChanged.bind(this);
        this._onItemSelected = this._onItemSelected.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onSuggestionClicked = this._onSuggestionClicked.bind(this);
        this._onSelectionChange = this._onSelectionChange.bind(this);
        this._onFocus = this._onFocus.bind(this);
        this._onClickSuggest = this._onClickSuggest.bind(this);
        this._convertUserObjTOAliasSuggestion=this._convertUserObjTOAliasSuggestion.bind(this);
        this._filterPersonasByText=this._filterPersonasByText.bind(this);
        this.state = {
            searchItems: [],
            currentSelectedItems: [],
            InputText: "",
            showSuggestion: "",
            displayErrorMessage: false,
            isError: false,
            errorDetails: "",
            isLoading : false
        };
    }


    public componentDidMount() {
        if (this.props !== null) {
            const selectedItem = (this.props.propertyValue) ? this.convertTOAliasSuggestion([this.props.propertyValue]) : [];
            this.setState({
                currentSelectedItems: selectedItem
            });

            const opts = {
                method: "POST",
                headers: { "Content-Type": "application/json" }
            };
            const url: any = `${AppSettings.TaxonomyServiceUrl()}/${Config.Taxonomy.GetOwnerAlias}`;

            adalApiFetch(fetch, url, opts)
                .then(response => response.json())
                .then(data => {
                    if (data !== undefined && data.length > 0) {
                        const searchAliasList = this.convertTOAliasSuggestion(data);
                        this.setState({
                            searchItems: searchAliasList                           
                        });
                        this.props.userlist(data, this.props.name);

                        return;
                    }
                    else {
                        this.setState({
                            isError: true,
                            errorDetails: data.ExceptionMessage
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        isError: true,
                        errorDetails: error.message
                    });
                });
        }
    }

    public componentWillReceiveProps(nextProps: any) {
        if (nextProps !== null) {
            const selectedItem = (nextProps.propertyValue) ? this.convertTOAliasSuggestion([nextProps.propertyValue]) : [];
            if(nextProps.propertyValue !== this.props.propertyValue){               
                this.setState({
                    displayErrorMessage: false,
                    currentSelectedItems: selectedItem               
            });
            }
        }
    }

    public render() {
        const self = this;
        return (
            <div className="singleSelectDropdown">

            <Stack horizontal grow>
             <StackItem>
                <Label required={this.props.isMandatory} className="font-Size-12" style={{marginBottom:'5px'}}>{this.props.label}</Label>
                </StackItem>
                <StackItem>
                { !isNullOrUndefined(this.props.informationText) && this.props.informationText!==""  &&
                      <ActivityPropertyTooltip informationText={this.props.informationText} />
                }
                </StackItem>
            </Stack>
                <NormalPeoplePicker
                        onResolveSuggestions={self._onFilterChanged}
                        onChange={self._onSelectionChange}
                        resolveDelay={1000}
                        selectedItems={self.state.currentSelectedItems}
                        className="divAliasPicker"
                        removeButtonAriaLabel={'Remove'}
                        componentRef={self._picker}
                        key={'normal'}
                        inputProps={{
                            onBlur: (ev: React.FocusEvent<HTMLInputElement>) => {
                                this._onBlur(ev);
                            },
                            onFocus: (ev: React.FocusEvent<HTMLInputElement>) => {
                                this._onFocus();
                            },
                            "aria-label":"Activity owner Alias"

                        }}
                        pickerSuggestionsProps={{
                            noResultsFoundText: 'No matches Found',
                            showRemoveButtons: false,
                            loadingText: 'Loading..',
                            searchForMoreText: this.state.showSuggestion
                        }}
                        itemLimit={1}
                        onItemSelected={self._onItemSelected}
                        onGetMoreResults={self._onSuggestionClicked}
                        styles={{
                            itemsWrapper:{
                              display:this.state.currentSelectedItems.length>0?"block":"none"
                            }
                          }}
                    />                
                {this.state.isLoading ? <Spinner/> : ''}
                <Label className={this.state.displayErrorMessage ? "aliasErrorMessageDisplay" : "aliasErrorMessageHide"} style={{ color: 'red' }}>Invalid user alias.  <Link onClick={this._onClickSuggest} className="suggestionText">Click to see Suggestions</Link></Label>
            </div>

        );
    }

    private _onClickSuggest() {
        this.setState({showSuggestion : "Show Suggestion",InputText : ""},() => {
            this._picker.current.focusInput();
        })
       
    }
    private _onFocus() {
        this.setState({InputText : ""});       
    }

    private _onFilterChanged = (filterText: string, tagList: IAliasSuggestions[]): Promise<IAliasSuggestions[]> => {

        if (filterText && filterText.trim() !== '' && filterText.length > 2) {
            const filteredItems: any[] = this._filterPersonasByText(filterText, this.state.searchItems)
            if (filteredItems.length === 0) {
                this.setState({
                    InputText: filterText,
                    showSuggestion: "",
                    displayErrorMessage: false
                });
                if(filterText.length>3)
                {
                  const url: any = `${AppSettings.AuthorizationBaseUrl()}/${Config.Authorization.GetSimilarAlias}/${filterText}/${false}`
                  return new Promise<IAliasSuggestions[]>((resolve, reject) => setTimeout(() => resolve(

                      adalApiFetch(fetch, url, {})
                          .then(response => response.json())
                          .then(data => {
                              if(data.length>0)
                              {
                              const searchAliasList = this._convertUserObjTOAliasSuggestion(data);
                              var existingSuggestions=this.state.searchItems;
                              existingSuggestions.push(...searchAliasList);
                              this.setState({
                                  displayErrorMessage:false,
                                  searchItems:Array.from(new Set(existingSuggestions.sort((a, b) => a.key.localeCompare(b.key))))
                              });
                              return searchAliasList;
                            }
                            else
                            {
                                this.setState({showSuggestion : "Show Suggestion",InputText : "",displayErrorMessage:true},() => {
                                    this._picker.current.focusInput();
                                    
                                })
                                return [];
                            }
                          })
                         ), 2000));
                }
                else
                {
                    return new Promise<IAliasSuggestions[]>((resolve, reject) => setTimeout(() => resolve(
                         filteredItems), 0));
                }
            } else {
                this.setState({
                    InputText: "",
                    showSuggestion: "",
                    displayErrorMessage: false
                });
                return new Promise<IAliasSuggestions[]>((resolve, reject) => setTimeout(() => resolve(
                    filteredItems), 0));

            }
        }
        return null;
    };

    private _filterPersonasByText(filterText: string, filteredPersonas: IAliasSuggestions[]): IAliasSuggestions[] {
        return filteredPersonas.filter(item => this._doesTextStartWith(item.text as string, filterText));
    }
    private _doesTextStartWith(text: string, filterText: string): boolean {
        return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
    }

    private _onItemSelected = (item: IAliasSuggestions): IAliasSuggestions | null => {
        const selectedAliasList = this.convertTOAliasSuggestion([item.displayname]);
        this.setState({
            InputText: "",
            displayErrorMessage: false,
            currentSelectedItems: selectedAliasList
        });

        return item;
    };


    private _onBlur(event: React.FocusEvent<HTMLElement>): void {
        const inputAlias = this.state.InputText;        
        if (inputAlias !== "" && this.state.currentSelectedItems.length===0 ) {
            this.setState({isLoading : true,showSuggestion:''})
            const url: any = `${AppSettings.AuthorizationBaseUrl()}/${Config.Authorization.validateUserAlias}/${inputAlias}`

            adalApiFetch(fetch, url, {})
                .then(response => response.json())
                .then(data => {
                    if (data !== undefined && data === true) {
                        const selectedAliasList = this.convertTOAliasSuggestion([inputAlias]);
                        this.setState({
                            displayErrorMessage: false,
                            InputText: "",
                            currentSelectedItems: selectedAliasList,
                            isLoading:false
                        });
                        this.props.onValueChanged(inputAlias, this.props.name);
                        return selectedAliasList[0];
                    }
                    else {
                        this.setState({
                            displayErrorMessage: true,
                            isError: true,
                            isLoading:false
                        });
                        this.props.onValueChanged("", this.props.name);

                        return null;
                    }
                })

        }
    }
    private _onSuggestionClicked = (filterText: string, tagList: IAliasSuggestions[]): Promise<IAliasSuggestions[]> => {
        const url: any = `${AppSettings.AuthorizationBaseUrl()}/${Config.Authorization.GetSimilarAlias}/${filterText}/${true}`
        return new Promise<IAliasSuggestions[]>((resolve, reject) => setTimeout(() => resolve(

            adalApiFetch(fetch, url, {})
                .then(response => response.json())
                .then(data => {
                    const searchAliasList = this._convertUserObjTOAliasSuggestion(data);
                    this.setState({
                        InputText: ""
                    });
                    return searchAliasList;
                })
        ), 2000));
    }

    private _onSelectionChange(items: IAliasSuggestions[]): void {
        if (items && items.length > 0) {
            this.props.onValueChanged(items[0].displayname, this.props.name);
        } else {
            this.props.onValueChanged("", this.props.name);
        }
        this.setState({
            currentSelectedItems: items
        });

    };

    private convertTOAliasSuggestion(items: string[]): IAliasSuggestions[] {
        const selectedAliasList: IAliasSuggestions[] = [];
        if (items && items.length > 0) {
            items.forEach(item => {
                const User: IAliasSuggestions = { key: item, type: item, text: item, displayname: item }
                selectedAliasList.push(User);
            });
        }

        return selectedAliasList;
    }

    private _convertUserObjTOAliasSuggestion(items: any[]): IAliasSuggestions[] {
        const selectedAliasList: IAliasSuggestions[] = [];
        if (items && items.length > 0) {
            items.forEach(item => {
                const User: IAliasSuggestions = { key: item.upn, type: item.upn, text: item.upn, displayname: item.upn }
                selectedAliasList.push(User);
            });
        }

        return selectedAliasList;
    }
}