import { AuthenticationContext, adalFetch } from 'react-adal';

const adalConfig ={
    cacheLocation: 'localStorage',
    clientId: '282e745d-e26a-4675-9369-f80c7d26ef99',
    endpoints: {
        api: '282e745d-e26a-4675-9369-f80c7d26ef99',
      },
    tenant: 'microsoft.onMicrosoft.com'
    };

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

  export const getToken = () => {
    return authContext.getCachedToken(authContext.config.clientId);
   };