import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { DialogAction } from '../../../../contracts/ActivityAction';

export class ActivityBlockingDialog extends React.Component<
  {
    hideDialog: boolean,
    dialogText:string,
    OnDialogActionButtonClick:(action:DialogAction) => void  
  },
  {
    hideDialog: boolean;
    dialogText: string;
  }
> {
  constructor(props: {
    hideDialog: true,
    dialogText:'',
    OnDialogActionButtonClick:(action:DialogAction) => void  
  }) {
    super(props);

    this.state = {
        hideDialog:true,
        dialogText:''
    };
  }
  public componentWillReceiveProps(nextProps:any) {
    this.setState ( {
        hideDialog:nextProps.hideDialog,
        dialogText:nextProps.dialogText
    });
  }
  public render() {
    const {hideDialog,dialogText}=this.state;
    return (
      <div>
        <Dialog
          hidden={hideDialog}
          onDismiss={this._closeDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Save Changes',
            subText: dialogText
          }}
          modalProps={{
            isBlocking: true,
            containerClassName: 'ms-saveDialogMainOverride',
            isDarkOverlay:true
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this._onSaveClick} text="Yes" />
            <DefaultButton onClick={this._onResetClick} text="No" />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }

  private _onSaveClick = (): void => {
    this.props.OnDialogActionButtonClick(DialogAction.Yes);
    this.setState({ hideDialog: true });
  };
  private _onResetClick = (): void => {
    this.props.OnDialogActionButtonClick(DialogAction.No);
    this.setState({ hideDialog: true });
  };
  private _closeDialog = (): void => {
    this.setState({ hideDialog: true });
  };
}