import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';
import 'core-js/fn/array/find';
import 'core-js/fn/string/includes';
import 'core-js/fn/array/find-index'
import 'whatwg-fetch';
import { polyfill } from 'es6-promise'
polyfill();

const DO_NOT_LOGIN = false;

runWithAdal(authContext, () => {

  // eslint-disable-next-line
  require('./indexApp.js');

},DO_NOT_LOGIN);