import * as React from 'react';
import { ActivityListDetails } from './ActivityDetailControls/ActivityListDetails';
import { ActivityActionButtonPanel } from './ActivityDetailControls/ActivityActionButtonPanel';
import { ActivityDetails } from '../../services/ActivityDetails';
import { IActivityDetails } from '../../contracts/IActivityDetails';
import { IActivityActionAttribute } from '../../contracts/IActivityAction';
import { ActivityAction, DialogAction } from '../../contracts/ActivityAction';
import { ActivityBlockingDialog } from './ActivityDetailControls/ActivityListDetailsControls/ActivityBlockingDialog';
import { ITaxonomyDetail } from '../../contracts/ITaxonomyDetails';
import { IActivitySearch } from '../../contracts/IActivitySearch';
import { ActivityDeleteDialog } from './ActivityDetailControls/ActivityListDetailsControls/ActivityDeleteDialog';
import { ActivityMoveDialog } from './ActivityDetailControls/ActivityListDetailsControls/ActivityMoveDialog';
import { AppSettings, Config } from '../../Configuration';
import { adalApiFetch } from '../../adalConfig';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { AppInsights } from "applicationinsights-js"
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { IActivityDefinition } from '../../contracts/IActivityDefinition';
import { ActivityDefinitionService } from '../../services/ActivityDefinitionService';
import { IActivityDeifinitionMember } from '../../contracts/IActivityDefinitionMember';
import { isNullOrUndefined } from 'util';
import { ActivityListService } from '../../services/ActivityListService';

export class ActivitiesDetails extends React.Component<{
    selectedNode: any,
    roles: any[],
    ActivitySearch: IActivitySearch,
    OnPOClick: (OnActivityDetails: boolean, SelectionDetails: any) => void,
    ActivitySearchList: (activitiesList: any[]) => void,
    UserHierarchyDetails: any,
    Workspaces: any[],
    IsInAdvancedSearchMode: boolean,
    IsSearchInProgress: boolean,
    SetHelpPageId: (helpPageId: string) => void,
    HandleClearSearch: () => void
},
    {
        selectedActivity: any,
        onCreateActivity: boolean,
        isError: boolean,
        erroDetails: string,
        activityAction: IActivityActionAttribute,
        hideSaveChangesDialog: boolean,
        dialogText: string,
        moveActivityDialogText: string,
        hideMoveActivityDialog:boolean,
        warningText: string,
        hideDeleteDialog: boolean,
        cutActivities: any[]
        copiedActivities: any[],
        isDelete: boolean,
        AllColumns: any[],
        SelectedColumnsToDisplay: IActivityDeifinitionMember[],
        ActivityDef: any,
        AllTaxonomies: any,
        disableActivityActionButtons: boolean
    }
    >{

    private activityDetail: IActivityDetails;

    constructor(props: {
        OnCreateActivity: boolean,
        selectedNode: any,
        OnPOClick: (OnActivityDetails: boolean, SelectionDetails: any) => void,
        ActivitySearchList: (activitiesList: any[]) => void,
        roles: [],
        ActivitySearch: IActivitySearch,
        UserHierarchyDetails: any,
        Workspaces: any[],
        IsInAdvancedSearchMode: boolean,
        IsSearchInProgress: boolean,
        SetHelpPageId: (helpPageId: string) => void,
        HandleClearSearch: () => void
    }) {
        super(props);
        const activityService = new ActivityDetails();
        this.activityDetail = activityService.Get(this.props.selectedNode.key);
        this._handleActivitySelection = this._handleActivitySelection.bind(this);
        this._handleActivityActionClick = this._handleActivityActionClick.bind(this);
        this._handleDialogActionClick = this._handleDialogActionClick.bind(this);
        this._handleActivitySaveClick = this._handleActivitySaveClick.bind(this);
        this._handleAddActivity = this._handleAddActivity.bind(this);
        this._handleExpandAll = this._handleExpandAll.bind(this);
        this._handleCollapseAll = this._handleCollapseAll.bind(this);
        this._handleDeleteClick = this._handleDeleteClick.bind(this);
        this._handleDeleteDialogClick = this._handleDeleteDialogClick.bind(this);
        this._handleCutClick = this._handleCutClick.bind(this);
        this._handlePasteClick = this._handlePasteClick.bind(this);
        this._handleCopyClick = this._handleCopyClick.bind(this);
        this._handleCutPasteClick = this._handleCutPasteClick.bind(this);
        this._handleCopyPasteClick = this._handleCopyPasteClick.bind(this);
        this._handleActivityDefn = this._handleActivityDefn.bind(this);
        this._handleColumnSelection = this._handleColumnSelection.bind(this);
        this._handleCustomColumnChange = this._handleCustomColumnChange.bind(this);
        this._handleMoveDialogActionClick = this._handleMoveDialogActionClick.bind(this);
        this._cascadeValuesFromParent = this._cascadeValuesFromParent.bind(this);
        this._searchActivity = this._searchActivity.bind(this);
        this._createActivityObject = this._createActivityObject.bind(this);
        this._createFolderObject = this._createFolderObject.bind(this);

        this.state = {
            selectedActivity: {id:''},
            onCreateActivity: false,
            isError: false,
            erroDetails: '',
            hideSaveChangesDialog: true,
            dialogText: '',
            moveActivityDialogText:'',
            hideMoveActivityDialog:true,
            warningText: '',
            activityAction: {
                IsLoading: false,
                DialogAction: DialogAction.none,
                ActivityAction: ActivityAction.none,
                Activity: {},
                UpdateSelection: false,
                SaveComplete: false,
                MoveActivity:false,
                movedActivityId:'',
                ActivitiesFullList: []
            },
            hideDeleteDialog: true,
            cutActivities: [],
            copiedActivities: [],
            isDelete: false,
            AllColumns: [],
            SelectedColumnsToDisplay: [],
            ActivityDef: null,
            AllTaxonomies: null,
            disableActivityActionButtons: false
        }
    }

    public render(): JSX.Element {

        if (AppInsights !== undefined) {
            AppInsights.trackPageView(
                "ActivityDetail",
                null,
                { env: AppSettings.Env() }
            );
        }
        const selectedNodeTaxonomies: ITaxonomyDetail[] = [];
        const OrganizationKey = "Organization";
        const CountryKey = "Country";
        if (this.props.selectedNode.data !== undefined && this.props.selectedNode.data !== null
            && this.props.selectedNode.data.relation !== undefined && this.props.selectedNode.data.relation !== null) {
            selectedNodeTaxonomies.push(
                {
                    taxonomy_id: this.props.selectedNode.data.relation[OrganizationKey], taxonomyDefinitionId: "organization.taxonomy.def.id",
                    taxonomyDisplayValue: null, parentTaxonomyDefinitionId: null, parentTaxonomyIds: null
                },
                {
                    taxonomy_id: this.props.selectedNode.data.relation[CountryKey], taxonomyDefinitionId: "country.taxonomy.def.id",
                    taxonomyDisplayValue: null, parentTaxonomyDefinitionId: null, parentTaxonomyIds: null
                },
                {
                    taxonomy_id: this.props.selectedNode.data.relation['FY'], taxonomyDefinitionId: "fy",
                    taxonomyDisplayValue: null, parentTaxonomyDefinitionId: null, parentTaxonomyIds: null
                }
            );
        }
        return (
            <Stack verticalFill grow className="activityDetails">
                <Stack.Item>
                    {
                        !this.props.IsInAdvancedSearchMode ?
                            <h2 className="ms-fontSize-xl manageActivity" style={{marginTop:0,marginBottom:0,fontWeight:400}}>Manage Activities</h2>
                            : <div />
                    }
                </Stack.Item>
                <Stack.Item>
                    {
                        this.state.activityAction.IsLoading ?
                            <Spinner className="saveInProgress" size={SpinnerSize.large} /> : ''
                    }
                    {
                        !this.props.IsInAdvancedSearchMode ?
                            <ActivityActionButtonPanel Workspaces={this.props.Workspaces}
                                OnActivityActionButtonClick={this._handleActivityActionClick}
                                OnSelectedColumns={this._handleColumnSelection}
                                UserHierarchyDetails={this.props.UserHierarchyDetails}
                                roles={this.props.roles} SelectedWorkspaceId={this.props.selectedNode.key}
                                SelectedWorkspaceName={this.props.selectedNode.text}
                                SelectedActivity={this.state.selectedActivity}
                                Allcolumns={this.state.AllColumns}
                                ActivityDef={this.state.ActivityDef}
                                AllTaxonomies={this.state.AllTaxonomies}
                                DisableEdits={this.state.disableActivityActionButtons} // Disable all buttons which can facilitate creates/edits
                            />
                            : <div />
                    }
                </Stack.Item>
                <Stack.Item verticalFill grow>
                    <ActivityListDetails
                        initailSelectedActivty={this.activityDetail}
                        SelectedHierarchyNodeId={this.props.selectedNode.key}
                        SelectedHierarchyNodeTaxonomies={selectedNodeTaxonomies}
                        OnPOClick={this.props.OnPOClick}
                        HandleActivitySelection={this._handleActivitySelection}
                        UpdateActivityOnAction={this.state.activityAction}
                        ActivitySearchList={this.props.ActivitySearchList}
                        roles={this.props.roles}
                        HandleSaveActivityClick={this._handleActivitySaveClick}
                        ActivitySearch={this.props.ActivitySearch}
                        IsSearchInProgress={this.props.IsSearchInProgress}
                        HandleActivityDefForColumns={this._handleActivityDefn}
                        SelectedActivityColumns={this.state.SelectedColumnsToDisplay}
                        SetHelpPageId={this.props.SetHelpPageId} />
                    <ActivityBlockingDialog hideDialog={this.state.hideSaveChangesDialog}
                        dialogText={this.state.dialogText}
                        OnDialogActionButtonClick={this._handleDialogActionClick}                        
                    />
                    <ActivityMoveDialog hideDialog={this.state.hideMoveActivityDialog}
                        dialogText={this.state.moveActivityDialogText}
                        OnDialogActionButtonClick={this._handleMoveDialogActionClick}                        
                    />
                    <ActivityDeleteDialog hideDialog={this.state.hideDeleteDialog}
                        dialogText={this.state.dialogText}
                        OnDialogActionButtonClick={this._handleDeleteDialogClick}
                        isDelete={this.state.isDelete}
                        warningText={this.state.warningText}
                    />
                </Stack.Item>
            </Stack>
        );
    }

    public componentDidMount()
    {
        if(isNullOrUndefined(this.state.selectedActivity))
        {
            this.setState({selectedActivity:{id:''}});
        }
    }

    public componentWillReceiveProps(nextProps: any) {
        const activityActionAttributesChanged: any = this.state.activityAction;

        if (this.props.selectedNode.key !== nextProps.selectedNode.key) {
            activityActionAttributesChanged.Activity = {};
            activityActionAttributesChanged.ActivityAction = ActivityAction.none;
            activityActionAttributesChanged.MoveActivity = false;
            this.setState({ activityAction: activityActionAttributesChanged, copiedActivities: [] });
        }
    }

    private _handleActivitySelection(selectedactivity: any, displayDialog: boolean) {
        var clearSearch:boolean=true;
        const activityAction: any = this.state.activityAction;
        if ((activityAction.ActivityAction === ActivityAction.Paste && !activityAction.MoveActivity) || activityAction.ActivityAction === ActivityAction.Cut
            || activityAction.ActivityAction === ActivityAction.Delete) {
            activityAction.Activity = {};
        }
        const editornewactivity: any = activityAction.Activity;
        // Display Dialog if activity selection is changed with out saving 
        if (displayDialog) {
            if (activityAction.ActivityAction === ActivityAction.none && this.state.cutActivities.length > 0) {
                activityAction.ActivityAction = ActivityAction.Cut;
                this.setState({ activityAction }, () => {
                    activityAction.ActivityAction = ActivityAction.none
                    activityAction.Activity = selectedactivity;
                    this.setState({ activityAction })
                    this.setState({ cutActivities: [] })
                })
            }
            else {
                activityAction.Activity = selectedactivity;
                this.setState({ activityAction })
            }
            if (!this.state.hideSaveChangesDialog) {
                this.setState({ hideSaveChangesDialog: true });
            }

            // 'displayDialog' true denotes there are changes on the folder/activity. In that case disable ActivityActionPanel buttons since edit is in progress
            this.setState({ disableActivityActionButtons: true });
        }
        else if (editornewactivity && selectedactivity && editornewactivity.id !== selectedactivity.id) {
            // Selection has changed, so enable the ActivityActionpanel buttons back
            this.setState({ disableActivityActionButtons: false });
        }
        if (activityAction.SaveComplete) {
            activityAction.Activity = {};
            activityAction.SaveComplete = false;
            activityAction.movedActivityId='';
            activityAction.UpdateSelection=false;
            activityAction.MoveActivity=false;
            this.setState({
                selectedActivity: selectedactivity,
                disableActivityActionButtons: false
            })
        }

        if (!editornewactivity || !editornewactivity.id || editornewactivity.id === '') {
            this.setState({
                selectedActivity: selectedactivity,
            })
        }
        else if (editornewactivity && selectedactivity && editornewactivity.id !== selectedactivity.id) {
            if (!activityAction.UpdateSelection) {
                const text: string = editornewactivity.is_folder ? editornewactivity.id === 'newactivity' ? "'New Folder' " : "'Folder' " : editornewactivity.id === 'newactivity' ? "'New Activity' properties" : "'Activity' properties";
                clearSearch=false;
                this.setState({
                    hideSaveChangesDialog: false,
                    dialogText: "You have unsaved changes on " + text + ". Do you want to Save them."
                });
            } 
         

        }
        else if (editornewactivity && selectedactivity && editornewactivity.id === selectedactivity.id) {
            if (activityAction.UpdateSelection) {
                activityAction.Activity = selectedactivity;
                activityAction.UpdateSelection = false;
            }
        }
      
        if (activityAction.DialogAction !== DialogAction.none) {
            if (activityAction.DialogAction === DialogAction.No) {
                activityAction.Activity = {};
            }
            activityAction.DialogAction = DialogAction.none;
        }

        activityAction.ActivityAction = ActivityAction.none;
        activityAction.IsLoading = false;
        this.setState({ activityAction })
        if ( clearSearch && !isNullOrUndefined(this.props.ActivitySearch) && !isNullOrUndefined(this.props.ActivitySearch.Activities[0]) && !isNullOrUndefined(selectedactivity)) {
            if (this.props.ActivitySearch.Activities[0] && this.props.ActivitySearch.Activities[0].level > selectedactivity.level) {
                this.props.HandleClearSearch();
            }
        }
    }

    private _handleColumnSelection(items: IActivityDeifinitionMember[]) {
        this.setState({ SelectedColumnsToDisplay: items }, () => {
            this._handleActivityActionClick(ActivityAction.CustomColumnSelection);
        });

    }

    private _handleActivityActionClick(action: ActivityAction) {
        const activityActionAttributes: any = this.state.activityAction;
        if (activityActionAttributes.Activity && activityActionAttributes.Activity.id !== undefined && activityActionAttributes.Activity.id !== '') {
            const text: string = activityActionAttributes.Activity.is_folder ? activityActionAttributes.Activity.id === 'newactivity' ? "'New Folder' " : "'Folder' " : activityActionAttributes.Activity.id === 'newactivity' ? "'New Activity' properties" : "'Activity' properties";
            // Display dialog if any unsaved activity is there
            this.setState({
                hideSaveChangesDialog: false,
                dialogText: "You have unsaved changes on " + text + ". Do you want to Save them."
            });
            activityActionAttributes.ActivityAction = ActivityAction.none;
            this.setState({ activityAction: activityActionAttributes });
        }
        else {
            if (this.state.cutActivities.length > 0 && action !== ActivityAction.Paste
                && action !== ActivityAction.ExpandAll && action !== ActivityAction.CollapseAll) {
                activityActionAttributes.ActivityAction = ActivityAction.Cut;
                this.setState({ cutActivities: [], activityAction: activityActionAttributes }, () => {
                    this._handleActivityActionSwitch(action, activityActionAttributes);
                });
            }
            else {
                return this._handleActivityActionSwitch(action, activityActionAttributes);
            }
        }
    }

    private _handleActivityActionSwitch(action: ActivityAction, activityActionAttributes: any) {
        switch (action) {
            case ActivityAction.AddActivity:
                return this._handleAddActivity(activityActionAttributes, action);
            case ActivityAction.AddFolder:
                return this._handleAddFolder(activityActionAttributes, action);
            case ActivityAction.ExpandAll:
                return this._handleExpandAll(activityActionAttributes, action);
            case ActivityAction.CollapseAll:
                return this._handleCollapseAll(activityActionAttributes, action);
            case ActivityAction.Delete:
                return this._handleDeleteClick(activityActionAttributes, action);
            case ActivityAction.Cut:
                return this._handleCutClick(activityActionAttributes, action);
            case ActivityAction.Copy:
                return this._handleCopyClick(activityActionAttributes, action);
            case ActivityAction.Paste:
                return this._handlePasteClick(activityActionAttributes, action);
            case ActivityAction.CustomColumnSelection:
                return this._handleCustomColumnChange(activityActionAttributes, action);
            default:
                return;
        }
    }

    private _searchActivity(activityId: string) {
        const activityFilters: any = {
            search_id: 'test',
            filters: [],
        }
        let selectedIds: any[] = [];
        selectedIds.push(activityId);
        activityFilters.filters.push({
            name: 'id',
            values: selectedIds
        });

        const opts = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(activityFilters)
        };
        const url = `${AppSettings.ActivityBaseUrl()}/${Config.Actvity.Search}`;
        return adalApiFetch(fetch, url, opts)
            .then(response => response.json());
    }


    private _handleAddActivity(activityActionAttributes: any, action: ActivityAction) {
        activityActionAttributes.DialogAction = DialogAction.none;
        activityActionAttributes.ActivityAction = action;
        activityActionAttributes.UpdateSelection = true;
        let parent_nodeID: string = '';
        parent_nodeID = this.state.selectedActivity ? (this.state.selectedActivity.is_folder ? this.state.selectedActivity.id : this.state.selectedActivity.parent_node_id):'';

        if (isNullOrUndefined( this.state.selectedActivity)|| isNullOrUndefined(this.state.selectedActivity.id) || this.state.selectedActivity.id==='' || this.state.selectedActivity.is_folder || parent_nodeID==='' ) {
            this._createActivityObject(activityActionAttributes, parent_nodeID, this.state.selectedActivity);
        }
        else {
            const apiResult = this._searchActivity(parent_nodeID);
            if (apiResult !== null && apiResult !== undefined) {
                apiResult.then(data => {
                    if (!isNullOrUndefined(data) && data.length > 0) {
                        const alService: ActivityListService = new ActivityListService();
                        const transformedSearchResults = alService.MapSearchResults(data, this.state.ActivityDef);
                        if (!isNullOrUndefined(transformedSearchResults) && transformedSearchResults.length > 0) {
                            this._createActivityObject(activityActionAttributes, parent_nodeID, transformedSearchResults[0]);
                        }
                    }
                });
            }
        }
    }

    private _createActivityObject(activityActionAttributes: any, parent_nodeID: any, parent_node: any) {
        activityActionAttributes.Activity = {
            id: "newactivity",
            parent_node_id: parent_nodeID,
            parent_node: parent_node,
            parent_org_id: this.props.selectedNode.key,
            is_folder: false,
            properties: {
                name: "New Activity",
                cylineitemstartdate: this.getDefaultDate(false),
                cylineitemenddate: this.getDefaultDate(true)
            },
            relations: {
                coexecutionwithmcs: [
                    {
                        "taxonomy_id": AppSettings.defaultValue_coexecutionwithmcs(),
                        "percentage": 100,
                        "comment": null
                    }
                ]
            }
        };
        if(!isNullOrUndefined(parent_nodeID) && parent_nodeID!=='')
        {
        this._cascadeValuesFromParent(activityActionAttributes.Activity, parent_node)
        }
        this.setState({ activityAction: activityActionAttributes });
    }

    private _handleCustomColumnChange(activityActionAttributes: any, action: ActivityAction) {
        activityActionAttributes.DialogAction = DialogAction.none;
        activityActionAttributes.ActivityAction = action;
        this.setState({ activityAction: activityActionAttributes });
    }
    private _handleExpandAll(activityActionAttributes: any, action: ActivityAction) {
        activityActionAttributes.DialogAction = DialogAction.none;
        activityActionAttributes.ActivityAction = action;
        activityActionAttributes.UpdateSelection = true;
        this.setState({ activityAction: activityActionAttributes });
    }
    private _handleCollapseAll(activityActionAttributes: any, action: ActivityAction) {
        activityActionAttributes.DialogAction = DialogAction.none;
        activityActionAttributes.ActivityAction = action;
        activityActionAttributes.UpdateSelection = true;
        this.setState({ activityAction: activityActionAttributes });
    }

    private _handleDialogActionClick(action: DialogAction) {
        const activityAction: any = this.state.activityAction;
        if (action === DialogAction.No) {            
            activityAction.Activity = {}
        }
        else if (action === DialogAction.Yes) {
            // User wants to stay back on the previous activity to commit the unsaved changes, so keep the ActivityActionPanel buttons disabled since activity/folder edit is in progress
            this.setState({ disableActivityActionButtons: true });
        }
        activityAction.MoveActivity = false;
        activityAction.DialogAction = action;
        this.setState({ activityAction });
        this.setState({ hideSaveChangesDialog: true });
    }
    private _handleMoveDialogActionClick(action: DialogAction) {
        const activityAction: any = this.state.activityAction;       
        activityAction.MoveActivity = false;
        activityAction.DialogAction = ActivityAction.none;
        this.setState({ activityAction,hideMoveActivityDialog:true,moveActivityDialogText:'' });        
    }

    private _handleActivitySaveClick(isSaveCompleted: boolean, savedActivity: any) {
        if (isSaveCompleted) {
            if (savedActivity) {
                const activityAction: any = this.state.activityAction;
                activityAction.Activity = savedActivity;
                activityAction.SaveComplete = true;
                activityAction.IsLoading = true;
                activityAction.MoveActivity = false;
                activityAction.movedActivityId='';
                this.setState({ activityAction });
            }
            this.setState({ hideSaveChangesDialog: true });
        }
        else {
            const activityAction: any = this.state.activityAction;
            activityAction.IsLoading = true;
            activityAction.MoveActivity = false;
            this.setState({ activityAction });
        }
    }
    private _handleActivityDefn(activityDef: IActivityDefinition, allTaxonomies: any, userPrefColumns: string) {
        const activityDefinitionService = new ActivityDefinitionService();
        var _summaryAttributes = [];
        if (userPrefColumns) {
            _summaryAttributes = activityDefinitionService.MapActivityDefitionColumnsAsDBView(activityDef, userPrefColumns);
        }
        else {
            _summaryAttributes = activityDefinitionService.MapActivityDefitionColumnsAsSummaryView(activityDef);
        }

        this.setState({ AllColumns: _summaryAttributes, ActivityDef: activityDef, AllTaxonomies: allTaxonomies });
    }
    private _handleAddFolder(activityActionAttributes: any, action: ActivityAction) {
        activityActionAttributes.DialogAction = DialogAction.none;
        activityActionAttributes.ActivityAction = action;
        activityActionAttributes.UpdateSelection = true;
        let parent_nodeID: string = '';
        parent_nodeID =this.state.selectedActivity? this.state.selectedActivity.is_folder ? this.state.selectedActivity.id : this.state.selectedActivity.parent_node_id:'';
        if ( isNullOrUndefined( this.state.selectedActivity) || this.state.selectedActivity.is_folder || isNullOrUndefined(this.state.selectedActivity.id) || this.state.selectedActivity.id==='' || parent_nodeID==='') {
            this._createFolderObject(activityActionAttributes, parent_nodeID, this.state.selectedActivity);
        }
        else {
            const apiResult = this._searchActivity(parent_nodeID); // this.state.selectedActivity.is_folder ? this.state.selectedActivity : this.state.selectedActivity.parent_node;
            if (apiResult !== null && apiResult !== undefined) {
                apiResult.then(data => {
                    if (!isNullOrUndefined(data) && data.length > 0) {
                        const alService: ActivityListService = new ActivityListService();
                        const transformedSearchResults = alService.MapSearchResults(data, this.state.ActivityDef);
                        if (!isNullOrUndefined(transformedSearchResults) && transformedSearchResults.length > 0) {
                            this._createFolderObject(activityActionAttributes, parent_nodeID, transformedSearchResults[0]);
                        }
                    }
                });
            }
        }
    }

    private _createFolderObject(activityActionAttributes: any, parent_nodeID: any, parent_node: any) {
        activityActionAttributes.Activity = {
            id: "newactivity",
            parent_node_id: parent_nodeID,
            parent_node: parent_node,
            parent_org_id: this.props.selectedNode.key,
            is_folder: true,
            properties: {
                name: "New Folder"
            },
            relations: {}
        };

        if(!isNullOrUndefined(parent_nodeID) && parent_nodeID!=='')
        {
        this._cascadeValuesFromParent(activityActionAttributes.Activity, parent_node)
        }
        this.setState({ activityAction: activityActionAttributes });
    }

    private _cascadeValuesFromParent(child: any, parent: any) {
        if (!isNullOrUndefined(child) && !isNullOrUndefined(parent)) {

            if (isNullOrUndefined(child.properties)) child.properties = [];
            if (isNullOrUndefined(child.relations)) child.relations = [];

            this.state.ActivityDef.members.forEach(defMember => {
                if (!isNullOrUndefined(defMember.is_cascadable) && defMember.is_cascadable) {
                    // Cascade down properties from the parent folder
                    if (defMember.type !== 'relation' && defMember.type !== 'io') {
                        const parentProp = parent[defMember.name];
                        if (!isNullOrUndefined(parentProp)) {
                            child.properties[defMember.name] = parentProp;
                        }
                    }

                    // Cascade down relations from the parent folder
                    else if (defMember.type === 'relation' || defMember.type === 'io') {
                        const parentRel = parent[defMember.name];
                        if (!isNullOrUndefined(parentRel)) {
                            child.relations[defMember.name] = parentRel;
                        }
                    }
                }
            });
        }
    }

    private _handleDeleteClick(activityActionAttributes: any, action: ActivityAction) {
        if (this.state.selectedActivity && this.state.selectedActivity.id!=='') {
            const text: string = this.state.selectedActivity.is_folder ? this.state.selectedActivity.parent_node_id !== undefined
                && this.state.selectedActivity.parent_node_id !== null && this.state.selectedActivity.parent_node_id !== '' ? "Sub-category '" : "Category '" : "Activity '";

            const _warningText: string = this.state.selectedActivity.is_folder ? " WARNING:  Deleting this will delete the folders & activities under it." : "";
            if (this.state.selectedActivity.oamt === undefined || this.state.selectedActivity.oamt === 0 || this.state.selectedActivity.oamt === '') {
                // Display confirm delete dialog 
                this.setState({
                    hideDeleteDialog: false,
                    dialogText: "Are you sure you want to delete the " + text + this.state.selectedActivity.name + "'?",
                    isDelete: true,
                    warningText: _warningText
                });
            }
            else {
                // Display confirm delete dialog 
                this.setState({
                    hideDeleteDialog: false,
                    dialogText: "Please un-map PO association to delete the " + text + this.state.selectedActivity.name + "'!!",
                    isDelete: false
                });

            }

        }
    }

    private _handleDeleteDialogClick(action: DialogAction) {
        this.setState({
            hideDeleteDialog: true,
            dialogText: ''
        });
        if (action === DialogAction.Yes && this.state.selectedActivity &&  this.state.selectedActivity.id!=='' && (this.state.selectedActivity.oamt === undefined || this.state.selectedActivity.oamt === 0 || this.state.selectedActivity.oamt === '')) {
            const activityActionAttributes: any = this.state.activityAction;
            if (this.state.selectedActivity.id === 'newactivity') {
                activityActionAttributes.ActivityAction = ActivityAction.Delete;
                activityActionAttributes.Activity = this.state.selectedActivity;
                this.setState({ activityAction: activityActionAttributes });
            }
            else {
                activityActionAttributes.IsLoading = true;
                this.setState({ activityAction: activityActionAttributes });
                const opts = {
                    method: "DELETE",
                    headers: { "Content-Type": "application/json" },
                };
                var url = `${this.state.selectedActivity.is_folder ? AppSettings.CategoryUrl() : AppSettings.ActivityBaseUrl()}/${this.state.selectedActivity.id}/${this.props.selectedNode.key}`;
                adalApiFetch(fetch, url, opts)
                    .then(response => response.json())
                    .then(data => {
                        if (data === this.state.selectedActivity.id) {
                            activityActionAttributes.ActivityAction = ActivityAction.Delete;
                            activityActionAttributes.Activity = this.state.selectedActivity;
                        }
                        activityActionAttributes.IsLoading = false;
                        this.setState({ activityAction: activityActionAttributes });
                    })
                    .catch((error) => {
                        activityActionAttributes.IsLoading = false;
                        this.setState({
                            isError: true,
                            erroDetails: error,
                            activityAction: activityActionAttributes
                        });
                    });
            }
        }
    }

    private _handleCutClick(activityActionAttributes: any, action: ActivityAction) {
        if (this.state.selectedActivity && this.state.selectedActivity.id!=='') {
            activityActionAttributes.ActivityAction = action;
            activityActionAttributes.Activity = this.state.selectedActivity;
            const cutActivitiesList: any[] = [];
            cutActivitiesList.push(this.state.selectedActivity);
            this.setState({
                activityAction: activityActionAttributes,
                cutActivities: cutActivitiesList
            });
        }
    }

    private _handlePasteClick(activityActionAttributes: any, action: ActivityAction) {
        if (activityActionAttributes.ActivityAction !== ActivityAction.Paste && !isNullOrUndefined(this.state.selectedActivity) &&  this.state.selectedActivity.id!=='') {
            activityActionAttributes.IsLoading = true;
            this.setState({ activityAction: activityActionAttributes });
            if (this.state.cutActivities.length > 0) {
                this._handleCutPasteClick(activityActionAttributes, action);
            }
            else if (this.state.copiedActivities.length > 0) {
                this._handleCopyPasteClick(activityActionAttributes, action);
            }
            else {
                activityActionAttributes.IsLoading = false;
                this.setState({ activityAction: activityActionAttributes });
            }
        }
    }

    private _handleCutPasteClick(activityActionAttributes: any, action: ActivityAction) {
        const activity: any = this.state.cutActivities[0];
        if (this.state.selectedActivity) {
            activity.parent_node_id = this.state.selectedActivity.is_folder ? this.state.selectedActivity.id : this.state.selectedActivity.parent_node_id;
            activity.sourceWorkspaceId = activity.parent_org_id;
            activity.parent_org_id = this.props.selectedNode.key;
        }
        if(activity.sourceWorkspaceId === activity.parent_org_id){
            const opts = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(activity)
            };
            const url = !activity.is_folder ? AppSettings.ActivityBaseUrl() : AppSettings.CategoryUrl();
            adalApiFetch(fetch, `${url}/${Config.Actvity.UpdateParentEndPoint}/${activity.id}/${activity.sourceWorkspaceId}`, opts)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        if (data.length === 1 && data.id === undefined) {
                            this._setError(data);
                        }
                        else {
                            activityActionAttributes.ActivityAction = action;
                            activityActionAttributes.Activity = activity;
                            activityActionAttributes.ActivitiesFullList = data;
                        }
                    }
                    else {
                        this._setError('Error occured, please reload page and try!!');
                    }
                    activityActionAttributes.IsLoading = false;
                    this.setState({ activityAction: activityActionAttributes, cutActivities: [] });
                })
                .catch((error) => {
                    activityActionAttributes.IsLoading = false;
                    this.setState({
                        activityAction: activityActionAttributes,
                        cutActivities: []
                    });
                    this._setError(error);
                });
        }
        else{
            if(!activity.is_folder){
                activityActionAttributes.ActivityAction = action;
                activityActionAttributes.Activity = activity;
                activityActionAttributes.IsLoading = false;
                activityActionAttributes.MoveActivity = true;
                activityActionAttributes.movedActivityId=activity.activityid;
                this.setState({ 
                    activityAction: activityActionAttributes, 
                    cutActivities: [] ,
                    hideMoveActivityDialog:false,
                    moveActivityDialogText: "Click on Save to paste activity '" + activity.activityid + "' in the '"+ this.props.selectedNode.text +"' !!"
                });
            } 
            else{
                activityActionAttributes.IsLoading = false;
                this.setState({ 
                    activityAction: activityActionAttributes, 
                    cutActivities: []                     
                });
            }  
        }
    }    
    private _setError(error: any) {
        this.setState({
            isError: true,
            erroDetails: error
        });
    }

    private _handleCopyClick(activityActionAttributes: any, action: ActivityAction) {
        if (this.state.selectedActivity && this.state.selectedActivity.id!=='') {
            activityActionAttributes.ActivityAction = action;
            activityActionAttributes.Activity = this.state.selectedActivity;
            const copiedActivitiesList: any[] = [];
            copiedActivitiesList.push(this.state.selectedActivity);
            this.setState({
                activityAction: activityActionAttributes,
                copiedActivities: copiedActivitiesList
            }, () => {
                activityActionAttributes.ActivityAction = ActivityAction.none;
                activityActionAttributes.Activity = {};
                this.setState({
                    activityAction: activityActionAttributes
                });
            });
        }
    }

    private _handleCopyPasteClick(activityActionAttributes: any, action: ActivityAction) {
        const activity: any = this.state.copiedActivities[0];
        if (this.state.selectedActivity) {
            activity.parent_node_id = this.state.selectedActivity.is_folder ? this.state.selectedActivity.id : this.state.selectedActivity.parent_node_id;
        }
        activity.properties = {
            name: 'Copy of ' + activity.name
        };
        const opts = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(activity)
        };
        const url = !activity.is_folder ? AppSettings.ActivityBaseUrl() : AppSettings.CategoryUrl();
        const copyActivityOrFolder = !activity.is_folder ? Config.Actvity.CopyandPasteActivity : Config.Actvity.CopyandPasteFolder;
        adalApiFetch(fetch, `${url}/${copyActivityOrFolder}/${activity.id}`, opts)
            .then(response => response.json())
            .then(data => {
                if (data.length > 0) {
                    if (isNullOrUndefined(data[0].item)) {
                        this._setError(data);
                    }
                    else {
                        activityActionAttributes.ActivityAction = action;
                        activity.name = 'Copy of ' + activity.name;
                        activityActionAttributes.Activity = activity;
                        activityActionAttributes.ActivitiesFullList = data;
                    }
                }
                else {
                    this._setError('Error occured, please reload page and try!!');
                }
                activityActionAttributes.IsLoading = false;
                this.setState({ activityAction: activityActionAttributes, copiedActivities: [] });
            })
            .catch((error) => {
                activityActionAttributes.IsLoading = false;
                this.setState({
                    activityAction: activityActionAttributes,
                    copiedActivities: []
                });
                this._setError(error);
            });

    }
    private getDefaultDate(endDate: boolean): string {
        var today = new Date();
        var yyyy;
        if (endDate) {
            yyyy = today.getMonth() >= 6 ? today.getFullYear() + 1 : today.getFullYear();
            today = new Date(yyyy + '-06-30');
        }
        else {
            yyyy = today.getMonth() >= 6 ? today.getFullYear() : today.getFullYear() - 1;
            today = new Date(yyyy + '-07-01');
        }
        return today.toISOString().replace(".000Z", "");
    }

}