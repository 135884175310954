import { TooltipDelay, DirectionalHint, TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import React from 'react';

export class ActivityPropertyTooltip extends React.Component<{
    informationText:string}>{
        // eslint-disable-next-line
        constructor(props) {
            super(props);
        }
public render() {
            return (
                <TooltipHost
                    calloutProps={{ gapSpace: 0 }}
                    tooltipProps={{
                        onRenderContent: () => {
                            return (
                                <div>
                                    {this.props.informationText}
                                </div>
                            );
                        }
                    }}
                    delay={TooltipDelay.zero}
                    directionalHint={DirectionalHint.rightCenter}>
                    <ActionButton
                        style={{ zoom: "70%" }}
                        data-automation-id="test"
                        iconProps={{ iconName: 'Unknown',ariaLabel:'Info',styles:{
                            root:{ color:'black' , fontSize:'20px'}
                            } }}
                        allowDisabledFocus={true}
                         />
                </TooltipHost>
    )}
                                        }