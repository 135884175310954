import * as React from 'react';
import { AppSettings } from "../Configuration";
import { IUserDetails } from '../contracts/IUserDetails';
import { IActivityNotification } from '../contracts/IActivityNotification';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { AppInsights } from "applicationinsights-js";
import '../App.css';
import { Details } from '../components/Details';

export class Main extends React.Component<{
    user: IUserDetails,
    notification:(ActivityNotification:IActivityNotification) => void,
    sethelppageid:(HelpPageId:string)=>void
}>
{

    public render(): JSX.Element {
        AppInsights.trackPageView(
            "ActivityManagement",
            null,
            { env: AppSettings.Env() }
        );
        return (
            <Stack grow verticalFill className="customwidth">
                <Details user={this.props.user} notification={this.props.notification} sethelppageid={this.props.sethelppageid} />
            </Stack>
        );
    }
} 