import * as React from 'react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { AppSettings, Config } from '../../../../../Configuration';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';

export class QSGControl extends React.Component<{
    activity: any,
    ActivityDef: any,
    TaxonomyLookup: any[]
}, {
    isCalloutVisible: boolean,
    missingProperties: string
}>{
    private _qsgButtonRef: HTMLElement;
    constructor(props) {
        super(props);
        this._onCalloutDismiss = this._onCalloutDismiss.bind(this);
        this.onQSGButtonClick = this.onQSGButtonClick.bind(this);
        this.getParamValue = this.getParamValue.bind(this);
        this.getDisplayLable = this.getDisplayLable.bind(this);
        this.getFromLookUp = this.getFromLookUp.bind(this);
        this.missingMandatoryProperties = this.missingMandatoryProperties.bind(this);
        this.state = {
            isCalloutVisible: false,
            missingProperties: ''
        }
    }



    public render(): JSX.Element {
        return (
            <div>
                <div ref={menuButton => (this._qsgButtonRef = menuButton!)} >
                    <PrimaryButton
                        data-automation-id="qsg_button"
                        ariaDescription="Setup WEDCS Query String"
                        onClick={this.onQSGButtonClick}
                        className='side-activity-footer-button'
                    >
                        Setup WEDCS Query String
                </PrimaryButton>
                </div>
                {this.state.isCalloutVisible ?
                    <div>
                        <TeachingBubble
                            targetElement={this._qsgButtonRef}
                            hasCondensedHeadline={true}
                            onDismiss={this._onCalloutDismiss}
                            hasCloseIcon={true}
                            headline="Action Required"
                        >
                            Some missing values are required to trigger this action. You are currently missing {this.state.missingProperties}
                        </TeachingBubble>
                    </div>
                    : null}

            </div>
        );
    }
    private onQSGButtonClick() {
        const perGeoValue =this.joinValues(this.props.activity['createquerystringspergeo'],'createquerystringspergeo');
        let url = `${AppSettings.QSGUrl()}`;
        if (this.missingMandatoryProperties()) {
            for (const param of Config.QSG.Params) {
                if(param!=='Landing+Geo' || perGeoValue==='Yes'){
                    const value = this.getParamValue(param);
                    url += `${param}=${value}&`;
                }
            }
            window.open(url);
        }
    }
    
    private missingMandatoryProperties(): boolean {
        let emptyProperties = '';
        for (const member of this.props.ActivityDef.members) {
            if ((member.name === 'activityid' ||
                (member.is_mandatory &&
                    !member.is_system_property &&
                    member.name !== 'name'))
                && ((member.name === 'activityid' && this.props.activity['activityId'] === "newactivity")
                || (!this.props.activity[member.name === 'activityid' ? 'activityId' : member.name] ||
                    this.props.activity[member.name === 'activityid' ? 'activityId' : member.name].length === 0))) {
                emptyProperties += member.labels[Config.locale] + ' ,'
            }
        }
        emptyProperties = emptyProperties.replace(/,+$/, "");
        this.setState({
            missingProperties: emptyProperties,
            isCalloutVisible: emptyProperties !== ''
        });
        return emptyProperties === '';
    }
    private getParamValue(paramName: string): string {
        const defName = this.getDefintionName(paramName);
        if (defName === 'activityId') {
            return 'AID' + this.props.activity.activityId;
        }
        if (defName === 'title') {
            return this.props.activity.title;
        }
        if (defName === 'activityowner') {
            return this.props.activity.activityowner;
        }
        const val = this.props.activity[defName];
        return this.joinValues(val, defName);
    }
    private getDisplayLable(paramName: string): string {
        paramName = this.getDefintionName(paramName);
        for (const member of this.props.ActivityDef.members) {
            if (member.name === paramName) {
                return member.labels[Config.locale];
            }
        }
        return '';
    }
    private joinValues(values: any[], def: string): string {
        let value: string;
        value = '';
        if (values) {
            if(def === 'campaigngepname')
            {
                if(values.length > 10){
                    values = values.slice(0,10);
                }
            }
            for (const val of values) {
                if (val.taxonomyDisplayValue) {
                    value = value + val.taxonomyDisplayValue + ',';
                }
                else {
                    value = value + this.getFromLookUp(val.taxonomy_id, def) + ',';
                }
            }
        
        value = value.replace(/,+$/, "");
        return encodeURIComponent(value);
        }
        return value;
    }
    private getDefintionName(paramName: string): string {
        switch (paramName) {
            case 'Campaign/Gep+Name':
                return 'campaigngepname';
            case 'Execution+Team':
                return 'primaryexecutionteam';
            case 'Co-Execution+With+Service':
                return 'coexecutionwithmcs';
            case 'Primary+Objective':
                return 'primaryobjective';
            case 'Engine':
                return 'engine';
            case 'Target+Segment':
                return 'targetsegment';
            case 'ActivityID':
                return 'activityId';
            case 'Activity+Name':
                return 'title';
            case 'Activity+Owner':
                return 'activityowner';
            case 'Landing+Geo':
                return 'landinggeography'
            default:
                return '';
        }
    }
    private _onCalloutDismiss = (): void => {
        this.setState({
            isCalloutVisible: false
        });
    };
    private getFromLookUp(id: string, def: string): string {
        if (this.props.TaxonomyLookup[def]) {
            for (const item of this.props.TaxonomyLookup[def]) {
                if (item.taxonomy_id === id) {
                    return item.taxonomyDisplayValue
                }
            }
        }
        else {
            return '';
        }
        return '';
    }

}