import { IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { IActivityDefinition } from '../contracts/IActivityDefinition';
import { IActivityDeifinitionMember } from '../contracts/IActivityDefinitionMember';
import { Config } from '../Configuration';
import { isNullOrUndefined } from 'util';


export class ActivityDefinitionService{

    public MapActivityDefition(data:any):IColumn[] {
        const activityDefFromServer = data as IActivityDefinition;
        let activityColumns :IColumn[];
        const locale=Config.locale;
        activityColumns=[];
        if(activityDefFromServer !== null && activityDefFromServer !== undefined && activityDefFromServer.members!==undefined)
        {
        activityDefFromServer.members.forEach(member =>{
                if(member.is_summary_view){
                    member.is_checked = true;
                    let calulatedMaxWidth=150;
                    if(member.name==='name')
                    {
                        calulatedMaxWidth=450;
                    }
                    activityColumns.push({
                    key:member.name,
                    name:member.labels[locale],
                    minWidth:100,
                    maxWidth:calulatedMaxWidth,
                    isResizable:true,
                    headerClassName:"detailsListHeader",
                    className:"activityListGridContent",
                    data:{type:member.type,selectiontype:member.selection_type,defintionId:member.associated_taxonomy_def_id}
                    });
                }
            }); 
        }
        return activityColumns;
    }

    public MapActivityDefitionForDetailPanel(data:any):IActivityDeifinitionMember[]{
        const activityDefFromServer = data as IActivityDefinition;
        let activityColumns :IActivityDeifinitionMember[];
        activityColumns=[];
        const locale=Config.locale;
        if(activityDefFromServer !== null && activityDefFromServer !== undefined && activityDefFromServer.members!==undefined)
        {
            activityDefFromServer.members.forEach(member =>{
                if(member.is_detail_view){
                    if(!isNullOrUndefined(member.information_text)){
                        member.information_text= !isNullOrUndefined(member.information_text[locale])?  member.information_text[locale]:member.information_text;
                   }
                    activityColumns.push(member);
                }

        }); 
        }
        return activityColumns;
    }

    public MapActivityDefitionColumnsAsSummaryView(data:any):IActivityDeifinitionMember[]{
        const activityDefFromServer = data as IActivityDefinition;
        let activityColumns :IActivityDeifinitionMember[];
        activityColumns=[];
        if(activityDefFromServer !== null && activityDefFromServer !== undefined && activityDefFromServer.members!==undefined)
        {
        activityDefFromServer.members.forEach(member =>{
            if(!(Config.NotToBeDisplayedActivityColumns.indexOf(member.name)>=0)){
                if(member.is_summary_view){
                    member.is_checked = true;
                }
                    activityColumns.push(member);
            }
        }); 
        }
        return activityColumns;

    }

    public MapActivityDefitionMemberToColumnsToDisplay(data:any, isInSearchMode: boolean):IColumn[]{
        const activityDefMembFromServer = data as IActivityDeifinitionMember[];
        let activityColumns :IColumn[];
        activityColumns=[];
        const locale=Config.locale;
        if(activityDefMembFromServer !== null && activityDefMembFromServer !== undefined) 
        {
            activityDefMembFromServer.forEach(member =>{
                if (!(Config.NotToBeDisplayedActivityColumns.indexOf(member.name)>=0) && member.is_checked){                   
                    let calulatedMaxWidth=150;
                    if(member.name==='name')
                    {
                        calulatedMaxWidth=450;
                    }
                    activityColumns.push({
                    key:member.name,
                    name:member.labels[locale],
                    minWidth:100,
                    maxWidth:calulatedMaxWidth,
                    isResizable:true,
                    headerClassName:"detailsListHeader",
                    className:"activityListGridContent",
                    data:{type:member.type,selectiontype:member.selection_type,defintionId:member.associated_taxonomy_def_id}
                    });
                }
                if(member.name === 'name' && isInSearchMode) {
                    let calulatedMaxWidth=150;
                    activityColumns.push({
                    key:'parent_org_id',
                    name:'Workspace',
                    minWidth:100,
                    maxWidth:calulatedMaxWidth,
                    isResizable:true,
                    headerClassName:"detailsListHeader",
                    className:"activityListGridContent",
                    data:{type:'text',selectiontype:null,defintionId:null}
                    });
                }
            }); 
        }
        return activityColumns;

    }


    public MapActivityDefitionMemberToJsonString(data:IActivityDeifinitionMember[]):string{
        const activityDefMembFromServer = data as IActivityDeifinitionMember[];
        
        var activityColumnsDict={};
       if(activityDefMembFromServer !== null && activityDefMembFromServer !== undefined)
        {
            activityDefMembFromServer.forEach(member =>{ 
                if(!(Config.NotToBeDisplayedActivityColumns.indexOf(member.name)>=0))
                {
                    if(member.is_checked)              
                        activityColumnsDict[member.name] = true;   
                    else
                        activityColumnsDict[member.name] = false;    
                }                  
                
            }); 
        }
        return JSON.stringify(activityColumnsDict);

    }

    public MapActivityDefitionColumnsAsDBView(data:IActivityDefinition,fetchedColumns : string):IActivityDeifinitionMember[]{
       
        var ColumnObject = JSON.parse(fetchedColumns);
    
        const activityDefFromServer = data as IActivityDefinition;
        let activityColumns :IActivityDeifinitionMember[];
        activityColumns=[];
        if(activityDefFromServer !== null && activityDefFromServer !== undefined && activityDefFromServer.members!==undefined)
        {
        activityDefFromServer.members.forEach(member =>{
            if(!(Config.NotToBeDisplayedActivityColumns.indexOf(member.name)>=0))
            {
                if( ColumnObject.hasOwnProperty(member.name) && ColumnObject[member.name]){
                    member.is_checked = true;
                }
                else{
                    member.is_checked = false;
                }
                    activityColumns.push(member);
            }
        }); 
        }
        return activityColumns;     


    }
}