import * as React from 'react';
import { ActivityList } from './ActivityListDetailsControls/ActivityList';
import { ActivityAttribute } from './ActivityListDetailsControls/ActivityAttribute';
import { IActivityDefinition } from '../../../contracts/IActivityDefinition';
import { adalApiFetch } from '../../../adalConfig';
import { AppSettings, Config } from '../../../Configuration';
import { Shimmer } from 'office-ui-fabric-react/lib/Shimmer';
import { IActivityActionAttribute } from '../../../contracts/IActivityAction';
import { ITaxonomyDetail } from '../../../contracts/ITaxonomyDetails';
import { IActivitySearch } from '../../../contracts/IActivitySearch';
import { ActivityAction } from '../../../contracts/ActivityAction';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { IActivityDeifinitionMember } from '../../../contracts/IActivityDefinitionMember';
import { HierarchyService } from '@commercialsalesandmarketing/pet-org-hierarchy';
import { isNullOrUndefined } from 'util';

export class ActivityListDetails extends React.Component<
    {
        initailSelectedActivty: any,
        SelectedHierarchyNodeId: string,
        SelectedHierarchyNodeTaxonomies: ITaxonomyDetail[],
        SelectedActivityColumns: IActivityDeifinitionMember[],
        UpdateActivityOnAction: IActivityActionAttribute,
        HandleActivitySelection: (selectedActivity: any, isActivityChanged: boolean) => void,
        OnPOClick: (OnActivityDetails: boolean, SelectionDetails: any) => void,
        HandleSaveActivityClick: (isSaveCompleted: boolean, activity: any) => void,
        ActivitySearchList: (activitiesList: any[]) => void,        
        roles: any[],
        ActivitySearch: IActivitySearch,
        IsSearchInProgress: boolean,
        HandleActivityDefForColumns:  (selectedActivity: IActivityDefinition,allTaxonomies: any,userPrefColumns : string) => void,
        SetHelpPageId: (helpPageId:string) => void
    },
    {
        activityDetail: any,
        ActivityDef: IActivityDefinition,
        isLoading: boolean,
        isTaxonomiesLoading: boolean,
        isColumnsLoading:boolean,
        isError: boolean,
        errorDetails: string,
        isActivitySelected: boolean,
        shouldDisplayDetails: boolean,
        AllTaxnomies: any,
        UserColumnPref: string,
        LoadFullGrid: boolean,
        selectedactivity:any
    }
    >{

    constructor(props: {
        initailSelectedActivty: any,
        SelectedHierarchyNodeId: string,
        UpdateActivityOnAction: IActivityActionAttribute,
        HandleActivitySelection: (selectedActivity: any, displayDialog: boolean) => void,
        OnPOClick: (OnActivityDetails: boolean, SelectionDetails: any) => void,
        HandleSaveActivityClick: (isSaveCompleted: boolean, activity: any) => void,
        ActivitySearchList: (activitiesList: any[]) => void,
        SelectedHierarchyNodeTaxonomies: [],
        SelectedActivityColumns : []
        roles: [],
        ActivitySearch: IActivitySearch,
        IsSearchInProgress: boolean,
        HandleActivityDefForColumns:  (selectedActivity: IActivityDefinition,allTaxonomies: any,userPrefColumns : string) => void,
        SetHelpPageId: (helpPageId:string) => void

    }) {
        super(props);
        this.HandleActivitySelection = this.HandleActivitySelection.bind(this);
        this.getActivityDefinition = this.getActivityDefinition.bind(this);
        this.HandleHideButtonClick = this.HandleHideButtonClick.bind(this);
        this._handleActivitySaveClick = this._handleActivitySaveClick.bind(this);
        this._handleActivityChanged = this._handleActivityChanged.bind(this);
        this._showDetails = this._showDetails.bind(this);

        this.state = {
            ActivityDef: { id: '', members: [], fyid: '' },
            activityDetail: {},
            isLoading: true,
            isTaxonomiesLoading: true,
            isColumnsLoading: true,
            isError: false,
            errorDetails: '',
            isActivitySelected: false,
            shouldDisplayDetails: false,
            AllTaxnomies: undefined,
            UserColumnPref : "",
            LoadFullGrid:false,
            selectedactivity:{}
        }
        this.getActivityDefinition(this.props.SelectedHierarchyNodeTaxonomies);
    }

    public getActivityDefinition(selectedNodeTaxonomies: any, forceLoadTaxonomies: boolean = false) {
        var selectedNodeTaxonomiesArr = selectedNodeTaxonomies as ITaxonomyDetail[]
        var fyid =selectedNodeTaxonomiesArr.length > 0 ? selectedNodeTaxonomies.find(x=> x.taxonomyDefinitionId === 'fy').taxonomy_id: "";

        const opts = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(selectedNodeTaxonomies)
        }

        adalApiFetch(fetch, `${AppSettings.ActivityDefinitionUrl()}/${Config.Actvity.DefinitionId}`, opts)
            .then(response => response.json())
            .then(data => {
                const _activityDef = data as IActivityDefinition;
                _activityDef.fyid = fyid;
                this.setState({
                    ActivityDef: _activityDef,
                    isLoading: false
                });                
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                    errorDetails: error
                });
            });

        if ((this.state.AllTaxnomies === undefined || forceLoadTaxonomies) && !isNullOrUndefined(fyid) && fyid !== "") {         
            this.setState({isTaxonomiesLoading: true});
            adalApiFetch(fetch, `${AppSettings.TaxonomyServiceUrl()}/${Config.Taxonomy.GetAll}/${fyid}`, {})
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        AllTaxnomies: data,
                        isLoading: false,
                        isTaxonomiesLoading: false
                    });
                    this.props.HandleActivityDefForColumns(this.state.ActivityDef,this.state.AllTaxnomies,"");     
                })
                .catch((error) => {
                    this.setState({
                        isLoading: false,
                        isError: true,
                        errorDetails: error,
                        isTaxonomiesLoading: false
                    });
                });
        }
        const hierarchyService = new HierarchyService(AppSettings);
        const apiResult = hierarchyService.GetUserPreferenceForWorkspace(Config.PreferenceKeys.ActivityColumnView);
        if (apiResult !== null && apiResult !== undefined) {
         apiResult.then(data => {           
           if (data.last_preference_id !== null && data.last_preference_id !== undefined) {
                this.setState({UserColumnPref : data.last_preference_id,isColumnsLoading:false});
                this.props.HandleActivityDefForColumns(this.state.ActivityDef,this.state.AllTaxnomies,data.last_preference_id);
           }else{
                this.setState({isColumnsLoading:false});
                this.props.HandleActivityDefForColumns(this.state.ActivityDef,this.state.AllTaxnomies,"");                
           }
        })
        .catch((ex) => {
            this.setState({
                isError: true,
                errorDetails: ex.message
            });
        });
    }
    

    }

    public HandleActivitySelection(selectedactivity: any) {
        this.setState({ isActivitySelected: true })
        if (!this.props.UpdateActivityOnAction.Activity || this.props.UpdateActivityOnAction.Activity.id === undefined ||
            (selectedactivity && this.props.UpdateActivityOnAction.Activity.id === selectedactivity.id)
            || this.props.UpdateActivityOnAction.ActivityAction === ActivityAction.Cut
            || this.props.UpdateActivityOnAction.ActivityAction === ActivityAction.Delete) {
            this.setState({
                activityDetail: selectedactivity,
            })
        }
        let isActivityChanged: boolean = false;
        if (selectedactivity && selectedactivity.temp_name!==undefined && selectedactivity.name !== selectedactivity.temp_name) {
            isActivityChanged = true;
        }
        this.props.HandleActivitySelection(selectedactivity, isActivityChanged);
    }

    public HandleHideButtonClick() {
        
        this.setState({ shouldDisplayDetails: false },()=>{
        this.props.SetHelpPageId(Config.Help.HomePage)
        });
    }
    public componentWillReceiveProps(nextProps: any) {
        if (nextProps !== null && nextProps.SelectedHierarchyNodeId !== this.props.SelectedHierarchyNodeId) {
            var oldfyid = "";
            var newfyid = "";
            if(!isNullOrUndefined(this.props) && !isNullOrUndefined(this.props.SelectedHierarchyNodeTaxonomies)){
                var oldSelectedNodeTaxonomiesArr = this.props.SelectedHierarchyNodeTaxonomies as ITaxonomyDetail[]
                oldfyid = oldSelectedNodeTaxonomiesArr.length > 0 ? oldSelectedNodeTaxonomiesArr.find(x=> x.taxonomyDefinitionId === 'fy').taxonomy_id: "";
            }
            if(!isNullOrUndefined(nextProps) && !isNullOrUndefined(nextProps.SelectedHierarchyNodeTaxonomies)){
                var newSelectedNodeTaxonomiesArr = nextProps.SelectedHierarchyNodeTaxonomies as ITaxonomyDetail[]
                newfyid = newSelectedNodeTaxonomiesArr.length > 0 ? newSelectedNodeTaxonomiesArr.find(x=> x.taxonomyDefinitionId === 'fy').taxonomy_id: "";
            }
            this.setState({ shouldDisplayDetails: false }, () => {
                this.getActivityDefinition(nextProps.SelectedHierarchyNodeTaxonomies, oldfyid !== newfyid);
            });  
        }
        const shouldDisplayDetails=nextProps.UpdateActivityOnAction.ActivityAction === ActivityAction.AddActivity
        || nextProps.UpdateActivityOnAction.ActivityAction === ActivityAction.AddFolder
        || nextProps.UpdateActivityOnAction.ActivityAction === ActivityAction.Paste;
        if(!this.state.shouldDisplayDetails && shouldDisplayDetails)
        {
            this.setState({shouldDisplayDetails});
            
        }
}
    public render(): JSX.Element {
        const isLoading = this.state.isLoading;
        const isColumnsLoading = this.state.isColumnsLoading;
        const isTaxonomiesLoading = this.state.isTaxonomiesLoading;
        return (
            <Stack verticalFill>
                {isLoading || isColumnsLoading || isTaxonomiesLoading
                    ?
                    <div>
                        <Shimmer />
                    </div>
                    :
                    <Stack verticalFill>
                        <ActivityList HandleActivitySelection={this.HandleActivitySelection}
                            SelectedHierarchyNodeId={this.props.SelectedHierarchyNodeId}
                            ActivityDef={this.state.ActivityDef}
                            OnPOClick={this.props.OnPOClick}
                            UpdateActivityOnAction={this.props.UpdateActivityOnAction}
                            ActivitySearchList={this.props.ActivitySearchList}
                            roles={this.props.roles}
                            ActivitySearch={this.props.ActivitySearch}
                            AllTaxonomies={this.state.AllTaxnomies}
                            IsSearchInProgress={this.props.IsSearchInProgress}
                            SelectedActivityColumns = {this.props.SelectedActivityColumns} 
                            UserPrefColumn = {this.state.UserColumnPref} 
                            ShowActivityDetails={this._showDetails}
                            LoadFullGrid={this.state.LoadFullGrid}
                            selectedActivity={this.state.selectedactivity}/>
                            {
                                this.state.shouldDisplayDetails ?
                                <ActivityAttribute selectedActivity={this.state.activityDetail}
                                    ActivityDef=
                                    {
                                        !isNullOrUndefined(this.state.activityDetail) && !isNullOrUndefined(this.props.ActivitySearch)
                                            && !isNullOrUndefined(this.props.ActivitySearch.ActivityDefinitionsForSearchedWorkspaces)
                                            && this.props.ActivitySearch.ActivityDefinitionsForSearchedWorkspaces.length > 0
                                            && this.props.ActivitySearch.ActivityDefinitionsForSearchedWorkspaces.findIndex(x => x.workspaceID === this.state.activityDetail.parent_org_id) >= 0 // Workspace corresponding to the activity has definition present in state.ActivityDefinitionsForSearchedWorkspaces
                                            ? this.props.ActivitySearch.ActivityDefinitionsForSearchedWorkspaces.find(x => x.workspaceID === this.state.activityDetail.parent_org_id).activityDef
                                            : this.state.ActivityDef // Fall back to activityDef for the selected workspace in Hierarchy dropdown as default
                                    }
                                    selectedNodeTaxonomies=
                                    {
                                        (!isNullOrUndefined(this.props.ActivitySearch) && !isNullOrUndefined(this.props.ActivitySearch.SelectedWorkspaces) && this.props.ActivitySearch.SelectedWorkspaces.length > 0) ? // Search results are tried to be displayed
                                            !isNullOrUndefined(this.state.activityDetail) && this.props.ActivitySearch.SelectedWorkspaces.findIndex(x => x.id === this.state.activityDetail.parent_org_id) >= 0 // Workspace corresponding to the activity has definition present in state.ActivityDefinitionsForSearchedWorkspaces
                                                ? this.props.ActivitySearch.SelectedWorkspaces.find(x => x.id === this.state.activityDetail.parent_org_id).nodeTaxonomies
                                                : []
                                            : this.props.SelectedHierarchyNodeTaxonomies // Fall back to nodeTaxonomies for the selected workspace in Hierarchy dropdown as default
                                    }
                                    handleHideActivityDetailsClicked={this.HandleHideButtonClick}
                                    saveActivity={this.props.UpdateActivityOnAction}
                                    handleActivitySaveClick={this._handleActivitySaveClick}
                                    handleActivityChanged={this._handleActivityChanged}
                                    roles={this.props.roles}
                                    isOpen={this.state.shouldDisplayDetails}
                                    SetHelpPageId={this.props.SetHelpPageId}
                                    /> 
                                    : <div/>
                        }
                    </Stack>
                }
            </Stack>
        );
    }
    private _handleActivitySaveClick(isSaveCompleted: boolean, activity: any) {
        if(isSaveCompleted && activity){
            this.setState({selectedactivity:this.state.activityDetail,LoadFullGrid:true})
        }else{
            this.setState({selectedactivity:{},LoadFullGrid:false})
        }
        this.props.HandleSaveActivityClick(isSaveCompleted, activity);
    }    
    private _handleActivityChanged(activity: string, isActivityChanged: boolean) {
        this.props.HandleActivitySelection(this.state.activityDetail, isActivityChanged);
    }
    private _showDetails(){
        this.setState({shouldDisplayDetails:true},()=>{
        
        });
    }
}