import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import { isNullOrUndefined } from 'util';


window.renderActivityManagement = (containerId, user= null, configUrl = '/Configuration.json', customProps = null) => {
  ReactDOM.render(
    <App user={user} 
      configUrl={configUrl} 
      notification={!isNullOrUndefined(customProps) ? customProps.notification : (val) => { /*alert("Go Home invoked locally") */ }}
      sethelppageid={!isNullOrUndefined(customProps) ? customProps.sethelppageid : (val) => { /*alert("Help Page") */ }} />,
    document.getElementById(containerId)
  );
};

window.unmountActivityManagement = containerId => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
}

